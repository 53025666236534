import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const fetchLeads = createAsyncThunk(
    'manager/fetchLeads',
    async ({ token, searchQuery, searchField, filters, limit = 10, offset = 0 }, { rejectWithValue }) => {
        try {
            let url = `/api/leads/?limit=${limit}&offset=${offset}`
            if (searchQuery && searchField) {
                url += `&${searchField}=${searchQuery}`
            }

            if (filters) {
                Object.keys(filters).forEach(key => {
                    if (filters[key].includes(',')) {
                        const values = filters[key].split(',');
                        values.forEach(value => {
                            url += `&${key}=${value}`
                        })
                        return
                    }

                    if (filters[key] !== 'all' && filters[key] !== '')
                        return url += `&${key}=${filters[key]}`
                })
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить лидов');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const leadsSlice = createSlice({
    name: 'leads',
    initialState: {
        leads: [],
        filters: [],
        search: {
            query: '',
            field: 'first_name',
        },
        total: 0,
        status: 'idle',
        currentPage: 1,
        perPage: 10,
        error: null
    },
    reducers: {
        setLeads: (state, action) => {
            state.leads = action.payload.leads;
        },
        handleSearchQuery: (state, action) => {
            state.search.query = action.payload;
        },
        handleSearchField: (state, action) => {
            state.search.field = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setPerPage: (state, action) => {
            state.perPage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeads.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchLeads.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.leads = action.payload.leads;
                state.total = action.payload.total;
            })
            .addCase(fetchLeads.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setLeads, handleSearchQuery, setFilters, handleSearchField, setCurrentPage, setPerPage } = leadsSlice.actions;

export default leadsSlice.reducer;