import React from 'react'
import Cookies from 'js-cookie'
import { AuthContext } from '../../context/AuthContext'
import { useHttp } from '../../hooks/http.hook'

const AuthProvider = (props) => {
    const { request } = useHttp()
    const [isLoaded, setIsLoaded] = React.useState(false)
    const [user, setUser] = React.useState(null)
    const [token, setTokenData] = React.useState(null)


    const setToken = React.useCallback((tokenData) => {
        setTokenData(tokenData)

        if (tokenData) {
            Cookies.set('auth-token-crm', tokenData)
            // parse JWT and set user
            const jwt = tokenData.split('.')[1]
            const user = JSON.parse(atob(jwt))
            setUser(user.sub)
        } else {
            Cookies.remove('auth-token-crm')
        }
    }, [])

    const logOut = React.useCallback((tokenData) => {
        setToken(null)
        setUser(null)
    }, [setToken])

    const loadData = React.useCallback(async () => {
        const tokenData = Cookies.get('auth-token-crm')
        setTokenData(tokenData)
        try {
            if (tokenData) {
                const data = await request(`/api/auth/user/me`, 'GET', null, {
                    ['authorization']: 'Bearer ' + tokenData
                })
                setUser(data)
            }
        } catch (error) {
            setToken(null)
        } finally {
            setIsLoaded(true)

        }
    }, [setToken])


    React.useEffect(() => {
        loadData()
    }, [loadData])

    const contextValue = React.useMemo(() => ({
        isLoaded,
        user,
        token,
        setUser,
        setToken,
        logOut
    }), [isLoaded, user, token, setToken, logOut])

    return (
        <AuthContext.Provider value={contextValue}>
            {props.children}
        </AuthContext.Provider>
    )
}

export default AuthProvider