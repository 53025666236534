import React, { useState } from "react";
import Modal from "../Modal";

const AddDesk = ({ isOpen, setIsOpen, applyChanges }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [currency, setCurrency] = useState('');
    const [symbol, setSymbol] = useState('');

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    // Здесь должна быть логика для обработки изменений настроек полей
    return (<>
        <button onClick={openModal} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Добавить деск</button>
        <Modal isOpen={isModalOpen || false} onClose={closeModal}>
            <Modal.Header>
                Добавить Деск
            </Modal.Header>
            <Modal.Body>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-4">Название
                    <input
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="name"
                        type="text" placeholder="Название" className="border border-gray-300 p-2 rounded-lg w-full" />
                </label>

                <label htmlFor="currency" className="block text-sm font-medium text-gray-700 mb-4">Валюта
                    <input
                        value={currency}
                        onChange={e => setCurrency(e.target.value)}
                        min={3}
                        max={3}
                        id="currency"
                        type="text" placeholder="USD, EUR etc." className="border border-gray-300 p-2 rounded-lg w-full" />
                </label>

                <label htmlFor="symbol" className="block text-sm font-medium text-gray-700 mb-4">Символ валюты
                    <input
                        value={symbol}
                        onChange={e => setSymbol(e.target.value)}
                        id="symbol"
                        type="text" placeholder="$"  className="border border-gray-300 p-2 rounded-lg w-full" />
                </label>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => { applyChanges({name, currency, symbol}); closeModal(); }} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Добавить</button>
            </Modal.Footer>
        </Modal >
    </>
    );
};

export default AddDesk;