import React, { useState, useEffect, useCallback, useRef } from 'react'
import Modal from '../components/Modal'
import AdvancedTable from '../components/leads/AdvancedTable'
import { useHttp } from '../hooks/http.hook'

export const AddNewsModal = ({ isOpen, onClose, handleSubmit }) => {

    return (
        <div>
            <Modal isOpen={isOpen} onClose={onClose}>
                <Modal.Header>Добавить новость</Modal.Header>
                <Modal.Body>
                    <form className="w-full max-w-lg" onSubmit={handleSubmit}>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                    Заголовок
                                </label>
                                <input name='title' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="grid-first-name" type="text" placeholder="Заголовок" />
                                <p className="text-gray-600 text-xs italic">Заголовок новости</p>
                            </div>
                        </div>
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3">
                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-last-name">
                                    Описание
                                </label>
                                <textarea name='description' className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white"
                                    id="grid-last-name" type="text" placeholder="Описание" />
                                <p className="text-gray-600 text-xs italic">Описание новости</p>
                            </div>
                        </div>

                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit">
                            Сохранить
                        </button>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        type="button">
                        Отмена
                    </button>


                </Modal.Footer>
            </Modal>

        </div>
    )

}

export const ActionsNewsDropdown = ({ handleDelete, handleEdit }) => {
    const [isOpen, setIsOpen] = useState(false);
    const btnRef = useRef(null);

    const handleClickOutside = (event) => {
        if (btnRef.current && !btnRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleDeleteClick = () => {
        setIsOpen(false);
        handleDelete();
    }

    return (
        <div className="relative inline-block text-left" ref={btnRef}>
            <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none" id="options-menu" aria-haspopup="true" aria-expanded="true">
                Действия
                <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M10 12a2 2 0 100-4 2 2 0 000 4zM7 10a2 2 0 114 0 2 2 0 01-4 0zm9 0a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
            </button>
            <div className={`${isOpen ? "block" : "hidden"} origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10`} role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div className="py-1" role="none">
                    <button onClick={handleDeleteClick} className="ease-in duration-100 hover:bg-gray-300/30 text-red-500 block w-full text-left px-4 py-2 text-sm " role="menuitem">Удалить</button>
                </div>
            </div>
        </div>
    );
};

const News = () => {
    const { request } = useHttp()
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [news, setNews] = React.useState([])
    const [selectedRows, setSelectedRows] = useState(new Set());

    const handleRowSelectionChange = (itemId) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allRowIds = news.map((item, index) => item.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    };

    const handleBulkDelete = async () => {

        try {
            const data = await request('/api/news/delete/array/', 'DELETE', { ids: Array.from(selectedRows) });
            if (data && data.message) {
                setNews(prev => prev.filter(item => !selectedRows.has(item.id)))
                setSelectedRows(new Set());
                alert(data.message);
            }
        } catch (e) {
            console.log('Ошибка при удалении новостей', e);
        }
    }

    const addNews = async (e) => {
        e.preventDefault(); // Предотвращаем стандартное поведение формы

        // Создаем новый объект FormData
        const formData = new FormData(e.target);

        // Извлекаем значения полей из formData
        const title = formData.get('title');
        const description = formData.get('description');

        try {
            const news = { title, description, lead_id: 0 };
            const data = await request('/api/news/create/', 'POST', { ...news });
            console.log('Новость успешно добавлена', data);
            setNews(prev => [...prev, data])
            setIsModalOpen(false)
        } catch (e) {
            console.log('Ошибка при добавлении новости', e);
        }
    }

    const singleDelete = async (id) => {
        try {
            const data = await request(`/api/news/delete/${id}`, 'DELETE');
            if (data && data.message) {
                setNews(prev => prev.filter(item => item.id !== id))
                alert(data.message);
            }
        } catch (e) {
            console.log('Ошибка при удалении новости', e);
        }
    }

    const getNews = useCallback(async () => {
        try {
            const news = await request('/api/news/', 'GET');
            if (news.total > 0) {
                // ActionsNewsDropdown
                news.news = news.news.map(item => {
                    item.actions = <ActionsNewsDropdown handleDelete={() => singleDelete(item.id)} />
                    return item;
                })
                setNews(news.news);
            }
        } catch (e) {
            console.log('News ERROR: ', e);
        }
    }, []);

    useEffect(() => {
        getNews();
    }, []);

    return <div className="sm:container mx-auto p-1 md:p-4">
        <h2 className="my-6 text-2xl font-semibold">
            Новости
        </h2>
        <div className="my-4 space-x-4">
            <button onClick={() => setIsModalOpen(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Добавить новость
            </button>
            <button onClick={handleBulkDelete} className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">
                Удалить
            </button>
        </div>
        <AddNewsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} handleSubmit={addNews} />

        <AdvancedTable data={news}
            selectedRows={selectedRows}
            handleRowSelectionChange={handleRowSelectionChange}
            handleSelectAll={handleSelectAll}
            selectedFields={{
                id: { label: 'ID', isChecked: true },
                title: { label: 'Заголовок', isChecked: true },
                description: { label: 'Описание', isChecked: true },
                actions: { label: 'Действия', isChecked: true },
            }}
        />
    </div>

}

export default News