import React, { useCallback, useEffect, useState, useContext } from 'react';
import { AuthContext } from '../../context/AuthContext'; // Путь к вашему AuthContext
import { useHttp } from '../../hooks/http.hook';
import { ManagersContext } from '../../context/ManagersContext'; // Путь к вашему ManagersContext

export const ManagersProvider = ({ children }) => {
    const { request } = useHttp();
    const { token } = useContext(AuthContext);
    const [managers, setManagers] = useState([]);

    const getManagers = useCallback(async () => {
        try {
            const managers = await request('/api/users/', 'GET');
            if (managers.total > 0) {
                setManagers(managers.users);
            }
        } catch (e) {
            console.log('Managers ERROR: ', e);
        }
    }, [token]);

    // Загрузите данные о менеджерах при монтировании
    useEffect(() => {
        if (token) {
            getManagers();
        } else {
            setManagers([]);
        }
    }, [token]);

    return (
        <ManagersContext.Provider value={{ managers, getManagers }}>
            {children}
        </ManagersContext.Provider>
    );
};
