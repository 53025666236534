import Dashboard from '../pages/Dashboard';
import MyCalendar from '../pages/Calendar';
import Leads from '../pages/Leads';
import Transactions from '../pages/Transactions';
import Staffs from '../pages/Staffs';
import Affiliates from '../pages/Affiliates';
import News from '../pages/News';
import Assets from '../pages/Assets';
import Lead from '../pages/Lead';
import AffLeads from '../pages/Affiliate/AffLeads';
import GetLeads from '../pages/Affiliate/GetLeads';
import PostLeads from '../pages/Affiliate/PostLeads';

export const tabs = [
    {
        groupName: 'Дашборд',
        path: '/',
        Component: <Dashboard />,
        isLink: true,
    },
    {
        groupName: 'Календарь',
        path: '/calendar',
        Component: <MyCalendar />,
        isLink: true
    },
    {
        groupName: 'Лиды',
        path: '/leads',
        Component: <Leads />,
        isLink: true
    },


    {
        groupName: 'Лид',
        path: '/leads/:id',
        Component: <Lead />,
        isVisible: false,
        isLink: false
    },
    {
        groupName: 'Транзакции',
        path: '/transactions',
        Component: <Transactions />,
        isLink: true
    },
    {
        groupName: 'Сотрудники',
        path: '/staffs',
        Component: <Staffs />,
        isLink: true
    },
    {
        groupName: 'Другое',
        path: '/calendar',
        Component: <Dashboard />,
        isLink: false,
        links: [
            { name: 'Аффилиаты', path: '/affiliates', Component: <Affiliates /> },
            { name: 'Новости', path: '/news', Component: <News /> },
            { name: 'Активы', path: '/assets', Component: <Assets /> }
        ],
    }
];

export const affOnlyTabs = [
    {
        groupName: 'Лиды',
        path: '/',
        Component: <AffLeads />,
        isVisible: true,
        isLink: true
    },
    {
        groupName: 'API Documentation',
        path: '/docks',
        isVisible: true,
        isLink: false,
        links: [
            { name: 'GET LEADS', path: '/get-leads', Component: <GetLeads /> },
            { name: 'POST LEADS', path: '/post-leads', Component: <PostLeads /> }
        ],
    },
];
