// Компонент Layout.js
import React, { useEffect, useState } from 'react';
import Sidebar from './Sidebar'; // Предполагается, что у вас есть компонент Sidebar
import useAuth from '../hooks/useAuth.hook';
// import component
import Drawer from 'react-modern-drawer'
//import styles
import 'react-modern-drawer/dist/index.css'
import { useDispatch, useSelector } from 'react-redux';
import { dateFormater } from '@/utils/dateFormater';
import { Link } from 'react-router-dom';
import { bulkUpdateReminders, deleteReminder, fetchReminds, updateReminder } from '@/redux/reminderSlice';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import dayjs from 'dayjs';
import { useHttp } from '@/hooks/http.hook';

const Msg = ({ closeToast, toastProps, reminder }) => {
    return <div className='bg-white p-2 m-2 rounded-lg text-sm odd:bg-white even:bg-gray-100' >
        <div>
            <b>Напоминание</b>
            <div className='flex justify-between'>
                <div>{reminder.title}</div>
                <div><Link
                    className="text-blue-500 hover:text-blue-700"
                    to={`/leads/${reminder.lead_id}`}
                >{reminder.lead}</Link></div>
            </div>
            <div>{reminder.description}</div>
            <div>{dateFormater(reminder.remind_date)}</div>
        </div>
    </div >
};

const Layout = ({ children }) => {
    const [isSidebarOpen, setSidebarOpen] = useState(true);
    const [isReminder, setIsReminder] = React.useState(false);
    const reminders = useSelector(state => state.reminders.reminders);
    const dispatch = useDispatch();
    const { token } = useAuth();
    const intervalRef = React.useRef(null);
    const { request } = useHttp();

    const getOnlineUser = async () => {
        try {
            const data = await request('/api/leads/get_online_leads/', 'GET', null, {});
        }
        catch (e) {
            console.log('error', e);
        }
    }

    const toggleDrawer = () => {
        setIsReminder((prevState) => !prevState)
    }
    const auth = useAuth()

    // Функция для проверки и триггера уведомлений
    function checkAndTriggerNotifications() {
        console.log("reminders in checkAndTriggerNotifications: ", reminders)
        const now = new Date();
        reminders.forEach(reminder => {
            const remindTime = new Date(reminder.remind_date);
            const timeDiff = remindTime - now; // Разница в миллисекундах
            console.log(dayjs(reminder.remind_date).format("YYYY-MM-DD HH:mm"))
            if (timeDiff <= 600000 && timeDiff > 0 && reminder.is_active) {

                // Показать уведомление с react-toastify
                toast(<Msg reminder={reminder} />, {
                    position: "bottom-right",
                    autoClose: false,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                    toastId: reminder.id,
                    onClose: () => {
                        dispatch(updateReminder({ reminder: { ...reminder, is_active: false, remind_date: dayjs(reminder.remind_date).format("YYYY-MM-DD HH:mm") }, token }));
                    }

                });
                // reminder.is_notified = true; // Предотвратить повторные уведомления
            }
        });
    }

    useEffect(() => {
        if (auth.user && auth.user.role != "affiliate")
            dispatch(fetchReminds({ token }))
    }, [token, auth]);

    useEffect(() => {
        if (reminders.length > 0 && auth.user && auth.user.role != "affiliate") {
            intervalRef.current = setInterval(() => {
                checkAndTriggerNotifications();
            }, 10000);
        }
        return () => {
            clearInterval(intervalRef.current);
        };
    }, [dispatch, reminders, auth]);

    useEffect(() => {
        if (auth.user && auth.user.role != "affiliate")
            getOnlineUser();
    }, [auth]);

    return (
        <div className="flex h-screen bg-gray-100">
            {auth.isLoaded && auth.token && auth.user && <Sidebar isOpen={isSidebarOpen} setIsOpen={setSidebarOpen} />}
            <div className="md:flex-1 flex flex-col overflow-hidden justify-beetwen">
                <header className='flex justify-beetwen items-center'>
                    <button
                        className="p-4 text-gray-500 focus:outline-none md:hidden"
                        onClick={() => setSidebarOpen(!isSidebarOpen)}
                    >
                        {/* Иконка меню (бургер) */}
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>

                    {auth.user && auth.user.role != "affiliate" && <button onClick={toggleDrawer} className="relative ml-auto mr-2 relative p-2 md:py-2 text-sm bg-white hover:bg-zinc-200 text-gray-900 border border-gray-900 text-xs mr-1 rounded-lg">
                        <span className="absolute top-[10xp] -left-[20px] inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">{reminders.filter(reminder => reminder.is_active).length}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6"><path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"></path></svg>
                    </button>}
                </header>
                <main className="md:flex-1 overflow-x-hidden overflow-y-auto bg-gray-200 bg-opacity-50">
                    {auth.user && auth.user.role != "affiliate" && <Drawer
                        open={isReminder}
                        onClose={toggleDrawer}
                        direction='right'
                        className='bla bla bla'
                    >
                        <div className='py-2'>
                            <h2 className="p-2 text-md font-semibold flex justify-between items-center">
                                <span>Напоминания</span>
                                {/* read all */}
                                <button
                                    onClick={() => {
                                        dispatch(bulkUpdateReminders({ token: token, reminders: reminders.map(reminder => ({ ...reminder, is_active: false, remind_date: dayjs(reminder.remind_date).format("YYYY-MM-DD HH:mm") })) }));
                                    }}
                                    className="text-gray-600 text-sm border rounded-md border-gray-600 p-1 m-1"
                                >Прочитать все</button>
                            </h2>
                            {
                                reminders.map((reminder, index) => (
                                    <div key={reminder.id} className='bg-white p-2 m-2 rounded-lg text-xs odd:bg-white even:bg-gray-100'>
                                        <div key={index} >
                                            <div className='flex justify-between'>
                                                <div>{reminder.title}</div>
                                                <div><Link
                                                    className="text-blue-500 hover:text-blue-700"
                                                    to={`/leads/${reminder.lead_id}`}
                                                >{reminder.lead}</Link></div>
                                            </div>
                                            <div>{reminder.description}</div>
                                            <div>{dateFormater(reminder.remind_date)}</div>
                                            <div className='flex gap-4 justify-end'>
                                                {/* edit btn */}
                                                {reminder.is_active && <button
                                                    onClick={() => {
                                                        dispatch(updateReminder({ reminder: { ...reminder, is_active: false, remind_date: dayjs(reminder.remind_date).format("YYYY-MM-DD HH:mm") }, token }));
                                                    }}
                                                    className="text-green-500 border-b border-green-500 p-1 m-1"
                                                >Прочитал</button>}
                                                {/* delete btn */}
                                                <button
                                                    onClick={() => {
                                                        dispatch(deleteReminder({ token, id: reminder.id }));
                                                    }}
                                                    className="text-red-500  border-b border-red-500 p-1 m-1"
                                                >Удалить</button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </Drawer>}
                    {children}
                </main>
                {auth.user && auth.user.role != "affiliate" && <ToastContainer />}

            </div>
        </div >
    );
};

export default Layout;
