import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const fetchLeadTransaction = createAsyncThunk(
    'lead/fetchLeadTransaction',
    async ({ token, lead_id, limit = 10, offset = 0, filters }, { rejectWithValue }) => {
        try {

            let url = `/api/transactions/get_by_lead/?lead_id=${lead_id}&limit=${limit}&offset=${offset}`

            if (filters) {
                Object.keys(filters).forEach(key => {
                    if (filters[key].includes(',')) {
                        const values = filters[key].split(',');
                        values.forEach(value => {
                            url += `&${key}=${value}`
                        })
                        return
                    }

                    if (filters[key] !== 'all' && filters[key] !== '')
                        return url += `&${key}=${filters[key]}`
                })
            }


            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить лидов');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const fetchLeadDeals = createAsyncThunk(
    'lead/fetchLeadDeals',
    async ({ token, lead_id, limit = 10, offset = 0 }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/deals/get_deal_by_lead_id/${lead_id}?limit=${limit}&offset=${offset}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить лидов');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const fetchDealsByStatus = createAsyncThunk(
    'lead/fetchDealsByStatus',
    async ({ token, lead_id, status = 'open', limit = 10, offset = 0 }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/deals/get_deal_by_lead_id/${lead_id}?limit=${limit}&offset=${offset}&status=${status}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить лидов');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const fetchLeadWallet = createAsyncThunk(
    'lead/fetchLeadWallet',
    async ({ token, lead_id }, { rejectWithValue }) => {
        try {

            const [dealsResponse, balanceResponse] = await Promise.all([
                fetch(`/api/deals/lead_count_deals_by_type/${lead_id}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                        },
                    }),
                fetch(`/api/transactions/get_balance_by_lead_id/${lead_id}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                        },
                    })
            ]);

            const response = await fetch(`/api/deals/lead_count_deals_by_type/${lead_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                    },
                })
            // const data = await response.json();
            const dealsData = await dealsResponse.json();
            const balanceData = await balanceResponse.json();

            if (!response.ok) {
                throw new Error('Не удалось получить лидов');
            }
            return [dealsData, balanceData];
        } catch (error) {
            return rejectWithValue(error.message);
        }
    })

export const leadSlice = createSlice({
    name: 'lead',
    initialState: {
        transactions: {
            items: [],
            total: 0,
            filters: [],
            perPage: 10,
            currentPage: 1,
            error: null,
            status: 'idle',
        },
        deals: {
            items: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            error: null,
            status: 'idle',
        },
        statusDeals: {
            items: [],
            total: 0,
            perPage: 10,
            currentPage: 1,
            error: null,
            status: 'idle',
        },
        wallet: {
            deals: {
                open: 0,
                pending: 0,
                closed: 0,
            },
            balances: {
                "balance": 0,
                "available_funds": 0,
                "bonus": 0,
                "deposited": 0,
                "profit": 0,
                "withdrawn": 0,
                "credit": 0,
                "demo_balance": 0
            },
            error: null,
            status: 'idle',
        },
        search: {
            query: '',
            field: 'first_name',
        },
    },
    reducers: {
        setLeads: (state, action) => {
            state.leads = action.payload.leads;
        },
        setFilters: (state, action) => {
            state.transactions.filters = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.transactions.currentPage = action.payload;
        },
        setDealCurrentPage: (state, action) => {
            state.deals.currentPage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchLeadTransaction.pending, (state) => {
                state.transactions.status = 'loading';
            })
            .addCase(fetchLeadTransaction.fulfilled, (state, action) => {
                state.transactions.status = 'succeeded';
                state.transactions.items = action.payload.transactions;
                state.transactions.total = action.payload.total;
            })
            .addCase(fetchLeadTransaction.rejected, (state, action) => {
                state.transactions.status = 'failed';
                state.transactions.error = action.payload;
            })
            .addCase(fetchLeadDeals.pending, (state) => {
                state.deals.status = 'loading';
            })
            .addCase(fetchLeadDeals.fulfilled, (state, action) => {
                state.deals.status = 'succeeded';
                state.deals.items = action.payload.deals;
                state.deals.total = action.payload.total;
            })
            .addCase(fetchLeadDeals.rejected, (state, action) => {
                state.deals.status = 'failed';
                state.statusDeals.error = action.payload;
            })
            .addCase(fetchDealsByStatus.pending, (state) => {
                state.statusDeals.status = 'loading';
            })
            .addCase(fetchDealsByStatus.fulfilled, (state, action) => {
                state.statusDeals.status = 'succeeded';
                state.statusDeals.items = action.payload.deals;
                state.statusDeals.total = action.payload.total;
            })
            .addCase(fetchDealsByStatus.rejected, (state, action) => {
                state.statusDeals.status = 'failed';
                state.statusDeals.error = action.payload;
            })
            .addCase(fetchLeadWallet.fulfilled, (state, action) => {
                state.wallet.status = 'succeeded'
                state.wallet.deals = action.payload[0]
                state.wallet.balances = action.payload[1]
            })
    }
});

export const { setLeads, setCurrentPage, setFilters, setDealCurrentPage } = leadSlice.actions;

export default leadSlice.reducer;