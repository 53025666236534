import { Link } from "react-router-dom";
import { dateFormater } from "../../utils/dateFormater";
import { ActionCard } from "../component/ActionCard";

export const Reminder = ({ data = [] }) => {
  return (

    <div className="bg-white p-4 rounded-lg shadow ">
      <h2 className="text-xl font-bold mb-4">Напоминания</h2>
      <div className="min-h-[200px] max-h-[300px] overflow-x-auto">

        <table className="w-full">
          <thead>
            <tr className="text-left text-gray-400">
              <th>Лид</th>
              <th>Дата</th>
              <th>Заголовок</th>
              <th>Подробности</th>
            </tr>
          </thead>
          <tbody>
            {data.map((reminder, index) => (
              <tr key={index} className={`odd:bg-white even:bg-gray-100`}>
                <td><Link
                  className="text-blue-500 hover:text-blue-700"
                  to={`/leads/${reminder.lead_id}`}
                >{reminder.lead}</Link></td>
                <td>{dateFormater(reminder.remind_date)}</td>
                <td>{reminder.title}</td>
                <td>{reminder.description}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

// Компонент ManagerActions.js
export const ManagerActions = ({ data = [] }) => {

  const acationIcon = {
    add_coment: {
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
      </svg>,
      color: 'text-blue-500',
      bg: 'bg-blue-100'
    },
    change_status: {
      icon: <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
      </svg>,
      color: 'text-yellow-500',
      bg: 'bg-yellow-100'
    },
  }

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <h2 className="text-xl font-bold mb-4">Действия менеджеров</h2>
      <div className="space-y-4 min-h-[200px] max-h-[300px] overflow-auto">
        {data.map((action, index) => (
          <ActionCard key={index} activity={action} />
        ))}
      </div>
    </div>
  );
};

// Компонент ResultsTable.js
export const ResultsTable = ({ data = [] }) => {
  return (
    <div className="bg-white p-4 rounded-lg shadow overflow-auto">
      <h2 className="text-xl font-bold mb-4">Результаты</h2>
      <div className="min-h-[200px] max-h-[500px] overflow-x-auto">
        <table className="w-full text-left">
          <thead className="bg-gray-50">
            <tr className="text-gray-400">
              <th className="px-4 py-2">МЕНЕДЖЕР</th>
              <th className="px-4 py-2">ПОЗИЦИЯ</th>
              <th className="px-4 py-2">ДЕСК</th>
              <th className="px-4 py-2">ЛИДЫ</th>
              <th className="px-4 py-2">ДЕПОЗИТЫ (СЕГОДНЯ)</th>
              <th className="px-4 py-2">ДЕПОЗИТЫ (МЕСЯЦ)</th>
              <th className="px-4 py-2">ДЕПОЗИТЫ (ВСЕГО)</th>
            </tr>
          </thead>
          <tbody>

          {/* {
            full_name: 'Marchin Gribovskiy',
            role: 'Sales',
            desk: 'PL',
            leads_count: 2,
            deposit_sum_today: 0,
            deposit_sum_month: 0,
            deposit_sum_all: 0,
        } */}
            {data.map((manager, index) => (
              <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                <td className="px-4 py-2">{manager.full_name}</td>
                <td className="px-4 py-2">{manager.role}</td>
                <td className="px-4 py-2">{manager.desk}</td>
                <td className="px-4 py-2">{manager.leads_count}</td>
                <td className="px-4 py-2">{manager.deposit_sum_today}</td>
                <td className="px-4 py-2">{manager.deposit_sum_month}</td>
                <td className="px-4 py-2">{manager.deposit_sum_all}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
