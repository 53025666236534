import React, { useState } from "react";
import Modal from "../Modal";
import { useDispatch, useSelector } from "react-redux";
import { addWallet } from "@/redux/walletSLice";
import { useHttp } from "@/hooks/http.hook";

// {
//     "id": 1,
//     "account_number": "1234 5678 9101 1213",
//     "desk_id": 1,
//     "description": "visa/mastercard",
//     "name": "Main Card",
//     "type": "card"
// }

const AddWallet = ({ isOpen, setIsOpen, applyChanges }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [type, setType] = useState('card');
    const [deskId, setDeskId] = useState(1);
    const { desks } = useSelector(state => state.desk);
    const { request, loading } = useHttp();
    const dispatch = useDispatch();

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const addWallet = () => {
        const wallet = {
            name,
            description,
            account_number: accountNumber,
            type,
            desk_id: deskId,
        }
        if (!name || !description || !accountNumber || !type || !deskId) {
            alert('Заполните все поля');
            return;
        }

        applyChanges(wallet);
        closeModal();
    }

    // Здесь должна быть логика для обработки изменений настроек полей
    return (<>
        <button onClick={openModal} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Добавить Кошелек</button>
        <Modal isOpen={isModalOpen || false} onClose={closeModal}>
            <Modal.Header>
                Добавить Кошелек
            </Modal.Header>
            <Modal.Body>
                {/* alert */}

                <label htmlFor="type" className="block text-sm font-medium text-gray-700">Тип
                    <select
                        value={type}
                        onChange={e => setType(e.target.value)}
                        id="type"
                        className="border border-gray-300 p-2 rounded-lg w-full">
                        <option value=""></option>
                        <option value="card">Карта</option>
                        <option value="crypto">Крипта</option>
                        <option value="e-pay">Банк Трансфер</option>
                    </select>
                </label>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">Название
                    <select
                        value={name}
                        onChange={e => setName(e.target.value)}
                        id="name"
                        type="text" placeholder="Название" className="border border-gray-300 p-2 rounded-lg w-full">
                        <option value=""></option>
                        {
                            type === 'card' && <>
                                <option value="visa/mastercard">Visa/Mastercard</option>
                                <option value="МИР">МИР</option>
                            </>
                        }
                        {
                            type === 'crypto' && <>
                                <option value="USD Tether (TRC-20)">USD Tether (TRC-20)</option>
                                <option value="USD Tether (ERC-20)">USD Tether (ERC-20)</option>
                                <option value="Etherium (ETH)">Etherium (ETH)</option>
                                <option value="Bitcoin (BTC)">Bitcoin</option>
                            </>
                        }
                        {type === 'e-pay' && <>
                            <option value="Bank Transfer">Bank Transfer</option>
                            <option value="Humo">Humo</option>
                        </>}

                    </select>
                </label>
                <label htmlFor="description" className="block text-sm font-medium text-gray-700">Описание
                    <input
                        value={description}
                        onChange={e => setDescription(e.target.value)}
                        id="description"
                        type="text" placeholder="Описание" className="border border-gray-300 p-2 rounded-lg w-full" />
                </label>
                <label htmlFor="accountNumber" className="block text-sm font-medium text-gray-700">Номер счета
                    <input
                        value={accountNumber}
                        onChange={e => setAccountNumber(e.target.value)}
                        id="accountNumber"
                        type="text" placeholder="Номер счета" className="border border-gray-300 p-2 rounded-lg w-full" />
                </label>

                <label htmlFor="desk" className="block text-sm font-medium text-gray-700">Деск
                    <select
                        id="desk"
                        value={deskId}
                        onChange={e => setDeskId(e.target.value)}
                        className="border border-gray-300 p-2 rounded-lg w-full">
                        {desks.map(desk => <option key={desk.id} value={desk.id}>{desk.name}</option>)}
                    </select>
                </label>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={addWallet} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Добавить</button>
            </Modal.Footer>
        </Modal >
    </>
    );
};

export default AddWallet;