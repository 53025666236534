import { createSlice } from "@reduxjs/toolkit";

const initialUser = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    role_id: 0,
    desk_id: 0,
    permissions: {
        commonRights: {
            accessToLeads: 'none',
            transactionAccess: 'none',
            availableManagers: 'none',
            newsManagement: 'common',
            staffManagement: 'none',
            affiliateManagement: false,
            paymentManagement: false,
            viewAnalytics: false,
            assetManagement: false
        },
        financialRights: {
            modifyingTransactions: false,
            depositAccess: false,
            conclusionAccess: false,
            correctionAccess: false,
            bonusAccess: false,
            creditAccess: false
        },
        dataRights: {
            personalInfoAccess: true,
            phoneAccess: true,
            emailAccess: true,
            secondaryPhoneAccess: true,
            saleStatusAccess: true,
            retentionStatusAccess: true,
            affiliateAccess: true,
            campaignAccess: true,
            documentsAccess: true,
            chatAccess: true,
            financialIndicatorsAccess: true
        },
        modificationRights: {
            fullNameAccess: true,
            phoneAccess: true,
            emailAccess: true,
            secondaryPhoneAccess: true,
            saleStatusAccess: true,
            changeManagerAccess: true,
            retentionStatusAccess: true,
            manageDealsAccess: true,
            tradeSignalsAccess: true
        }
    }
};

export const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: initialUser,
        status: 'idle',
        error: null
    },
    reducers: {
        userChange: (state, action) => {
            console.log('userChange: ', action.payload)
            // Add your logic to handle user changes here
            state.user = action.payload
        },
    },
});


export const { userChange } = userSlice.actions;

export default userSlice.reducer;