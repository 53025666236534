import React, { useCallback, useEffect, useState } from 'react';
import useAuth from '../../hooks/useAuth.hook';
import { hasDataRights } from '../../utils/permissionsHelper';
import Modal from '../Modal';
import { useParams } from 'react-router-dom';
import { useHttp } from '../../hooks/http.hook';
import ModalImage from "react-modal-image";

const DocumentModal = ({ type, showModal, setShowModal, submit, docs }) => {
    let { id } = useParams();
    console.log(docs)
    return (
        <>
            {showModal && (
                <Modal isOpen={showModal || false} onClose={() => setShowModal(false)}>
                    <form onSubmit={submit}>
                        <Modal.Header>
                            Редактировать документ верификации
                        </Modal.Header>
                        <Modal.Body>
                            {/* upload photo */}

                            <div className='flex gap-2'>
                                {docs.map(doc => <div key={doc.id} className="flex items-center max-w-[600px] w-full lg:max-w-1/2">
                                    <ModalImage
                                        small={process.env.REACT_APP_PLATFORM_URL + '/media/docs/' + id + "/" + doc.photo_path}
                                        large={process.env.REACT_APP_PLATFORM_URL + '/media/docs/' + id + "/" + doc.photo_path}
                                        alt={type}
                                    />
                                </div>
                                )}
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                onClick={() => setShowModal(false)}
                            >
                                Отмена
                            </button>

                            <button
                                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                type='submit'
                            >
                                Добавить
                            </button>

                        </Modal.Footer>
                    </form>
                </Modal>
            )}
        </>
    );
}

const Leverages = () => {
    // Состояние для каждого поля ввода
    // const [forexLeverage, setForexLeverage] = useState('');
    const [testData, setTestData] = useState({
        forex: 100,
        crypto: 100,
        stocks: 100,
        indices: 100,
        commodities: 100
    })

    return (
        <div className="p-4 bg-white rounded-lg shadow">
            <h2 className="text-lg font-semibold mb-4">Кредитные Плечи</h2>
            <div className="grid grid-cols-2 gap-4">
                {Object.entries(testData).map(([key, value]) => (<div key={key}>
                    <label className="block text-gray-700 text-sm mb-2" htmlFor="forex">
                        {key.toUpperCase()}
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700"
                        id="forex"
                        type="number"
                        placeholder="100"
                        value={value}
                        onChange={(e) => setTestData(prev => ({ ...prev, [key]: e.target.value }))} // setForexLeverage(e.target.value
                    />
                </div>))}
            </div>
            <div className="mt-4">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700">
                    Сохранить
                </button>
            </div>
        </div>
    );
};

const Documents = () => {
    let { id } = useParams();
    const { request } = useHttp()

    const [isOpen, setIsOpen] = useState(false)
    const [photoType, setPhotoType] = useState('')
    const [photos, setPhotos] = useState([])

    const toggleModal = (type) => {
        setPhotoType(type)
        setIsOpen(!isOpen)
    }

    const uploadDocument = async (e) => {
        e.preventDefault()
        try {
            const formData = new FormData()
            formData.append('file', e.target.file.files[0]);
            formData.append('lead_id', id); // Замените на актуальный ID лида
            formData.append('photo_type', photoType); // Замените на актуальный тип фото

            // data.append('document', e.target.files[0])
            const response = await request(`/api/lead_photos/add/`, 'POST', formData, { 'Content-Type': 'multipart/form-data' })
            console.log(response)
        } catch (e) {
            console.log(e)
        }
    }

    const getPhotos = async () => {
        try {
            const response = await request(`/api/lead_photos/get_photo_by_lead_id/${id}`, 'GET')
            setPhotos(response)
        } catch (e) {
            console.log(e)
        }
    }

    const changePhotoStatus = useCallback(async (e, lead_photo_id) => {
        const value = e.target.value
        try {
            // const respons = await request(`/api/lead_photos/${lead_photo_id}`, 'PUT', {photo_status: value})
            const respons = await Promise.all([
                lead_photo_id.map(async id => await request(`/api/lead_photos/${id}`, 'PUT', { photo_status: value }))
            ])
            console.log("respons: ", respons)
            if (respons) {
                alert('status change successfully')
                getPhotos()
            }
        } catch (e) {
            alert('Error change photo status')
        }
    }, [id])


    useEffect(() => {
        getPhotos()
    }, [id])

    return (
        <div className="p-4 bg-white rounded-lg shadow">
            <h2 className="text-lg font-semibold mb-4">Документы</h2>
            <div className="space-y-4">
                <div>
                    <label className="space-y-3">
                        <div className="border-l-4 border-blue-500 hover:text-blue-500 justify-between flex gap-2 flex-wrap lg:flex-nowrap ">
                            <span onClick={() => toggleModal('passport')} className={`pl-4 cursor-pointer 
                            ${photos.some(photo => photo.type === 'passport' && photo.photo_status === true) ? 'text-green-600' : 'text-oramge-500'}`}>Подтверждение Личности</span>
                            {photos && photos.some(photo => photo.type === 'passport') && <select onChange={(e) => changePhotoStatus(e, photos.filter(photo => photo.type === 'passport').map(p => p.id))} value={
                                photos.some(photo => photo.type === 'passport' && photo.photo_status === true)
                            } defaultValue={'false'} className="border-2 border-blue-500 rounded-md p-2">
                                <option value="true">Подтверждено</option>
                                <option value="false">Отклонено</option>
                            </select>}
                        </div>
                        <div className="border-l-4 border-blue-500 hover:text-blue-500 justify-between flex gap-2 flex-wrap lg:flex-nowrap ">
                            <span onClick={() => toggleModal('employment')} className={`pl-4 cursor-pointer ${photos.some(photo => photo.type === 'employment' && photo.photo_status === true) ? 'text-green-600' : 'text-oramge-500'}`}>Подтверждение места работы</span>
                            {photos && photos.some(photo => photo.type === 'employment') && <select onChange={(e) => changePhotoStatus(e, photos.filter(photo => photo.type === 'employment').map(p => p.id))}
                                value={photos.some(photo => photo.type === 'employment' && photo.photo_status === true)}
                                defaultValue={'false'} className="border-2 border-blue-500 rounded-md p-2">
                                <option value="true">Подтверждено</option>
                                <option value="false">Отклонено</option>
                            </select>}
                        </div>
                        <div className="border-l-4 border-blue-500 hover:text-blue-500 justify-between flex gap-2 flex-wrap lg:flex-nowrap ">
                            <span onClick={() => toggleModal('address')} className={`pl-4 cursor-pointer ${photos.some(photo => photo.type === 'address' && photo.photo_status === true) ? 'text-green-600' : 'text-oramge-500'}`}>Подтверждение места проживания</span>
                            {photos && photos.some(photo => photo.type === 'address') && <select onChange={(e) => changePhotoStatus(e, photos.filter(photo => photo.type === 'address').map(p => p.id))}
                                value={photos.some(photo => photo.type === 'address' && photo.photo_status === true)}
                                defaultValue={'false'} className="border-2 border-blue-500 rounded-md p-2">
                                <option value="true">Подтверждено</option>
                                <option value="false">Отклонено</option>
                            </select>}
                        </div>
                        <div className="border-l-4 border-blue-500 hover:text-blue-500 justify-between flex gap-2 flex-wrap lg:flex-nowrap ">
                            <span onClick={() => toggleModal('card_front')} className={`pl-4 cursor-pointer ${photos.some(photo => photo.type === 'card_front' && photo.photo_status === true) ? 'text-green-600' : 'text-oramge-500'}`}>Подтверждение Карта (фронт)</span>
                            {photos && photos.some(photo => photo.type === 'card_front') && <select onChange={(e) => changePhotoStatus(e, photos.filter(photo => photo.type === 'card_front').map(p => p.id))}
                                value={photos.some(photo => photo.type === 'card_front' && photo.photo_status === true)}
                                defaultValue={'false'} className="border-2 border-blue-500 rounded-md p-2">
                                <option value="true">Подтверждено</option>
                                <option value="false">Отклонено</option>
                            </select>}
                        </div>
                        <div className="border-l-4 border-blue-500 hover:text-blue-500 justify-between flex gap-2 flex-wrap lg:flex-nowrap ">
                            <span onClick={() => toggleModal('card_back')} className={`pl-4 cursor-pointer ${photos.some(photo => photo.type === 'card_back' && photo.photo_status === true) ? 'text-green-600' : 'text-oramge-500'}`}>Подтверждение Карта (бэк)</span>
                            {photos && photos.some(photo => photo.type === 'card_back') && <select onChange={(e) => changePhotoStatus(e, photos.filter(photo => photo.type === 'card_back').map(p => p.id))}
                                value={photos.some(photo => photo.type === 'card_back' && photo.photo_status === true)}
                                defaultValue={'false'} className="border-2 border-blue-500 rounded-md p-2">
                                <option value="true">Подтверждено</option>
                                <option value="false">Отклонено</option>
                            </select>}
                        </div>
                    </label>
                </div>
                {/* Повторите для других документов */}
            </div>
            <DocumentModal
                submit={uploadDocument}
                showModal={isOpen}
                type={photoType}
                setShowModal={setIsOpen}
                docs={photos.filter(photo => photo.type === photoType)}
            />
        </div>
    );
};

const Premission = ({ permissions, handleSubmit }) => {
    const [leadPermissions, setLeadPermissions] = useState(permissions)

    return <div className="p-4 bg-white rounded-lg shadow" >
        <h2 className="text-lg font-semibold mb-4">Права</h2>
        <div className="space-y-4">
            <div>
                <label className="flex items-center space-x-3">
                    <input
                        type="checkbox"
                        defaultChecked={leadPermissions?.allowTrade}
                        onChange={(e) => setLeadPermissions(prev => ({ ...prev, allowTrade: e.target.checked }))} // setTradingAllowed(e.target.checked
                        className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="text-gray-700">Разрешить Торговлю</span>
                </label>
            </div>
            <div>
                <label className="flex items-center space-x-3">
                    <input
                        type="checkbox"
                        defaultChecked={leadPermissions?.allowDataChange}
                        onChange={(e) => setLeadPermissions(prev => ({ ...prev, allowDataChange: e.target.checked }))} // setDataChangeAllowed(e.target.checked
                        className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="text-gray-700">Разрешить смену данных</span>
                </label>
            </div>
            <div>
                <label className="flex items-center space-x-3">
                    <input
                        type="checkbox"
                        defaultChecked={leadPermissions?.allowWithdrawal}
                        onChange={(e) => setLeadPermissions(prev => ({ ...prev, allowWithdrawal: e.target.checked }))} // setWithdrawalAllowed(e.target.checked
                        className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="text-gray-700">Разрешить Вывод</span>
                </label>
            </div>
            {/* <div>
                <label className="flex items-center space-x-3">
                    <input
                        type="checkbox"
                        defaultChecked={leadPermissions?.allowChat}
                        onChange={(e) => setLeadPermissions(prev => ({ ...prev, allowChat: e.target.checked }))} // setChatAllowed(e.target.checked
                        className="form-checkbox h-5 w-5 text-blue-600"
                    />
                    <span className="text-gray-700">Разрешить Чат</span>
                </label>
            </div> */}
            {/* Repeat for other permissions */}
            <div className="mt-4">
                <button className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-700" onClick={e => handleSubmit(e, 'permissions', leadPermissions)}>
                    Сохранить
                </button>
            </div>
        </div>
    </div >
}

const UserVerification = ({ lead, handleSubmit }) => {
    const { user } = useAuth()

    return (
        <div className="flex flex-wrap -mx-2">
            <div className="flex flex-wrap lg:flex-nowrap w-full px-2 gap-2">
                <div className="w-full lg:w-1/2 mt-2 bg-gray-200">
                    {hasDataRights(user, 'documentsAccess') && <Documents />}
                </div>
                <div className="w-full lg:w-1/2 mt-2 bg-gray-200">
                    <Premission permissions={lead.permissions} handleSubmit={handleSubmit} />
                </div>
            </div>
            {/* <div className="w-full lg:w-1/2 px-2">
                <div className="mt-2 bg-gray-200">
                    <Leverages />
                </div>
            </div> */}
        </div>
    );

}

export default UserVerification;