import React, { useState } from 'react';
import Modal from '../Modal';

// Компонент для настройки полей
const FieldSettings = ({ selectedFields, handleFieldChange }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const applyChanges = () => {
        closeModal();
    };


    // Здесь должна быть логика для обработки изменений настроек полей
    return (<>
        <button onClick={openModal} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded h-fit ">Изменить таблицу</button>
        <Modal isOpen={isModalOpen || false} onClose={closeModal}>
            <Modal.Header>
                Изменить таблицу
            </Modal.Header>
            <Modal.Body>
                <div className="grid grid-cols-1 gap-2 mb-4">
                    {Object.entries(selectedFields).map(([fieldKey, { label, isChecked }]) => (
                        <label key={fieldKey} className="flex items-center space-x-2 text-lg">
                            <input
                                className="mr-2 mt-[0.3rem] h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-neutral-300 before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-neutral-100 after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-blue-500 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-blue-400 checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:outline-none focus:ring-0 focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                type="checkbox"
                                defaultChecked={isChecked}
                                onChange={() => handleFieldChange(fieldKey)}
                            />
                            <span>{label}</span>
                        </label>
                    ))}

                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={applyChanges} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Применить</button>
            </Modal.Footer>
        </Modal>
    </>
    );
};

export default FieldSettings;