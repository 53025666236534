import React, { useState, useEffect } from "react"
import StaffTable from "../components/staff/StaffTable"
import { useHttp } from "../hooks/http.hook"
import { useSelector, useDispatch } from "react-redux"
import useAuth from "@/hooks/useAuth.hook"
import { fetchStaffs } from "@/redux/staffSlice"

const Staffs = () => {
    const { token } = useAuth()
    const dispatch = useDispatch()
    const { request, loading } = useHttp()
    const { staffs, status, error } = useSelector(state => state.staff)

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchStaffs({
                token: token
            }))
        }
    }, [token])

    return <div className="p-1 md:p-4">
        <h2 className="my-6 text-2xl font-semibold">
            Сотрудники
        </h2>
        {!loading && status === 'succeeded' && staffs.length > 0 && <StaffTable users={staffs} />}
    </div>

}

export default Staffs