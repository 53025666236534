import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const fetchStaffs = createAsyncThunk(
    'manager/fetchStaffs', 
    async ({ token }, { rejectWithValue }) => {
    try {
        const response = await fetch(`/api/users/staffs/?limit=100`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
            },
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || 'Не удалось получить сотрудников');
        }
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const staffSlice = createSlice({
    name: 'staff',
    initialState: {
        staffs: [],
        total: 0,
        status: 'idle',
        currentPage: 1,
        deskPerPage: 10,
        error: null
    },
    reducers: {
        setStaffs: (state, action) => {
            state.staffs = action.payload.users;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStaffs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchStaffs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.staffs = action.payload.users;
                state.total = action.payload.total;
            })
            .addCase(fetchStaffs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setStaffs } = staffSlice.actions;

export default staffSlice.reducer;