import React from "react"
import { useCallback, useState } from "react"
import useAuth from "./useAuth.hook"
import Cookies from "js-cookie";

export const useHttp = () => {

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const auth = useAuth()
    const token = Cookies.get('auth-token-crm')

    const request = useCallback(async (url, method = 'GET', body = null, headers = {}) => {
        setLoading(true)
        try {

            if (body && headers['Content-Type'] !== 'multipart/form-data') {
                body = JSON.stringify(body);
                headers['Content-Type'] = 'application/json';
            } else if (body && headers['Content-Type'] === 'multipart/form-data') {
                headers = {}
            }


            if (!!auth.token || token) {
                headers['authorization'] = 'Bearer ' + (auth.token ? auth.token : token)
            }

            const respons = await fetch(url, { method, body, headers })
            const data = await respons.json()

            if (!respons.ok)
                throw new Error(data.message || 'Error')

            setLoading(false)
            return data
        } catch (e) {
            setLoading(false)
            setError(e.message)
            throw e
        }
    }, [auth])

    const clearError = () => setError(null)
    return {
        loading, request, error, clearError
    }
}