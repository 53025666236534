import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchWallets = createAsyncThunk(
    'wallets/fetchWallets',
    async ({ token }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/wallets/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить wallets');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

const initialState = {
    wallets: [],
    status: 'idle',
    error: null,
};

const walletsSlice = createSlice({
    name: 'wallets',
    initialState,
    reducers: {
        addWallet: (state, action) => {
            state.wallets.push(action.payload);
        },
        removeWallet: (state, action) => {
            state.wallets = state.wallets.filter(wallet => wallet.id !== action.payload);
        },
        updateWallet: (state, action) => {
            const { id, ...updatedWallet } = action.payload;
            const walletIndex = state.wallets.findIndex(wallet => wallet.id === id);
            if (walletIndex !== -1) {
                state.wallets[walletIndex] = { ...state.wallets[walletIndex], ...updatedWallet };
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchWallets.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchWallets.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.wallets = action.payload;
            })
            .addCase(fetchWallets.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    },
});

export const { addWallet, removeWallet, updateWallet } = walletsSlice.actions;

export default walletsSlice.reducer;