import React, { useState, useCallback, useEffect } from 'react';
import { ActionsNewsDropdown, AddNewsModal } from '../../pages/News';
import AdvancedTable from '../../components/leads/AdvancedTable'
import { useHttp } from '../../hooks/http.hook';
import { useParams } from 'react-router-dom';

const UserNews = () => {
    const { id } = useParams()
    const { request } = useHttp();
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [news, setNews] = React.useState([])
    const [selectedRows, setSelectedRows] = useState(new Set());

    const handleRowSelectionChange = (itemId) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allRowIds = news.map((item, index) => item.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    };


    const handleBulkDelete = async () => {

        try {
            const data = await request('/api/news/delete/array/', 'DELETE', { ids: Array.from(selectedRows) });
            if (data && data.message) {
                setNews(prev => prev.filter(item => !selectedRows.has(item.id)))
                setSelectedRows(new Set());
                alert(data.message);
            }
        } catch (e) {
            console.log('Ошибка при удалении новостей', e);
        }
    }

    const addNews = async (e) => {
        e.preventDefault(); // Предотвращаем стандартное поведение формы

        // Создаем новый объект FormData
        const formData = new FormData(e.target);

        // Извлекаем значения полей из formData
        const title = formData.get('title');
        const description = formData.get('description');

        try {
            const news = { title, description, lead_id: id };
            const data = await request('/api/news/create/', 'POST', { ...news });
            console.log('Новость успешно добавлена', data);
            setNews(prev => [...prev, data])
            setIsModalOpen(false)
        } catch (e) {
            console.log('Ошибка при добавлении новости', e);
        }
    }

    const singleDelete = async (id) => {
        try {
            const data = await request(`/api/news/delete/${id}`, 'DELETE');
            if (data && data.message) {
                setNews(prev => prev.filter(item => item.id !== id))
                alert(data.message);
            }
        } catch (e) {
            console.log('Ошибка при удалении новости', e);
        }
    }

    const getNews = useCallback(async () => {
        try {
            const news = await request(`/api/news/lead/${id}`, 'GET');
            if (news.total > 0) {
                //  ActionsNewsDropdown
                news.news = news.news.map(item => {
                    item.actions = <ActionsNewsDropdown handleDelete={() => singleDelete(item.id)} />
                    return item;
                })
                setNews(news.news);
            }
        } catch (e) {
            console.log('News ERROR: ', e);
        }
    }, [id]);

    useEffect(() => {
        getNews();
    }, [getNews]);

    return <div className="sm:container mx-auto relative mb-4">
        <div className="flex items-center justify-between">
            <h2 className="my-6 text-xl font-semibold">
                Новости
            </h2>
            <div className="my-4 space-x-4">
                <button onClick={() => setIsModalOpen(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                    Добавить новость
                </button>
                <button onClick={handleBulkDelete} className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">
                    Удалить
                </button>
            </div>
        </div>
  
        <AddNewsModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} handleSubmit={addNews} />

        <AdvancedTable data={news}
            selectedRows={selectedRows}
            handleRowSelectionChange={handleRowSelectionChange}
            handleSelectAll={handleSelectAll}
            selectedFields={{
                id: { label: 'ID', isChecked: true },
                title: { label: 'Заголовок', isChecked: true },
                description: { label: 'Описание', isChecked: true },
                actions: { label: 'Действия', isChecked: true },
            }}
        />
    </div >
}

export default UserNews;