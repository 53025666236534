import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from "../Modal";
import { CheckBox } from '../component/CheckBox'
import _ from 'lodash';
import { useHttp } from "../../hooks/http.hook";
import { useDispatch, useSelector } from 'react-redux';
import { userChange } from '@/redux/userSlice';

const initialUser = {
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    role_id: 0,
    desk_id: 0,
    permissions: {
        commonRights: {
            accessToLeads: 'none',
            transactionAccess: 'none',
            availableManagers: 'none',
            newsManagement: 'common',
            staffManagement: 'none',
            affiliateManagement: false,
            paymentManagement: false,
            viewAnalytics: false,
            assetManagement: false
        },
        financialRights: {
            modifyingTransactions: false,
            depositAccess: false,
            conclusionAccess: false,
            correctionAccess: false,
            bonusAccess: false,
            creditAccess: false
        },
        dataRights: {
            personalInfoAccess: true,
            phoneAccess: true,
            emailAccess: true,
            secondaryPhoneAccess: true,
            saleStatusAccess: true,
            retentionStatusAccess: true,
            affiliateAccess: true,
            campaignAccess: true,
            documentsAccess: true,
            chatAccess: true,
            financialIndicatorsAccess: true
        },
        modificationRights: {
            fullNameAccess: true,
            phoneAccess: true,
            emailAccess: true,
            secondaryPhoneAccess: true,
            saleStatusAccess: true,
            changeManagerAccess: true,
            retentionStatusAccess: true,
            manageDealsAccess: true,
            tradeSignalsAccess: true
        }
    }
};

const LeadChangeRightsTab = ({ data, onUserUpdate }) => {
    const [fullNameAccess, setFullNameAccess] = useState(data.fullNameAccess);
    const [phoneAccess, setPhoneAccess] = useState(data.phoneAccess);
    const [emailAccess, setEmailAccess] = useState(data.emailAccess);
    const [secondaryPhoneAccess, setSecondaryPhoneAccess] = useState(data.secondaryPhoneAccess);
    const [saleStatusAccess, setSaleStatusAccess] = useState(data.saleStatusAccess);
    const [changeManagerAccess, setChangeManagerAccess] = useState(data.changeManagerAccess);
    const [retentionStatusAccess, setRetentionStatusAccess] = useState(data.retentionStatusAccess);
    const [manageDealsAccess, setManageDealsAccess] = useState(data.manageDealsAccess);
    const [tradeSignalsAccess, setTradeSignalsAccess] = useState(data.tradeSignalsAccess);

    const handleChange = (onChange, field, value) => {
        onChange(value); // update local state
        onUserUpdate(field, value); // update parent state
    };

    return (
        <div className=''>
            <div className="grid gap-2 md:grid-cols-2">

                <div className="flex items-center">
                    <CheckBox label={'Полное Имя'} value={fullNameAccess} onChange={
                        checked => {
                            handleChange(setFullNameAccess, 'permissions.modificationRights.fullNameAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Телефон'} value={phoneAccess} onChange={
                        checked => {
                            handleChange(setPhoneAccess, 'permissions.modificationRights.phoneAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Email'} value={emailAccess} onChange={
                        checked => {
                            handleChange(setEmailAccess, 'permissions.modificationRights.emailAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Второй Телефон'} value={secondaryPhoneAccess} onChange={
                        checked => {
                            handleChange(setSecondaryPhoneAccess, 'permissions.modificationRights.secondaryPhoneAccess', checked)
                        }

                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Сейл Статус'} value={saleStatusAccess} onChange={
                        checked => {
                            handleChange(setSaleStatusAccess, 'permissions.modificationRights.saleStatusAccess', checked)
                        }

                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Смена Менеджера'} value={changeManagerAccess} onChange={
                        checked => {
                            handleChange(setChangeManagerAccess, 'permissions.modificationRights.changeManagerAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Ретен Статус'} value={retentionStatusAccess} onChange={
                        checked => {
                            handleChange(setRetentionStatusAccess, 'permissions.modificationRights.retentionStatusAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Управление Сделками'} value={manageDealsAccess} onChange={
                        checked => {
                            handleChange(setManageDealsAccess, 'permissions.modificationRights.manageDealsAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Торговые Сигналы'} value={tradeSignalsAccess} onChange={
                        checked => {
                            handleChange(setTradeSignalsAccess, 'permissions.modificationRights.tradeSignalsAccess', checked)
                        }
                    } />
                </div>


            </div>
        </div>
    );
};

const DataAccessRightsTab = ({ data, onUserUpdate }) => {
    const [personalInfoAccess, setPersonalInfoAccess] = useState(data.personalInfoAccess);
    const [phoneAccess, setPhoneAccess] = useState(data.phoneAccess);
    const [emailAccess, setEmailAccess] = useState(data.emailAccess);
    const [secondaryPhoneAccess, setSecondaryPhoneAccess] = useState(data.secondaryPhoneAccess);
    const [saleStatusAccess, setSaleStatusAccess] = useState(data.saleStatusAccess);
    const [retentionStatusAccess, setRetentionStatusAccess] = useState(data.retentionStatusAccess);
    const [affiliateAccess, setAffiliateAccess] = useState(data.affiliateAccess);
    const [campaignAccess, setCampaignAccess] = useState(data.campaignAccess);
    const [documentsAccess, setDocumentsAccess] = useState(data.documentsAccess);
    const [chatAccess, setChatAccess] = useState(data.chatAccess);
    const [financialIndicatorsAccess, setFinancialIndicatorsAccess] = useState(data.financialIndicatorsAccess);

    const handleChange = (onChange, field, value) => {
        onChange(value); // update local state
        onUserUpdate(field, value); // update parent state
    };

    return (
        <div className=''>
            <div className="grid gap-2 md:grid-cols-2">

                <div className="flex items-center">
                    <CheckBox label={'ФИО'} value={personalInfoAccess} onChange={
                        checked => {
                            handleChange(setPersonalInfoAccess, 'permissions.dataRights.personalInfoAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Телефон'} value={phoneAccess} onChange={
                        checked => {
                            handleChange(setPhoneAccess, 'permissions.dataRights.phoneAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Email'} value={emailAccess} onChange={
                        checked => {
                            handleChange(setEmailAccess, 'permissions.dataRights.emailAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Второй Телефон'} value={secondaryPhoneAccess} onChange={
                        checked => {
                            handleChange(setSecondaryPhoneAccess, 'permissions.dataRights.secondaryPhoneAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Сейл Статус'} value={saleStatusAccess} onChange={
                        checked => {
                            handleChange(setSaleStatusAccess, 'permissions.dataRights.saleStatusAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Ретен Статус'} value={retentionStatusAccess} onChange={
                        checked => {
                            handleChange(setRetentionStatusAccess, 'permissions.dataRights.retentionStatusAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Аффилиат'} value={affiliateAccess} onChange={
                        checked => {
                            handleChange(setAffiliateAccess, 'permissions.dataRights.affiliateAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Кампания'} value={campaignAccess} onChange={
                        checked => {
                            handleChange(setCampaignAccess, 'permissions.dataRights.campaignAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Документы'} value={documentsAccess} onChange={
                        checked => {
                            handleChange(setDocumentsAccess, 'permissions.dataRights.documentsAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Чат'} value={chatAccess} onChange={
                        checked => {
                            handleChange(setChatAccess, 'permissions.dataRights.chatAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Финансовые Показатели'} value={financialIndicatorsAccess} onChange={
                        checked => {
                            handleChange(setFinancialIndicatorsAccess, 'permissions.dataRights.financialIndicatorsAccess', checked)
                        }
                    } />
                </div>
            </div>
        </div>
    );
};

const FinancialRightsTab = ({ data, onUserUpdate }) => {
    const [modifyingTransactions, setModifyingTransactions] = useState(data.modifyingTransactions);
    const [depositAccess, setAccessDeposit] = useState(data.depositAccess);
    const [conclusionAccess, setAccessConclusion] = useState(data.conclusionAccess);
    const [correctionAccess, setAccessCorrection] = useState(data.correctionAccess);
    const [bonusAccess, setAccessBonus] = useState(data.bonusAccess);
    const [creditAccess, setAccessCredit] = useState(data.creditAccess);

    const handleChange = (onChange, field, value) => {
        onChange(value); // update local state
        onUserUpdate(field, value); // update parent state
    };

    return (
        <div className=''>
            <div className="grid gap-2 md:grid-cols-2">

                <div className="flex items-center">
                    <CheckBox label={'Изменение Транзакций'} value={modifyingTransactions} onChange={
                        checked => {
                            handleChange(setModifyingTransactions, 'permissions.financialRights.modifyingTransactions', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Депозит'} value={depositAccess} onChange={
                        checked => {
                            handleChange(setAccessDeposit, 'permissions.financialRights.depositAccess', checked)
                        }
                    } />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Вывод'} value={conclusionAccess} onChange={
                        checked => {
                            handleChange(setAccessConclusion, 'permissions.financialRights.conclusionAccess', checked)
                        }
                    } />
                </div>
{/* 
                <div className="flex items-center">
                    <CheckBox label={'Корректировка'} value={correctionAccess} onChange={
                        checked => {
                            handleChange(setAccessCorrection, 'permissions.financialRights.correctionAccess', checked)
                        }
                    } />
                </div> */}

                <div className="flex items-center">
                    <CheckBox label={'Бонус'} value={bonusAccess} onChange={
                        checked => {
                            handleChange(setAccessBonus, 'permissions.financialRights.bonusAccess', checked)
                        }
                    } />
                </div>

                {/* <div className="flex items-center">
                    <CheckBox label={'Кредит'} value={creditAccess} onChange={
                        checked => {
                            handleChange(setAccessCredit, 'permissions.financialRights.creditAccess', checked)
                        }
                    } />
                </div> */}
            </div>
        </div>
    );
};

const CommonRightsTab = ({ data, onUserUpdate }) => {
    const [accessToLeads, setAccessToPeople] = useState(data.accessToLeads);
    const [transactionAccess, setTransactionAccess] = useState(data.transactionAccess);
    const [availableManagers, setAvailableManagers] = useState(data.availableManagers);
    const [newsManagement, setNewsManagement] = useState(data.newsManagement);
    const [staffManagement, setStaffManagement] = useState(data.staffManagement);
    const [affiliateManagement, setAffiliateManagement] = useState(data.affiliateManagement);
    const [paymentManagement, setPaymentManagement] = useState(data.paymentManagement);
    const [viewAnalytics, setViewAnalytics] = useState(data.viewAnalytics);
    const [assetManagement, setAssetManagement] = useState(data.assetManagement);

    const handleChange = (onChange, field, value) => {
        console.log("field: ", field, " value: ", value)
        onChange(value); // update local state
        onUserUpdate(field, value); // update parent state
    };

    return (
        <div className=''>
            <div className="grid gap-2 md:grid-cols-2">
                <div className="flex flex-col">
                    <label htmlFor="accessToLeads" className="font-semibold">Доступ к лидам:</label>
                    <select
                        id="accessToLeads"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={accessToLeads}
                        onChange={(e) => handleChange(setAccessToPeople, 'permissions.commonRights.accessToLeads', e.target.value)}
                    >
                        <option value="none">None</option>
                        <option value="assigned">Назначенные</option>
                        <option value="desk">Деск</option>
                        <option value="all">Все</option>
                    </select>
                </div>

                <div className="flex flex-col">
                    <label htmlFor="transactionAccess" className="font-semibold">Доступ к транзакциям:</label>
                    <select
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={transactionAccess}
                        onChange={(e) => handleChange(setTransactionAccess, 'permissions.commonRights.transactionAccess', e.target.value)}
                    >
                        <option value="none">None</option>
                        <option value="assigned">Назначенные</option>
                        <option value="desk">Деск</option>
                        <option value="all">Все</option>
                    </select>
                </div>

                <div className="flex flex-col">
                    <label htmlFor="availableManagers" className="font-semibold">Доступные менеджеры:</label>
                    <select
                        id="availableManagers"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={availableManagers}
                        onChange={(e) => handleChange(setAvailableManagers, 'permissions.commonRights.availableManagers', e.target.value)}
                    >
                        <option value="none">None</option>
                        <option value="desk">Деск</option>
                        <option value="all">Все</option>
                        {/* Опции менеджеров */}
                    </select>
                </div>


                <div className="flex flex-col">
                    <label htmlFor="staffManagement" className="font-semibold">Управление сотрудниками:</label>
                    <select
                        id="staffManagement"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={staffManagement}
                        onChange={(e) => handleChange(setStaffManagement, 'permissions.commonRights.staffManagement', e.target.value)}
                    >
                        <option value="none">None</option>
                        <option value="desk">Деск</option>
                        <option value="all">Все</option>
                        {/* Опции менеджеров */}
                    </select>
                </div>

                <div className="flex flex-col">
                    <label htmlFor="newsManagement" className="font-semibold">Управление Новостями:</label>
                    <select
                        id="newsManagement"
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                        value={newsManagement}
                        onChange={(e) => handleChange(setNewsManagement, 'permissions.commonRights.newsManagement', e.target.value)}
                    >
                        <option value="none">None</option>
                        <option value="lead">Лид</option>
                        <option value="common">Общие</option>
                        <option value="all">Все</option>

                        {/* Опции управления новостями */}
                    </select>
                </div>
            </div>

            <div className="grid gap-2 md:grid-cols-2">

                <div className="flex items-center">
                    <CheckBox label={'Управление Аффилиатами'} value={affiliateManagement} onChange={(checked) => handleChange(setAffiliateManagement, 'permissions.commonRights.affiliateManagement', checked)} />
                </div>

                <div className="flex items-center">
                    <CheckBox label={'Управление Платежками'} value={paymentManagement} onChange={checked => {
                        handleChange(setPaymentManagement, 'permissions.commonRights.paymentManagement', checked)
                    }} />
                </div>

                {/* <div className="flex items-center">
                    <CheckBox label={'Просмотр Аналитики'} value={viewAnalytics} onChange={checked => {
                        handleChange(setViewAnalytics, 'permissions.commonRights.viewAnalytics', checked)
                    }} />
                </div> */}

                <div className="flex items-center">
                    <CheckBox label={'Управление Активами'} value={assetManagement} onChange={checked => {
                        handleChange(setAssetManagement, 'permissions.commonRights.assetManagement', checked)
                    }} />
                </div>
            </div>
        </div>
    );
};

const EditUser = ({ user, onUserUpdate }) => {
    // Состояния для каждого поля формы
    const { request } = useHttp()

    const [first_name, setName] = useState(user.first_name);
    const [last_name, setlast_name] = useState(user.last_name);
    const [email, setEmail] = useState(user.email);
    const [desk, setDesk] = useState(user.desk);
    const [password, setPassword] = useState(user.password);
    const [position, setPosition] = useState(user.role);
    const { desks } = useSelector(state => state.desk)
    //roles
    const { roles } = useSelector(state => state.role)

    // ... и так далее для каждого поля

    // Обработчик отправки формы
    const handleChange = (onChange, field, value) => {
        console.log("field: ", field, " value: ", value)
        onChange(value); // update local state
        onUserUpdate(field, value); // update local state
    };

    // const getDesc = async () => {
    //     // /api/desks
    //     try {
    //         const fetched = await request('/api/desks/')
    //         if (fetched && fetched.desks)
    //             setDesks(fetched.desks)
    //     } catch (e) {
    //         console.log("user added: ", e)
    //     }
    // }

    return (
        <div className="grid md:grid-cols-2">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
                    Имя:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="first_name"
                    type="text"
                    placeholder="Имя"
                    value={first_name}
                    onChange={(e) => handleChange(setName, 'first_name', e.target.value)}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">
                    Фамилия:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="last_name"
                    type="text"
                    placeholder="Фамилия"
                    value={last_name}
                    onChange={(e) => handleChange(setlast_name, 'last_name', e.target.value)}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => handleChange(setEmail, 'email', e.target.value)}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                    Pass:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="password"
                    type="text"
                    placeholder="password"
                    value={password}
                    onChange={(e) => handleChange(setPassword, 'password', e.target.value)}
                />
            </div>
            {desks.length > 0 && <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="desk">
                    Деск:
                </label>
                <select
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="desk"
                    defaultValue={desk?.id || ''}
                    onChange={(e) => handleChange(setDesk, 'desk_id', e.target.value)}
                >
                    <option value="none">None</option>
                    {desks.map((desk) => {
                        return <option value={desk.id}>{desk.name}</option>
                    })}
                    {/* Добавьте другие опции */}
                </select>
            </div>}

            {roles.length > 0 && <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="role">
                    Позиция:
                </label>
                <select
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    id="role"
                    defaultValue={position?.id || ''}
                    onChange={(e) => handleChange(setPosition, 'role_id', e.target.value)}
                >
                    <option value="none">None</option>
                    {roles.map((role) => {
                        return <option value={role.id}>{role.role}</option>
                    })}
                    {/* Добавьте другие опции */}
                </select>
            </div>}
        </div>
    );
};

const AddUserWrap = ({ isModalOpen, closeModal, openModal, applyChanges, userObj = null }) => {
    const [activeTab, setActiveTab] = useState('personal');
    const [user, setUser] = useState(userObj || initialUser);
    const dispatch = useDispatch()
    // const { user } = useSelector(state => state.user)

    const tabTitles = {
        personal: 'Личные Данные',
        common: 'Общие Права',
        financial: 'Финансовые Права',
        dataAccess: 'Доступ к данным лид',
        leadChange: 'Права изменения лид'
    };

    const handleUserUpdate = (path, value) => {
        // let newUser = { ...user };
        // _.set(newUser, path, value === '' ? null : value); // Применяем изменения к newUser
        // dispatch(userChange(newUser)); // Отправляем обновлённый объект
        let newUser = _.cloneDeep(user); // Создаём глубокую копию объекта user

        // let newUser = { ...prevState };
        _.set(newUser, path, value === '' ? null : value); // Применяем изменения к newUser
        console.log(newUser)
        setUser(newUser);
    };

    // useEffect(() => {
    //     if (userObj != null) {
    //         setUser(userObj)
    //     }
    // }, [userObj]);
    return (<>
        {!userObj && <button onClick={openModal} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Добавить Пользователя</button>}
        <Modal isOpen={isModalOpen || false} onClose={closeModal}>
            <Modal.Header>
                Добавить юзера
            </Modal.Header>
            <Modal.Body>
                <div className="flex gap-2 md:gap-4 flex-wrap lg:flex-nowrap">
                    <div className="w-full lg:w-1/4">
                        {/* Vertical tabs */}
                        <div className="flex flex-col">
                            {Object.entries(tabTitles).map(([key, title]) => (
                                <button
                                    key={key}
                                    onClick={() => setActiveTab(key)}
                                    className={`px-4 py-2 text-left ${activeTab === key ? 'bg-green-200' : 'hover:bg-green-100'}`}
                                >
                                    {title}
                                </button>
                            ))}
                        </div>
                    </div>
                    <div className="w-full lg:w-3/4">
                        {/* Content for active tab */}
                        {activeTab === 'personal' && (
                            <>
                                <EditUser user={user} onUserUpdate={handleUserUpdate} />
                            </>
                        )}
                        {activeTab === 'common' && (
                            <>
                                <CommonRightsTab data={
                                    user.permissions.commonRights
                                }
                                    onUserUpdate={handleUserUpdate}
                                />
                            </>
                        )}
                        {activeTab === 'financial' && (
                            <>
                                <FinancialRightsTab data={
                                    user.permissions.financialRights
                                } onUserUpdate={handleUserUpdate} />
                            </>
                        )}
                        {activeTab === 'dataAccess' && (
                            <>
                                <DataAccessRightsTab data={
                                    user.permissions.dataRights
                                } onUserUpdate={handleUserUpdate}
                                />
                            </>
                        )}
                        {activeTab === 'leadChange' && (
                            <>
                                <LeadChangeRightsTab data={
                                    user.permissions.modificationRights
                                } onUserUpdate={handleUserUpdate}
                                />
                            </>
                        )}
                        {/* Repeat for other tabs */}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => applyChanges(user)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Добавить</button>
            </Modal.Footer>
        </Modal>
    </>);
}


export default AddUserWrap;
