import { ActionCard } from '../component/ActionCard';
const UserActivity = ({ data }) => {
    // data is an array of objects
    //    [
    //       {
    //         "action": "update",
    //         "description": {
    //           "first_name": {
    //             "to": "ActionTest",
    //             "from": "callback"
    //           }
    //         },
    //         "entity": "lead",
    //         "user_id": null,
    //         "user": null,
    //         "lead_id": 1,
    //         "lead": "ActionTest test",
    //         "created_at": "2023-12-28T17:01:46.820011"
    //       }
    //     ],

    return (
        <div className="user-activity my-4">
            <h3 className='font-semibold py-2 text-xl'>Действия менеджера</h3>
            <div className="flex flex-wrap gap-4">
                {data.map((activity, index) => {
                    return <ActionCard key={index} activity={activity} />
                })}
            </div>
        </div>
    )
}

export default UserActivity;