import { configureStore } from '@reduxjs/toolkit';
import deskReducer from './deskSlice';
import managerReducer from './managersSlice';
import roleReducer from './roleSLice';
import staffReducer from './staffSlice';
import leadsReducer from './leadsSlice';
import retenReducer from './retenSlice';
import saleReducer from './saleSlice';
import transactionsReducer from './transactionSlice';
import walletReducer from './walletSLice';
import leadReducer from './leadSlice';
import affiliatesReducer from './affiliatesSlice';
import activeReducer from './activesSlice';
import userReducer from './userSlice';
import fetchAffLeadsReducer from './affLeadsSlice';
import remindersReducer from './reminderSlice';

export const store = configureStore({
    reducer: {
        desk: deskReducer,
        manager: managerReducer,
        role: roleReducer,
        staff: staffReducer,
        leads: leadsReducer,
        reten: retenReducer,
        sale: saleReducer,
        transactions: transactionsReducer,
        wallet: walletReducer,
        lead: leadReducer,
        affiliates: affiliatesReducer,
        active: activeReducer,
        user: userReducer,
        affLeads: fetchAffLeadsReducer,
        reminders: remindersReducer
    },
});