// desk slice for redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// chunk of get managers
// /api/users/managers/
// GET

export const fetchRoles = createAsyncThunk(
    'manager/fetchRoles', 
    async ({ token }, { rejectWithValue }) => {
    try {
        const response = await fetch(`/api/users/roles/all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
            },
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || 'Не удалось получить роли');
        }
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const rolesSlice = createSlice({
    name: 'role',
    initialState: {
        roles: [],
        total: 0,
        status: 'idle',
        currentPage: 1,
        deskPerPage: 10,
        error: null
    },
    reducers: {
        setRoles: (state, action) => {
            state.roles = action.payload.roles;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRoles.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchRoles.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.roles = action.payload.roles;
                state.total = action.payload.total;
            })
            .addCase(fetchRoles.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setRoles } = rolesSlice.actions;

export default rolesSlice.reducer;