import React, { useState } from 'react';

// Компонент продвинутой таблицы
const AdvancedTable = ({ data, selectedFields, handleSelectAll, selectedRows, chooseAff }) => {

    const fieldsToShow = Object.entries(selectedFields)
        .filter(([key, value]) => value.isChecked)
        .map(([key]) => key);



    // Здесь должна быть логика для поиска и фильтрации данных таблицы
    return (
        <div className="min-w-full overflow-x-auto shadow rounded-lg bg-white p-1 md:p-4">
            <table className="min-w-full">
                <thead className="bg-gray-50">
                    <tr className="text-left text-gray-500 uppercase">
                        {/* <th className="px-6 py-3">
                            <input
                                className="w-4 h-4"
                                type="checkbox"
                                id="checkAll"
                                onChange={handleSelectAll}
                                checked={data.length > 0 && selectedRows.size === data.length}
                            /></th> */}
                        {/* Отображаем только заголовки активных полей */}
                        {fieldsToShow.map((field, i) => (
                            <th key={i+field} className="px-6 py-3">{selectedFields[field].label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index+item} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                          
                            {/* Отображаем только данные активных полей */}
                            {fieldsToShow.map((field, i) => (
                                <td key={i} className="px-6 py-4 whitespace-nowrap">
                                    {field === 'id' ? <div className='text-blue-500 cursor-pointer' onClick={() => chooseAff(item[field])}>{item[field]}</div> : item[field]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdvancedTable;