import React, { useEffect, useState } from "react";
import AdvancedTable from "./AdvancedTable";
import AddDesk from "./AddDesk";
import AddUserWrap from "./AddUser";
import { useHttp } from "../../hooks/http.hook";
import { useSelector, useDispatch } from "react-redux";
import { fetchDesks } from "@/redux/deskSlice";
import useAuth from "@/hooks/useAuth.hook";
import { fetchStaffs } from "@/redux/staffSlice";
import { fetchWallets } from "@/redux/walletSLice";
import AddWallet from "./AddWallet";
import Wallet from "./Wallet";

// Компонент LeadsDashboard
const StaffTable = ({ users }) => {
    // Тестовые данные
    const { token, user } = useAuth()
    const { request } = useHttp()
    const dispatch = useDispatch()
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [selectedRowsDesk, setSelectedRowsDesk] = useState(new Set());
    const [usersList, setUsersList] = useState(users);
    const [addUserModal, setAddUserModal] = useState(false);
    const { desks } = useSelector(state => state.desk)
    const { wallets, status: walletStatus } = useSelector(state => state.wallet)
    const [selectedUsers, setSelectedUsers] = useState(0);
    const [walletFilter, setWalletFilter] = useState({
        desk_id: '',
        type: ''
    });
    const [selectedFields, setSelectedFields] = useState({
        id: { label: 'ID', isChecked: true },
        first_name: { label: 'Имя', isChecked: true },
        last_name: { label: 'Фам.', isChecked: true },
        role: { label: 'ПОЗИЦИЯ', isChecked: true },
        desk: { label: 'Деск', isChecked: true },
    });
    const [deskDelectedFields, setDeskSelectedFields] = useState({
        id: { label: 'ID', isChecked: true },
        name: { label: 'Деск', isChecked: true },
    });
    const [walletModal, setWalletModal] = useState(false);

    const handleRowSelectionChange = (itemId) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allRowIds = usersList.map((item, index) => item.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    };

    const handleRowDeskSelectionChange = (itemId) => {
        setSelectedRowsDesk((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleSelectDeskAll = (e) => {
        if (e.target.checked) {
            const allRowIds = desks.map((item, index) => item.id);
            setSelectedRowsDesk(new Set(allRowIds));
        } else {
            setSelectedRowsDesk(new Set());
        }
    };

    const handleDelete = () => {
        // Логика удаления для выбранных элементов
        console.log('Удаление:', selectedRows);
        const newData = usersList.filter((item, index) => !selectedRows.has(item.id));
        // Обновляем тестовые данные и очищаем выбранные строки
        // usersList = newData; // (Это должно быть состоянием, а не переменной)
        setUsersList(newData);
        setSelectedRows(new Set()); // Очистка выбранных элементов
        console.log('Удаление:', Array.from(selectedRows));
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedUsers(0);
    }

    const chooseUser = (id) => {
        setSelectedUsers(id);
        openModal()
    }

    const getUser = (id) => {
        return usersList.find(user => user.id === id)
    }

    const handleAddUser = async (user) => {
        try {
            console.log('/api/users/add')
            const fetched = await request('/api/users/add', 'POST', { ...user })
            if (fetched && fetched.message) {
                alert(fetched.message)
            }
            setAddUserModal(false)
        } catch (e) {
            console.log("user added: ", e)
        } finally {
            dispatch(fetchStaffs({
                token: token
            }))
        }
    }

    const handleAddDesk = async (desk) => {
        try {
            const fetched = await request('/api/desks/add', 'POST', { name: desk.name, currency: desk.currency, currency_icon: desk.symbol })

            if (fetched.message)
                alert(fetched.message)
        } catch (e) {
            alert("ERROR: ", e.message)
            console.log("ERROR DESK ADD: ", e)
        } finally {
            dispatch(fetchDesks({
                token: token
            }))
        }
    }

    const handleEditUser = async (user) => {
        const editedUser = user

        if (!editedUser.desk_id)
            editedUser.desk_id = editedUser.desk.id

        if (!editedUser.role_id)
            editedUser.role_id = editedUser.role.id

        try {
            console.log('/api/users/update/' + user.id)
            const fetched = await request('/api/users/update/' + user.id, 'PUT', { ...user })
            console.log("user edited: ", fetched)
        } catch (e) {
            console.log("user edited: ", e)
        } finally {
            dispatch(fetchStaffs({
                token: token
            }))
        }
    }


    const handleUserBulkDelete = async () => {
        try {
            console.log('/api/users/delete')
            const fetched = await request('/api/users/delete/array/', 'DELETE', { ids: Array.from(selectedRows) })

            if (fetched.message) {
                alert(fetched.message)
            }

            console.log("user deleted: ", fetched)
        } catch (e) {
            alert("ERROR: ", e.message)
            console.log("ERROR user deleted: ", e)
        } finally {
            dispatch(fetchStaffs({
                token: token
            }))
        }
    }

    const handleDeskBulkDelete = async () => {
        try {
            const fetched = await request('/api/desks/delete/array/', 'DELETE', { ids: Array.from(selectedRowsDesk) })

            if (fetched.message) {
                alert(fetched.message)
                setSelectedRowsDesk(new Set())
                dispatch(fetchDesks({
                    token: token
                }))
            }

            console.log("desk deleted: ", fetched)
        } catch (e) {
            alert("ERROR: ", e.message)
            console.log("ERROR desk deleted: ", e)
        } finally {
            dispatch(fetchDesks({
                token: token
            }))
        }
    }

    const handleDeleteWallet = async (id) => {
        try {
            await request('/api/wallets/delete/' + id, 'DELETE', null)
        } catch (e) {
            alert("ERROR: ", e.message)
            console.log("ERROR wallet deleted: ", e)
        } finally {
            dispatch(fetchWallets({
                token: token
            }))
        }
    }

    const handleEditWallet = async (wallet) => {
        try {
            const fetched = await request('/api/wallets/update/' + wallet.id, 'PUT', { ...wallet })
            console.log("wallet edited: ", fetched)
        } catch (e) {
            alert("ERROR: ", e.message)
            console.log("ERROR wallet edited: ", e)
        } finally {
            dispatch(fetchWallets({
                token: token
            }))
        }
    }

    const handleAddWallet = async (wallet) => {
        try {
            const fetched = await request('/api/wallets/add/', 'POST', { ...wallet })
            console.log("wallet added: ", fetched)
        } catch (e) {
            alert("ERROR: ", e.message)
            console.log("ERROR wallet added: ", e)
        } finally {
            dispatch(fetchWallets({
                token: token
            }))
        }
    }

    useEffect(() => {
        if (walletStatus === 'idle')
            dispatch(fetchWallets({ token }))
    }, [token, dispatch])

    const filteredWallets = (array) => array.filter(wallet => {
        // Для каждого ключа в walletFilter проверяем, соответствует ли свойство кошелька условиям фильтра
        return Object.keys(walletFilter).every(key => {
            // Если фильтр для ключа не установлен (пустая строка), пропускаем этот критерий
            if (!walletFilter[key]) return true;
            // Если свойство кошелька соответствует фильтру, включаем кошелек в результат
            return wallet[key] == walletFilter[key];
        });
    });

    return (
        <div className="sm:container mx-auto">

            {selectedUsers !== 0 ? <AddUserWrap
                userObj={getUser(selectedUsers)}
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                openModal={openModal}
                applyChanges={handleEditUser}
            /> : ''}

            <div className="mb-2 gap-2 bg-gray-100 grid grid-cols-1 lg:grid-cols-2">
                <div className="bg-white shadow rounded-lg p-1 md:p-4">
                    <div className="flex gap-2 md:gap-4 flex-wrap lg:flex-nowrap">
                        {selectedUsers === 0 && <AddUserWrap
                            isModalOpen={addUserModal}
                            closeModal={() => setAddUserModal(false)}
                            openModal={() => setAddUserModal(true)}
                            applyChanges={handleAddUser}
                        />}
                        <button onClick={handleUserBulkDelete} className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">Удалить</button>
                    </div>
                    <AdvancedTable
                        chooseUser={chooseUser}
                        selectedRows={selectedRows}
                        handleRowSelectionChange={handleRowSelectionChange}
                        handleSelectAll={handleSelectAll}
                        selectedFields={selectedFields}
                        data={usersList}
                    />
                </div>
                <div className="bg-white shadow rounded-lg p-1 md:p-4">
                    <div className="flex gap-2 md:gap-4 flex-wrap lg:flex-nowrap">
                        <AddDesk applyChanges={handleAddDesk} />
                        <button className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded" onClick={handleDeskBulkDelete}>Удалить</button>
                    </div>
                    <AdvancedTable
                        selectedRows={selectedRowsDesk}
                        handleRowSelectionChange={handleRowDeskSelectionChange}
                        handleSelectAll={handleSelectDeskAll}
                        selectedFields={deskDelectedFields}
                        data={desks}
                        type="desk"
                    />
                </div>

            </div>
            {/* wallets list */}
            {(user.role.toLowerCase() === 'admin' || user.permissions.commonRights.paymentManagement) && <div className="bg-white shadow rounded-lg p-1 md:p-4 w-full">
                <div className="flex gap-2 md:gap-4 flex-wrap lg:flex-nowrap">
                    <AddWallet applyChanges={handleAddWallet} />
                    {/* Filters by desk & type */}
                    <div className="flex gap-2 md:gap-4 flex-wrap lg:flex-nowrap">
                        <label htmlFor="desk" className="block text-sm font-medium text-gray-700">Деск
                            <select
                                className="border border-gray-300 p-2 rounded-lg"
                                name="desk_id"
                                id="desk"
                                onChange={(e) => setWalletFilter({ ...walletFilter, desk_id: e.target.value })}
                            >
                                <option value="">Все</option>
                                {desks.map((item, index) => (
                                    <option key={index} value={item.id}>{item.name}</option>
                                ))}
                            </select>
                        </label>
                        <label htmlFor="type" className="block text-sm font-medium text-gray-700">Тип
                            <select
                                className="border border-gray-300 p-2 rounded-lg"
                                name="type"
                                id="type"
                                onChange={(e) => setWalletFilter({ ...walletFilter, type: e.target.value })}
                            >
                                <option value="">Все</option>
                                <option value="card">Карта</option>
                                <option value="crypto">Крипта</option>
                                <option value="e-pay">Банк Трансфер</option>
                            </select>
                        </label>
                    </div>
                </div>
                <div className="min-w-full overflow-x-auto shadow rounded-lg bg-white p-1 md:p-4">
                    <table className="min-w-full">
                        <thead className="bg-gray-50">
                            <tr className="text-left text-gray-500 uppercase">
                                <td className="py-3 px-4 font-semibold text-sm">название</td>
                                <td className="py-3 px-4 font-semibold text-sm">счет</td>
                                <td className="py-3 px-4 font-semibold text-sm">тип</td>
                                <td className="py-3 px-4 font-semibold text-sm">Деск</td>
                                <td className="py-3 px-4 font-semibold text-sm"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                                </svg>
                                </td>
                            </tr>
                        </thead>
                        {desks.length > 0 && filteredWallets(wallets)
                            .map((item, index) => (
                                <Wallet key={index}
                                    handleDelete={handleDeleteWallet}
                                    handleEdit={handleEditWallet}
                                    wallet={item}
                                    desks={desks} />
                            ))}
                    </table>
                </div>
            </div>}
        </div>
    );
};

export default StaffTable;
