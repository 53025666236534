import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Modal from '../Modal';
import Select from 'react-select';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTransactions, setFilters } from '@/redux/transactionSlice';
import useAuth from '@/hooks/useAuth.hook';
import { fetchManagers } from '@/redux/managersSlice';

// Компонент для фильтров
const Filters = ({ applyChanges, defaultFilters, filters }) => {
    console.log('filters: ', filters)
    const { token } = useAuth();
    const [fields, setFields] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [localFilters, setLocalFilters] = useState({});
    const { desks, status } = useSelector(state => state.desk)
    // const { filters } = useSelector(state => state.transactions)
    const { managers, status: managerStatus } = useSelector(state => state.manager)

    const dispatch = useDispatch();

    const openModal = () => {
        const initialFilters = {};
        setIsModalOpen(true);
    };
    const closeModal = () => setIsModalOpen(false);

    const applyFilters = () => {
        // dispatch(fetchTransactions({ token, limit: 10, offset: 0, filters: localFilters }));
        // dispatch(setFilters(localFilters));
        // closeModal();
        applyChanges(localFilters, closeModal)
    };


    const handleFilterChange = (key, selectedOption, type) => {
        // console.log('handleFilterChange: ', key, selectedOption, type)
        const filterObj = {};

        if (type === 'multy_select') {
            const currentFilterValues = filters[key]?.split(',') || [];
            const newFilterValues = selectedOption.map(option => option.value);
            console.log('handleFilterChange: ', currentFilterValues, newFilterValues)

            // Add new values to the filter
            const updatedFilterValues = [...newFilterValues];

            // Remove duplicate values
            const uniqueFilterValues = [...new Set(updatedFilterValues)];

            filterObj[key] = uniqueFilterValues.join(',');
        } else {
            filterObj[key] = selectedOption.value;
        }

        // Update the filters
        const updatedFilters = { ...localFilters, ...filterObj };
        setLocalFilters(updatedFilters)
        // dispatch(fetchTransactions({ token, limit: 10, offset: 0, filters: updatedFilters }));
    };

    useEffect(() => {
        setFields(prev => {
            const newFilters = [];
            // add desks
            if (desks.length) {
                newFilters.push({
                    label: 'Деск', key: "desk", type: 'multy_select', defaultValue: 'all',
                    options: [{ label: 'Все', value: 'all' }, ...desks.map(desk => ({ label: desk.name, value: desk.name }))]
                })
            }
            // status
            newFilters.push({
                label: 'Статус', key: "status", type: 'select', defaultValue: 'all',
                options: [{ label: 'Все', value: 'all' }, { label: 'Успех', value: 'success' }, { label: 'Ошибка', value: 'error' }, { label: 'В ожидании', value: 'pending' }]
            });

            newFilters.push({
                label: 'FTD', key: "fdt", type: 'select', defaultValue: 'all',
                options: [{ label: 'Все', value: 'all' }, { label: 'Да', value: 'true' }, { label: 'Нет', value: 'false' }]
            });


            newFilters.push({
                label: 'TEST', key: "test", type: 'select', defaultValue: 'all',
                options: [{ label: 'Все', value: 'all' }, { label: 'Да', value: 'true' }, { label: 'Нет', value: 'false' }]
            });

            newFilters.push({
                label: 'Тип', key: "type", type: 'select', defaultValue: 'all',
                options: [{ label: 'Все', value: 'all' }, { label: 'Депозит', value: 'deposit' }, { label: 'Вывод', value: 'withdraw' }, { label: 'Бонус', value: 'bonus' }, { label: 'Кредит', value: 'credit' }, { label: 'Корекция', value: 'correction' }]
            });

            newFilters.push({
                label: 'Менеджер', key: "manager", type: 'multy_select', defaultValue: 'all',
                options: [{ label: 'Все', value: 'all' }, ...managers.map(manager => ({ label: manager.full_name, value: manager.full_name }))]
            });


            return newFilters;
        })
    }, [status, managerStatus]);

    const handleDefaultFilters = () => {
        setLocalFilters({});
        // dispatch(fetchTransactions({ token, limit: 10, offset: 0, filters: {} }));
        // dispatch(setFilters({}));
        defaultFilters()
        closeModal();
    }

    useEffect(() => {
        if (managerStatus === 'idle' && token) {
            dispatch(fetchManagers({
                token: token
            }))
        }
    }, [token]);

    return (<>
        <button onClick={openModal} className="h-fit bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Фильтры</button>
        <Modal isOpen={isModalOpen || false} onClose={closeModal}>
            <Modal.Header>
                Фильтры
            </Modal.Header>
            <Modal.Body>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
                    {fields.map(({ label, key, type, defaultValue, options }) => <div key={key}>
                        <label className="block text-gray-700 text-sm font-bold mb-2">{label}</label>
                        <Select
                            isMulti={type === 'multy_select' ? true : undefined}
                            onChange={(selectedOption) => handleFilterChange(key, selectedOption, type)}
                            options={options}
                            defaultValue={
                                type === 'multy_select'
                                    ? filters[key]?.split(',').map(value => options.find(option => option.value === value)) || []
                                    : options.find(option => option.value === filters[key]) || null
                            }
                        />
                    </div>
                    )}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <div className="flex gap-2">
                    <button onClick={handleDefaultFilters} className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded">Очистить</button>
                    <button onClick={applyFilters} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Применить</button>
                </div>
            </Modal.Footer>
        </Modal>
    </>
    );
};

export default Filters;