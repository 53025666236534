import React, { useEffect, useState } from 'react';
import AdvancedTable from '../../components/leads/AdvancedTable'
import { useDispatch, useSelector } from 'react-redux';
import useAuth from '@/hooks/useAuth.hook';
import { fetchDealsByStatus, setDealCurrentPage } from '@/redux/leadSlice';
import { dateFormater } from '@/utils/dateFormater';
import ReactPaginate from 'react-paginate';
import { ActionsDropdown } from './UserTransaction';
import { useHttp } from '@/hooks/http.hook';
import Modal from '../Modal';

// example of a deal object from the API {
//     "id": 124,
//     "type": "up",
//     "status": "close",
//     "open_time": "2024-01-31T14:34:10",
//     "close_time": "2024-01-31T14:35:10.474617",
//     "open_price": 1.267624,
//     "close_price": 1.267559,
//     "duration": 60,
//     "value": 10,
//     "profit": 0,
//     "active_id": 1,
//     "lead_id": 3
// }

const UserOffers = ({ id }) => {
    const { request } = useHttp();
    const dispatch = useDispatch();
    const { token } = useAuth();
    const { items: deals, status, perPage, currentPage, total } = useSelector(state => state.lead.statusDeals)
    const { actives } = useSelector(state => state.active);
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [showModal, setShowModal] = useState(false);
    const [selectedDeal, setSelectedDeal] = useState(null);


    const getDealsByStatus = () => {
        dispatch(fetchDealsByStatus({ token, lead_id: id, limit: perPage, offset: (currentPage - 1) * perPage }));
    }

    useEffect(() => {
        getDealsByStatus();
    }, [id, currentPage, perPage]);

    const handlePageChange = (newPage) => {
        dispatch(setDealCurrentPage(newPage.selected + 1));
    };
    const pageNumbers = Math.ceil(total / perPage);

    const handleRowSelectionChange = (itemId) => {
        console.log('Выбран элемент с id:', itemId);
        console.log('Выбраны элементы:', Array.from(selectedRows));
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleDelete = async (id) => {

        try {
            const response = await request(`/api/deals/delete/${id}`, 'DELETE', null);
            console.log(response);
            if (response.message && response.message.includes('deleted successfully')) {
                getDealsByStatus();
                alert('Deleted successfully!');
            }
        } catch (e) {
            console.log(e);
            alert('Delete failed!');
        }

    }

    const handleEdit = (id) => {
        setSelectedDeal(deals.find(deal => deal.id === id));
        setShowModal(true);
    }

    const handleDealUpdate = async (e) => {
        // DEAL
        // {
        //     "type": "string",
        //     "status": "string",
        //     "open_time": "2024-02-10T13:12:57.416Z",
        //     "close_time": "2024-02-10T13:12:57.416Z",
        //     "open_price": 0,
        //     "close_price": 0,
        //     "duration": 0,
        //     "value": 0,
        //     "profit": 0,
        //     "active_id": 0,
        //     "lead_id": 0
        //   }
        console.log('form submitted');
        e.preventDefault();
        const data = new FormData(e.target);
        const jsonData = {};
        data.forEach((value, key) => {
            jsonData[key] = value;
        });

        ///api/deals/update/${deal_id}
        // SEND JSON

        try {
            const response = await request(`/api/deals/update/${data.get('deal_id')}`, 'PUT', jsonData);
            console.log(response);
            if (response) {
                getDealsByStatus();
                alert('Updated successfully!');
            }
        } catch (e) {
            console.log(e);
            alert('Update failed!');
        }
    }

    return <div className="sm:container mx-auto relative mb-4">
        <div className="mb-2">
            {/* pagination */}
            <ReactPaginate
                initialPage={currentPage - 1}
                previousLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10.707 5.293a1 1 0 010 1.414L7.414 10l3.293 3.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>}
                nextLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M9.293 14.707a1 1 0 010-1.414L12.586 10 9.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageNumbers}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'rounded-full border bg-gray-200'}
                className="flex justify-center mt-4 items-center gap-1"
                pageClassName="flex items-center justify-center w-8 h-8 "
            />
        </div>
        <AdvancedTable data={deals.map(deal => ({
            ...deal,
            open_time: dateFormater(deal.open_time),
            close_time: dateFormater(deal.close_time),
            active: actives.find(active => active.id === deal.active_id)?.type,
            actions: <ActionsDropdown
                handleDelete={() => handleDelete(deal.id)}
                handleEdit={() => handleEdit(deal.id)}
            />
        }))}
            selectedRows={selectedRows}
            handleRowSelectionChange={handleRowSelectionChange}
            handleSelectAll={() => null}
            selectedFields={{
                "id": { "label": "ID", "isChecked": true },
                "status": { "label": "СТАТУС", "isChecked": true },
                "type": { "label": "ТИП", "isChecked": true },
                "open_time": { "label": "ВРЕМЯ ОТКРЫТИЯ", "isChecked": true },
                "close_time": { "label": "ВРЕМЯ ЗАКРЫТИЯ", "isChecked": true },
                "open_price": { "label": "ЦЕНА ОТКРЫТИЯ", "isChecked": true },
                "close_price": { "label": "ЦЕНА ЗАКРЫТИЯ", "isChecked": true },
                "duration": { "label": "ДЛИТЕЛЬНОСТЬ", "isChecked": true },
                "value": { "label": "СТАВКА", "isChecked": true },
                "profit": { "label": "ПРИБЫЛЬ", "isChecked": true },
                "active": { "label": "АКТИВ", "isChecked": true },
                "actions": { "label": "", "isChecked": true },
            }}
        />

        {showModal && selectedDeal && (
            <Modal isOpen={showModal || false} onClose={() => setShowModal(false)}>
                <form className="flex flex-col gap-4" onSubmit={handleDealUpdate}>
                    <Modal.Header>
                        Редактировать сделку
                    </Modal.Header>
                    <Modal.Body>
                        {/* Edit Deal form */}
                        <input type="hidden" name="deal_id" value={selectedDeal.id} />
                        {/* LEAD ID */}
                        <input type="hidden" name="lead_id" value={id} />

                        <div className="flex flex-col gap-2">
                            <label htmlFor="type">Тип</label>
                            <select className="border border-gray-300 rounded-md p-2" name='type' defaultValue={selectedDeal.type}>
                                <option value="up">Up</option>
                                <option value="down">Down</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="status">Статус</label>
                            <select className="border border-gray-300 rounded-md p-2" name="status"
                                defaultValue={selectedDeal.status}>
                                <option value="open">Open</option>
                                <option value="close">Close</option>
                                <option value="pending">Pending</option>
                            </select>
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="open_time">Время открытия</label>
                            <input
                                type="datetime-local"
                                name="open_time"
                                id="open_time"
                                defaultValue={selectedDeal?.open_time?.slice(0, 16)}
                                className="border border-gray-300 rounded-md p-2"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="close_time">Время закрытия</label>
                            <input
                                type="datetime-local"
                                name="close_time"
                                id="close_time"
                                value={''}
                                disabled
                                className="border border-gray-300 rounded-md p-2"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="open_price">Цена открытия</label>
                            <input
                                type="text"
                                name="open_price"
                                id="open_price"
                                defaultValue={selectedDeal.open_price}

                                className="border border-gray-300 rounded-md p-2"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="close_price">Цена закрытия</label>
                            <input
                                type="text"
                                name="close_price"
                                id="close_price"
                                defaultValue={selectedDeal.close_price}

                                className="border border-gray-300 rounded-md p-2"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="duration">Длительность (в секундах)</label>
                            <input
                                type="number"
                                name="duration"
                                id="duration"
                                defaultValue={selectedDeal.duration}

                                className="border border-gray-300 rounded-md p-2"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="defaultValue">Ставка</label>
                            <input
                                type="number"
                                name="value"
                                id="value"
                                defaultValue={selectedDeal.value}

                                className="border border-gray-300 rounded-md p-2"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="profit">Прибыль</label>
                            <input
                                type="number"
                                name="profit"
                                id="profit"
                                defaultValue={selectedDeal.profit}

                                className="border border-gray-300 rounded-md p-2"
                            />
                        </div>
                        <div className="flex flex-col gap-2">
                            <label htmlFor="active_id">Актив</label>
                            <select className="border border-gray-300 rounded-md p-2" name="active_id"
                                defaultValue={selectedDeal.active_id}>
                                {actives.map(active => <option key={active.id} value={active.id}>{active.type}</option>)}
                            </select>

                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => setShowModal(false)}
                        >
                            Отмена
                        </button>

                        <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            type='submit'
                        >
                            Сохранить
                        </button>

                    </Modal.Footer>
                </form>

            </Modal>
        )}
    </div >
}


export default UserOffers;