import React, { useState } from 'react';

const Wallet = ({ wallet, desks, handleDelete, handleEdit }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleDropdownToggle = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleDropdownItemClick = (itemId) => {
        // Handle dropdown item click
        console.log('Dropdown item clicked:', itemId);
    };

    return <tbody>
        <tr className="border-b border-gray-200">
            <td className="py-3 px-4 text-left whitespace-nowrap">
                <div className="text-sm text-gray-900">{wallet.name}</div>
            </td>
            <td className="py-3 px-4 text-left">
                <div className="text-sm text-gray-900">{wallet.account_number}</div>
            </td>
            <td className="py-3 px-4 text-left">
                <div className="text-sm text-gray-900">{wallet.type}</div>
            </td>
            <td className="py-3 px-4 text-left">
                <div className="text-sm text-gray-900">{
                    desks.find(desk => desk.id === wallet.desk_id).name
                }</div>
            </td>
            <td className="py-3 px-4 text-left">
                <div className="text-sm text-gray-900 cursor-pointer relative" onClick={handleDropdownToggle}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                    </svg>
                    {/* dropdown */}
                    {isDropdownOpen && (
                        <div className="absolute right-0 mt-2 w-48 bg-white rounded-md shadow-lg z-10">
                            <div className="py-1">
                              
                                <button
                                    className="block px-4 py-2 text-sm text-red-500 hover:red-gray-100 hover:text-red-600 "
                                    onClick={() => handleDelete(wallet.id)}
                                >
                                    Удалить
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </td>
        </tr>
    </tbody>
}

export default Wallet;