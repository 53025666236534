import React, { useEffect, useState } from 'react';
import Modal from "../Modal";
import { useHttp } from "../../hooks/http.hook";
import { useSelector } from 'react-redux';

const initAff = {
    id: '',
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    desk_id: '',
    notification: '',
}
const AddAffiliate = ({ aff, onChange }) => {
    const { request } = useHttp()
    // DB DATA
    const [desks, setDesks] = useState([])
    const [users, setUsers] = useState([])
    const { managers } = useSelector(state => state.manager)

    // Обработчик отправки формы
    const handleSubmit = (e) => {
        e.preventDefault();
        // Логика отправки формы
    };

    const getDesc = async () => {
        // /api/desks
        try {
            const fetched = await request('/api/desks/')
            if (fetched && fetched.desks)
                setDesks(fetched.desks)
        } catch (e) {
            console.log("user added: ", e)
        }
    }

    const getUsers = async () => {
        // /api/users
        try {
            const fetched = await request('/api/users/')
            if (fetched && fetched.users)
                setUsers(fetched.users)
        } catch (e) {
            console.log("user added: ", e)
        }
    }

    useEffect(() => {
        getDesc()
        getUsers()
    }, [])

    return (
        <div className="grid md:grid-cols-2">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="first_name">
                    Имя:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="first_name"
                    type="text"
                    placeholder="Имя"
                    value={aff.first_name}
                    onChange={onChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="last_name">
                    Фамилия:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="last_name"
                    type="text"
                    placeholder="Фамилия"
                    value={aff.last_name}
                    onChange={onChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                    Email:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="email"
                    type="email"
                    placeholder="Email"
                    value={aff.email}
                    onChange={onChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="password">
                    Пароль:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="password"
                    type="text"
                    placeholder="password"
                    value={aff.password}
                    onChange={onChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="desk_id">
                    Деск:
                </label>
                <select
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="desk_id"
                    value={aff.desk_id}
                    onChange={onChange}
                >
                    <option value="">None</option>
                    {desks.map((desk, index) => {
                        return <option key={index} value={desk.id}>{desk.name}</option>
                    })}
                    {/* Добавьте другие опции */}
                </select>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="notification">
                    Уведомления:
                </label>
                <select
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="notification"
                    value={+aff.notification}
                    onChange={onChange}
                >
                    <option value="">None</option>
                    {managers.map((user, index) => {
                        return <option key={index} value={user.id}>{user.full_name}</option>
                    })}
                    {/* Добавьте другие опции */}
                </select>
            </div>
        </div>
    );
}

const AddAffWrap = ({ affObj = null, apllyChanges, showModal, setShowModal, type = 'add' }) => {
    const [aff, setAff] = useState(affObj || initAff);

    const handleAffUpfate = (e) => {
        const { name, value } = e.target;
        setAff({ ...aff, [name]: value });
    }


    return (
        <>
            {type === 'add' && <button
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                onClick={() => setShowModal(true)}
            >
                Добавить аффилиата
            </button>}
            {showModal && (
                <Modal isOpen={showModal || false} onClose={() => setShowModal(false)}>
                    <Modal.Header>
                        Добавить Аффилиата
                    </Modal.Header>
                    <Modal.Body>
                        <AddAffiliate aff={aff} onChange={handleAffUpfate} />
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => setShowModal(false)}
                        >
                            Отмена
                        </button>

                        <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => { apllyChanges(aff); setShowModal(false) }}
                        >
                            Добавить
                        </button>

                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default AddAffWrap;