import React, { useEffect, useState } from 'react';
import Modal from "../Modal";
import Select from 'react-select'; // Обычный селект
import { useSelector } from 'react-redux';

const ActionsDropdown = ({ selectedRows, onAssign, onDelete, filterConfig }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedManager, setSelectedManager] = useState(null);
    const [selectedSale, setSelectedSale] = useState(null);
    const [selectedReten, setSelectedReten] = useState(null);
    const { sales } = useSelector(state => state.sale);
    const { retens } = useSelector(state => state.reten);
    const { managers } = useSelector(state => state.manager);

    const closeModal = () => {
        setSelectedManager(null);
        setSelectedSale(null);
        setSelectedReten(null);
        setIsModalOpen(false)
    };
    const openModal = () => setIsModalOpen(true);

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleManagerChange = (selectedOption) => {
        setSelectedManager(selectedOption);
    };

    const handleSaleChange = (selectedOption) => {
        setSelectedSale(selectedOption);
    };

    const handleRetenChange = (selectedOption) => {
        setSelectedReten(selectedOption);
    };

    const applyChanges = () => {
        const changeObj = {}
        if (selectedSale && selectedSale.value) changeObj['sale_status'] = selectedSale?.label
        if (selectedReten && selectedReten.value) changeObj['reten_status'] = selectedReten?.label
        if (selectedManager && selectedManager.value) changeObj['user_id'] = selectedManager?.value

        onAssign(changeObj);
        closeModal();
        toggleDropdown();
    }

    return (
        <div className="relative ml-auto">
            <button onClick={toggleDropdown} className="bg-blue-500 text-white p-2 rounded">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 12.75a.75.75 0 110-1.5.75.75 0 010 1.5zM12 18.75a.75.75 0 110-1.5.75.75 0 010 1.5z" />
                </svg>
            </button>
            {isOpen && (
                <ul className="absolute right-0 bg-white border mt-2 rounded shadow-lg">
                    <li onClick={openModal} className="px-4 py-2 hover:bg-gray-100 cursor-pointer">Назначить</li>
                </ul>
            )}

            <Modal isOpen={isModalOpen || false} onClose={closeModal}>
                <Modal.Header>
                    Назначения
                </Modal.Header>
                <Modal.Body>
                    <div className="grid grid-cols-1 gap-4 mb-4">
                        <div className="w-full">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Менеджер</label>
                            <Select
                                value={selectedManager}
                                onChange={handleManagerChange}
                                options={
                                    [{ label: 'none', value: null }, ...managers.map((item) => { return { label: item.full_name, value: item.id } })]
                                } // Преобразуйте данные в нужный формат
                                placeholder="Выберите поле"
                            />
                        </div>

                        <div className="w-full">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Сейл Статус</label>
                            <Select
                                value={selectedSale}
                                onChange={handleSaleChange}
                                options={
                                    [{ label: 'none', value: null }, ...sales.map(item => ({ label: item.name, value: item.id }))]
                                } // Преобразуйте данные в нужный формат
                                placeholder="Выберите поле"
                            />
                        </div>

                        <div className="w-full">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Ретеншн Статус</label>
                            <Select
                                value={selectedReten}
                                onChange={handleRetenChange}
                                options={
                                    [{ label: 'none', value: null }, ...retens.map((item) => ({ label: item.name, value: item.id }))]
                                } // Преобразуйте данные в нужный формат
                                placeholder="Выберите поле"
                            />
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={applyChanges} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Применить</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ActionsDropdown;