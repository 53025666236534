import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHttp } from "../../hooks/http.hook";
import useAuth from "../../hooks/useAuth.hook";
import { hasDataRights } from "../../utils/permissionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeadWallet } from "@/redux/leadSlice";

const User = ({ lead }) => {
  const dispatch = useDispatch()
  // Для запросов к API
  const { user, token } = useAuth();
  const { request } = useHttp();
  const { id } = lead;
  const prevIdRef = useRef();
  // Данные пользователя, которые могут быть получены из props или state
  const [userData, setUserData] = useState({
    id: lead.id,
    name: lead.first_name + " " + lead.last_name,
    lastOnline: "15/12/2023 00:44",
    transactions: {
      open: 0,
      pending: 0,
      closed: 0,
    },
    balance: 0.0,
    pledge: 0.0,
    equity: 0.0,
    freeMargin: 0.0,
    marginLevel: "100.00%",
    profit: 0.0,
    bonus: 0.0,
    deposited: 0,
    withdrawn: 0,
    credit: 0.0,
  });
  const { wallet } = useSelector(state => state.lead)

  useEffect(() => {
    if (hasDataRights(user, 'financialIndicatorsAccess') && id && id !== prevIdRef.current) {
      console.log('fetch data')
      dispatch(fetchLeadWallet({
        token,
        lead_id: id
      }))
      prevIdRef.current = id;
    }
  }, [id]);

  return (
    <div className="w-full bg-white shadow-lg rounded-lg p-6">
      <div className="flex flex-col items-center">
        <div className="">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 rounded-full text-white bg-gray-400">
            <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
        </div>
        <h3 className="text-lg font-semibold">{userData.name} </h3>
        {/* <p className="text-sm text-gray-500">{userData.lastOnline}</p> */}
      </div>

      {hasDataRights(user, 'financialIndicatorsAccess') && <div className="grid grid-cols-3 gap-4 text-center mt-4">
        {Object.entries(wallet.deals).map(([key, value]) => (
          <div key={key} className="border-dashed border-2 rounded p-2">
            <p className="font-bold">{value}</p>
            <p>{key.charAt(0).toUpperCase() + key.slice(1)}</p>
          </div>
        ))}
      </div>}

      {hasDataRights(user, 'financialIndicatorsAccess') && <div className="mt-4 grid grid-cols-2">
        <div className="flex flex-col">
          <p className="font-bold">Баланс:</p>
          <p>{wallet.balances.balance.toFixed(2)}</p>
        </div>
        {/* <div className="flex flex-col">
          <p className="font-bold">Залог:</p>
          <p>{userData.pledge.toFixed(2)}</p>
        </div> */}
        <div className="flex flex-col">
          <p className="font-bold">Свободно:</p>
          <p>{wallet.balances.available_funds.toFixed(2)}</p>
        </div>
        {/* <div className="flex flex-col">
          <p className="font-bold">Эквити:</p>
          <p>{userData.equity.toFixed(2)}</p>
        </div> */}
        <div className="flex flex-col">
          <p className="font-bold">Профит:</p>
          <p>{wallet.balances.profit.toFixed(2)}</p>
        </div>
        {/* <div className="flex flex-col">
          <p className="font-bold">Уровень Маржи:</p>
          <p>{userData.marginLevel}</p>
        </div> */}
        <div className="flex flex-col">
          <p className="font-bold">Бонус:</p>
          <p>{wallet.balances.bonus.toFixed(2)}</p>
        </div>
        <div className="flex flex-col">
          <p className="font-bold">Кредит:</p>
          <p>{wallet.balances.credit.toFixed(2)}</p>
        </div>
        <div className="flex flex-col">
          <p className="font-bold">Заведено:</p>
          <p>{wallet.balances.deposited.toFixed(2)}</p>
        </div>
        <div className="flex flex-col">
          <p className="font-bold">Выведено:</p>
          <p>{wallet.balances.withdrawn.toFixed(2)}</p>
        </div>
        {/* Добавьте дополнительные поля данных, аналогично выше */}
      </div>}
    </div>
  );
}

export default User;