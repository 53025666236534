import useAuth from "@/hooks/useAuth.hook";
import LeadsDashboard from "../components/leads/leadsTable"
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchLeads } from "@/redux/leadsSlice";

const Leads = () => {
    const { token } = useAuth();
    const dispatch = useDispatch();
    const { status, error } = useSelector((state) => state.leads);

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchLeads({ token }));
        }
    }, [status, dispatch, token]);

    return <div className="p-1 md:p-4">
        <h2 className="my-6 text-2xl font-semibold">
            Лиды
        </h2>

        <LeadsDashboard />
    </div>

}

export default Leads