import useAuth from '@/hooks/useAuth.hook';
import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const GetLeads = () => {
    const {user} = useAuth()

    const serverResponse = [
        {
            title: '1. Если Вы не предоставили токен',
            status: 401,
            response: `{ "success": false, "message": "No token provided" }`
        },
        {
            title: '2. Если Вы предоставили неверный токен',
            status: 401,
            response: `{ "success": false, "message": "Invalid token" }`
        },
        {
            title: '3. Успешный запрос',
            status: 200,
            response: `{\n` +
                `    "success": true,\n` +
                `    "message": "Leads found",\n` +
                `    "leads": [\n` +
                `        {\n` +
                `            "id": 1,\n` +
                `            "first_name": "John",\n` +
                `            "last_name": "Doe",\n` +
                `            "email": "test@gmail.com",\n` +
                `            "phone": "123456789",\n` +
                `            "campaign": "Test",\n` +
                `            "status": "New",\n` +
                `        },\n` +
                `        {\n` +
                `            "id": 2,\n` +
                `            "first_name": "John",\n` +
                `            "last_name": "Doe",\n` +
                `            "email": "example2@gmail.com",\n` +
                `            "phone": "123456789",\n` +
                `            "campaign": "Test",\n` +
                `            "status": "New",\n` +
                `        }\n` +
                `    ]\n` +
                `}`
        }
    ]
    return (
        <div className="sm:container mx-auto p-1 md:p-4 z-10 relative">
            <h2 className="my-6 text-2xl font-semibold">
                GET LEADS
            </h2>

            <div className="bg-white card p-4 mb-6 xl:mb-9">
                <div className="card-body pt-0 pb-5">
                    <div className="row">
                        <h4 className="fw-bold mb-3">Этот запрос инициируется вами для получения статуса лидов</h4>
                        <h4 className="fw-bold mb-5">URL для получения статуса лидов: <code>https://domain.com/api/aff_leads/get_leads_status?token={user.token}&limit=10&offset=0</code></h4>
                        <h4 className="fw-bold mb-3">limit и offset отвечают за кол-во вывода на странице и смещение соответсвенно</h4>
                        <h4 className="fw-bold mb-3">Дополнительные параметры для фильтрации: <code>start_date, end_date, status, ftd, email</code></h4>
                        <h4 className="fw-bold mb-3">Пример запроса: <code>https://domain.com/api/aff_leads/get_leads_status?token={user.token}&amp;start_date=2022-01-02&amp;end_date=2022-01-09&amp;status=New&amp;ftd=true</code></h4>
                    </div>

                    <div className="mt-6">
                        <div className="mb-2">
                            <h2 className="font-bold mb-5">Возможные ответы сервера:</h2>
                        </div>
                        {serverResponse.map((item, index) => (
                            <div key={index} className="mt-6 mb-3">
                                <div className="mb-2">
                                    <h2 className="font-bold mb-5">{item.title}</h2>
                                    <SyntaxHighlighter language="json" style={tomorrowNight}>
                                        {'STATUS ' + item.status + '\n\n' + item.response}
                                    </SyntaxHighlighter>


                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetLeads