import TransactionsTable from "../components/transactions/TransactionsTable"


const Transactions = () => {

    return <div className="p-1 md:p-4">
        <h2 className="my-6 text-2xl font-semibold">
            Транзакции
        </h2>


        <TransactionsTable />
    </div>

}

export default Transactions