// Aff object
// {
//     "id": 1,
//     "first_name": "Asd",
//     "last_name": "editovich",
//     "email": "testedit@gmail.com",
//     "password": "123",
//     "notification": 10,
//     "token": "acd80c914060649e5d2e1928cab9fa3a",
//     "created_at": "2023-12-20T14:10:44.408168",
//     "desk": "RU",
//     "desk_id": 1
// }
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const fetchAffiliates = createAsyncThunk(
    'affiliates/fetchAffiliates',
    async ({ token, limit = 10, offset = 0 }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/affiliates/?limit=100`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить дески');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const affiliatesSlice = createSlice({
    name: 'affiliates',
    initialState: {
        affiliates: [],
        status: 'idle',
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchAffiliates.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchAffiliates.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.affiliates = action.payload.affiliates;
            })
            .addCase(fetchAffiliates.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            });
    },
});

export default affiliatesSlice.reducer;