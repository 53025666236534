import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useHttp } from '../hooks/http.hook'
import useAuth from '../hooks/useAuth.hook';
import { TEInput, TERipple } from "tw-elements-react";

const Login = () => {

    const { request } = useHttp()
    const auth = useAuth()
    const navigate = useNavigate()

    const [loginDate, setLoginDate] = React.useState({
        email: '',
        password: ''
    })

    React.useEffect(() => {
        if (!!auth.token)
            navigate("/");
    })

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await request(`/api/auth/login`, 'POST', loginDate)
            console.log(res)
            if (res.access_token)
                auth.setToken(res.access_token)

            // navigate("/");
        } catch (error) {
            return false
        } finally {
            console.log(auth)
        }

    }

    return (
        <section className="h-full bg-neutral-200 ">
            <div className="h-full p-10">
                <div className="g-6 flex h-full flex-wrap items-center justify-center text-neutral-800 ">
                    <div className="w-full">
                        <div className="block rounded-lg bg-white shadow-lg ">
                            <div className="g-0 lg:flex lg:flex-wrap">
                                {/* <!-- Left column container--> */}
                                <div className="px-4 md:px-0 w-full">
                                    <div className="md:mx-6 md:p-12">
                                        {/* <!--Logo--> */}
                                        <div className="text-center">
                                            <img
                                                className="mx-auto w-48"
                                                src="/wa-crm.png"
                                                alt="logo"
                                            />
                                            <h4 className="mb-12 mt-1 pb-1 text-xl font-semibold">
                                                WA-CRM
                                            </h4>
                                        </div>

                                        <form onSubmit={handleSubmit}>
                                            <p className="mb-4">Please login to your account</p>
                                            {/* <!--Username input--> */}
                                            <div className="w-full px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                    Email
                                                </label>
                                                <input onChange={
                                                    (e) => setLoginDate({ ...loginDate, email: e.target.value })
                                                } className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" required id="grid-first-name" type="text" placeholder="Email" />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>

                                            {/* <!--Password input--> */}
                                            <div className="w-full px-3 mb-6 md:mb-0">
                                                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                                                    Password
                                                </label>
                                                <input onChange={
                                                    (e) => setLoginDate({ ...loginDate, password: e.target.value })
                                                } className="appearance-none block w-full bg-gray-200 text-gray-700 border rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" required id="grid-first-name" type="password" placeholder="Password" />
                                                {/* <p className="text-red-500 text-xs italic">Please fill out this field.</p> */}
                                            </div>

                                            {/* <!--Submit button--> */}
                                            <div className="mb-12 pb-1 pt-1 text-center">
                                                <TERipple rippleColor="light" className="w-full">
                                                    <button
                                                        className="mb-3 inline-block w-full rounded px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_rgba(0,0,0,0.2)] transition duration-150 ease-in-out hover:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)] focus:outline-none focus:ring-0 active:shadow-[0_8px_9px_-4px_rgba(0,0,0,0.1),0_4px_18px_0_rgba(0,0,0,0.2)]"
                                                        type="submit"
                                                        style={{
                                                            background:
                                                                "linear-gradient(to right, #ee7724, #d8363a, #dd3675, #b44593)",
                                                        }}
                                                    >
                                                        Log in
                                                    </button>
                                                </TERipple>

                                                {/* <!--Forgot password link--> */}
                                                {/* <a href="#!">Forgot password?</a> */}
                                            </div>

                                            {/* <!--Register button--> */}
                                            {/* <div className="flex items-center justify-between pb-6">
                                                <p className="mb-0 mr-2">Don't have an account?</p>
                                                <TERipple rippleColor="light">
                                                    <button
                                                        type="button"
                                                        className="inline-block rounded border-2 border-danger px-6 pb-[6px] pt-2 text-xs font-medium uppercase leading-normal text-danger transition duration-150 ease-in-out hover:border-danger-600 hover:bg-neutral-500 hover:bg-opacity-10 hover:text-danger-600 focus:border-danger-600 focus:text-danger-600 focus:outline-none focus:ring-0 active:border-danger-700 active:text-danger-700"
                                                    >
                                                        Register
                                                    </button>
                                                </TERipple>
                                            </div> */}
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
};

export default Login;