
// Функция для проверки финансовых прав
export function hasFinancialRight(user, right) {
    if (user.role === 'Admin') return true;

    return Boolean(user.permissions.financialRights[right]);
}

export function hasCommonRights(user, permission) {

    if (user.role === 'Admin') return true;

    if (user.permissions.commonRights[permission] === 'none') return false;

    return true;
}

export function hasDataRights(user, right) {
    if (user.role === 'Admin') return true;

    return Boolean(user.permissions.dataRights[right]);
}

export function hasModificationRights(user, right) {
    if (user.role === 'Admin') return true;

    return Boolean(user.permissions.modificationRights[right]);
}

export const haseNewsPermissions = (user) => {
    if (user.role === 'Admin') return true;

    
    if (user.permissions.commonRights.newsManagement === 'none') return false;


    return user.permissions.commonRights.newsManagement;
}