import React, { useCallback, useEffect, useState } from 'react';
import Modal from "../Modal";
import { dateFormater } from '../../utils/dateFormater';
import { useHttp } from '../../hooks/http.hook';
import { useManagers } from '../../context/ManagersContext';
import { isEmpty } from 'lodash';
import { hasDataRights, hasModificationRights } from '../../utils/permissionsHelper';
import useAuth from '../../hooks/useAuth.hook';
import { useSelector } from 'react-redux';

const UserInfo = ({ lead, handleSubmit }) => {
    const { user } = useAuth();
    const { request, loading } = useHttp();
    const [editingField, setEditingField] = useState(null);
    const [clientInfo, setClientInfo] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { sales } = useSelector(state => state.sale);
    const { retens } = useSelector(state => state.reten);
    const { managers } = useSelector(state => state.manager);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => { setIsModalOpen(false); setEditingField(null) }

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setEditingField({ ...editingField, value });
    };

    const handleEdit = (key) => {
        openModal()
        setEditingField(clientInfo.find(field => field.key === key))
    };

    const handleSubmitCallback = () => {
        // setClientInfo(prev => prev.map(field => field.key === field_name ? { ...field, value: field_value } : field))
        setEditingField(null)
        closeModal()
    }

    // useEffect(() => {
    //     getManagers()
    // }, []);
    useEffect(() => {
        if (!isEmpty(lead)) {
            const leadData = []


            if (hasDataRights(user, 'personalInfoAccess')) {
                leadData.push({ label: 'Имя', key: 'first_name', value: lead.first_name, editable: hasModificationRights(user, 'fullNameAccess'), type: 'text' })
                leadData.push({ label: 'Фамилия', key: 'last_name', value: lead.last_name, editable: hasModificationRights(user, 'fullNameAccess'), type: 'text' })
            }
            //emailAccess
            if (hasDataRights(user, 'emailAccess')) {
                leadData.push({ label: 'Email', key: 'email', value: lead.email, editable: hasModificationRights(user, 'emailAccess'), type: 'text' })
            }

            if (hasDataRights(user, 'phoneAccess')) {
                leadData.push({ label: 'Телефон', key: 'phone', value: lead.phone, editable: hasModificationRights(user, 'phoneAccess'), type: 'text' })
            }

            if (hasDataRights(user, 'secondaryPhoneAccess')) {
                leadData.push({ label: 'Второй телефон', key: 'second_phone', value: lead.second_phone, editable: hasModificationRights(user, 'secondaryPhoneAccess'), type: 'text' })
            }
            //saleStatusAccess
            if (hasDataRights(user, 'saleStatusAccess')) {
                leadData.push({ label: 'Сейл Статус', key: 'sale_status', value: lead.sale_status, editable: hasModificationRights(user, 'saleStatusAccess'), type: 'select' })
            }
            //retentionStatusAccess
            if (hasDataRights(user, 'retentionStatusAccess')) {
                leadData.push({ label: 'Ретен Статус', key: 'reten_status', value: lead.reten_status, editable: hasModificationRights(user, 'retentionStatusAccess'), type: 'select' })
            }
            //affiliateAccess
            if (hasDataRights(user, 'affiliateAccess')) {
                leadData.push({ label: 'Аффилиат', key: 'affiliate', value: lead.affiliate || 'none', editable: false, type: 'text' })
            }
            //campaignAccess
            if (hasDataRights(user, 'campaignAccess')) {
                leadData.push({ label: 'Кампания', key: 'campaign', value: lead.campaign || '', editable: false, type: 'text' })
            }

            setClientInfo([
                ...leadData,
                { label: 'Пароль', key: 'password', value: lead.password || 123, editable: true, type: 'text' },
                { label: 'Менеджер', key: 'user_id', value: lead.manager_id || 'none', editable: hasModificationRights(user, 'changeManagerAccess'), type: 'select' },
                { label: 'Деск', key: 'desk', value: lead.desk || 'none', editable: false, type: 'select' },
                { label: 'Дата регистрации', key: 'registrationDate', value: dateFormater(lead.created_at), editable: false, type: 'text' },
                { label: 'Описание', key: 'description', value: lead.description, editable: true, type: 'text' },
                // Add more fields as necessary
            ])
        }
    }, [lead])

    if (loading || isEmpty(lead))
        return <div></div>


    return (
        <div className="sm:container mx-auto">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 bg-white shadow-md rounded px-4 md:px-8 pt-6 pb-8 mb-4">
                {/* Iterate over clientInfo object and create input fields */}
                {clientInfo.map((field, index) => (
                    <div className="mb-4" key={index}>
                        <div className="grid grid-cols-3 grid-flow-col justify-between text-gray-700 text-sm mb-2">
                            <span className='font-bold '>{field.label}:</span>
                            <span className='col-span-1' >
                                {field.key === 'user_id' ? managers.find(item => item.id == field.value)?.full_name : field.value}</span>
                            <span className='justify-self-end lg:justify-self-start text-gray-500 hover:text-gray-400'>
                                {field.editable && <svg onClick={() => handleEdit(field.key)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 cursor-pointer ">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                </svg>}
                            </span>
                        </div>
                    </div>
                ))}
            </div>

            {isModalOpen && !loading && (
                <Modal isOpen={isModalOpen || false} onClose={closeModal}>
                    <Modal.Header>
                        Редактировать {editingField.label}
                    </Modal.Header>
                    <Modal.Body>

                        {editingField.type === 'text' && <input
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name={editingField.key}
                            value={editingField.value}
                            onChange={handleInputChange}
                        />}

                        {editingField.type === 'select' && <select
                            className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            name={editingField.key}
                            value={editingField.value}
                            onChange={handleInputChange}
                        >
                            <option value="">none</option>
                            {editingField.key === 'user_id' && managers.map(manager => <option key={manager.id} value={manager.id}>{manager.full_name}</option>)}
                            {editingField.key === 'sale_status' && sales.map(sale => <option key={sale.id} value={sale.name}>{sale.name}</option>)}
                            {editingField.key === 'reten_status' && retens.map(reten => <option key={reten.id} value={reten.name}>{reten.name}</option>)}
                        </select>}
                        {/* {
                            "label": "Менеджер",
                            "key": "manager",
                            "value": "1",
                            "editable": true
                        } */}
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={closeModal}
                        >
                            Отмена
                        </button>

                        <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            onClick={e => handleSubmit(e, editingField.key, editingField.value, handleSubmitCallback)}
                        >
                            Применить
                        </button>

                    </Modal.Footer>
                </Modal>
            )}
        </div>
    );
};


export default UserInfo;