import React, { useState, useEffect } from 'react';
import Modal from "../Modal";

const TransactionModal = ({ onChange, trans }) => {
    // Состояния для каждого поля формы
    return (
        <div className="grid md:grid-cols-2">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="sum">
                    Сумма:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="sum"
                    type="number"
                    placeholder="Сумма"
                    value={trans.sum}
                    onChange={onChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="method">
                    Метод:
                </label>
                <select value={trans.method?.toLowerCase()} name='method' onChange={onChange} className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option value={''}>none</option>
                    <option value={'card'.toLowerCase()}>CARD</option>
                    <option value={'crypto'.toLowerCase()}>Bank Transfer</option>
                    <option value={'e-pay'.toLowerCase()}>BANK</option>
                    {/* Другие опции, если нужно */}
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="card_number">
                    Номер Карты:
                </label>
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="card_number"
                    type="text"
                    placeholder="Номер Карты"
                    value={trans.card_number}
                    onChange={onChange}
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                    Статус:
                </label>

                <select name='status' onChange={onChange}
                    value={trans.status?.toLowerCase()}
                    className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                    <option></option>
                    <option value={'error'}>Error</option>
                    <option value={'pending'}>Pending</option>
                    <option value={'success'}>Success</option>
                    {/* Другие опции, если нужно */}
                </select>

            </div>
            <div>
                <div className="mb-4">

                    <label className="inline-flex items-center">
                        <input onChange={onChange}
                            checked={trans.test} name={'test'} type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" /><span className="ml-2 text-gray-700">TEST</span>
                    </label>

                </div>

                <div className="mb-4">
                    <label className="inline-flex items-center">
                        <input onChange={onChange}
                            checked={trans.fdt} name={'fdt'} type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" />   <span className="ml-2 text-gray-700">FTD</span>
                    </label>

                </div>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="created_at">
                    Дата:
                </label>
                {/* Datatime */}
                <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    type="datetime-local"
                    name="created_at"
                    value={trans.created_at.substring(0, 16)}
                    onChange={onChange}
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="comment">
                    Комментарий:
                </label>
                {/* textarea */}
                <textarea
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    name="comment"
                    value={trans.comment}
                    onChange={onChange}
                />
            </div>
        </div>
    );
}

const TransactionModalWrap = ({ isModalOpen, closeModal, openModal, applyChanges, transObj = null }) => {
    const [trans, setTrans] = useState(transObj);

    const handleTransUpdate = (e) => {
        const key = e.target.name;
        const value = e.target.value;
        // if checkbox

        if (e.target.type === 'checkbox') {
            setTrans(prevState => ({
                ...prevState,
                [key]: !prevState[key]
            }))
            return;
        }

        setTrans(prevState => ({
            ...prevState,
            [key]: value
        }))
    };

    // Добавляем useEffect для обновления состояния trans, когда transObj меняется
    useEffect(() => {
        setTrans(transObj);
    }, [transObj]);


    return (
        <>
            {isModalOpen && (
                <Modal isOpen={isModalOpen || false} onClose={closeModal}>
                    <Modal.Header>
                        Изменение Транзакций
                    </Modal.Header>
                    <Modal.Body>
                        <TransactionModal
                            trans={trans}
                            onChange={handleTransUpdate}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                            onClick={closeModal}
                        >
                            Отмена
                        </button>

                        <button
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                            onClick={() => { applyChanges(trans); openModal(false) }}
                        >
                            Добавить
                        </button>

                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}

export default TransactionModalWrap;