import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import Modal from "../Modal";
import Select from 'react-select'; // Обычный селект
import ActionsDropdown from "./ActionsDropdown";
import AdvancedTable from "./AdvancedTable";
import Filters from "./Filters";
import FieldSettings from "./FieldSettings";
import Search from "./Search";
import { useHttp } from '../../hooks/http.hook';
import { useManagers } from '../../context/ManagersContext';
import useAuth from "../../hooks/useAuth.hook";
import { hasDataRights } from "../../utils/permissionsHelper";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeads, setCurrentPage, setFilters, setPerPage } from "@/redux/leadsSlice";
import ReactPaginate from 'react-paginate';
import _ from 'lodash'

const initFilterConfig = [

    {
        label: 'Аффилиат', key: "affiliate", type: 'multy_select', defaultValue: 'all',
        options: [{ label: 'Все', value: 'all' }, { label: 'Google Ads', value: 'google' }, { label: 'Facebook Ads', value: 'facebook' }]
    },
    {
        label: 'Кампания', key: "campaign", type: 'multy_select', defaultValue: 'all',
        options: [{ label: 'Все', value: 'all' }, { label: 'Summer Sale', value: 'summer' }, { label: 'Winter Sale', value: 'winter' }]
    },

    {
        label: 'Гео', key: "geo", type: 'multy_select', defaultValue: 'all',
        options: [{ label: 'Все', value: 'all' }, { label: 'RU', value: 'ru' }, { label: 'PL', value: 'pl' }]
    },
    {
        label: 'Подтверждение Личности', key: "identity", type: 'select', defaultValue: 'all',
        options: [{ label: 'none', value: '' }, { label: 'Pending', value: 'pending' }, { label: 'Verified', value: 'verified' }]
    },
    {
        label: 'Подтверждение Адреса', key: "address", type: 'select', defaultValue: 'all',
        options: [{ label: 'none', value: '' }, { label: 'Pending', value: 'pending' }, { label: 'Verified', value: 'verified' }]
    },
    {
        label: 'Карта (фронтальная):', key: "cardFront", type: 'select', defaultValue: 'all',
        options: [{ label: 'none', value: '' }, { label: 'Pending', value: 'pending' }, { label: 'Verified', value: 'verified' }]
    },
    {
        label: 'Карта (обратная):', key: "cardBack", type: 'select', defaultValue: 'all',
        options: [{ label: 'none', value: '' }, { label: 'Pending', value: 'pending' }, { label: 'Verified', value: 'verified' }]
    },
    {
        label: 'Место Работы:', key: "workPlace", type: 'select', defaultValue: 'all',
        options: [{ label: 'none', value: '' }, { label: 'Pending', value: 'pending' }, { label: 'Verified', value: 'verified' }]
    },
    {
        label: 'Сортировать по:', key: "sort", type: 'select', defaultValue: 'all',
        options: [{ label: 'Date', value: 'date' }, { label: 'Reminder', value: 'reminder' }, { label: 'Last Modified', value: 'last_modified' }]
    },
    {
        label: 'Порядок', key: "order", type: 'select', defaultValue: 'all',
        options: [{ label: 'Ascending', value: 'asc' }, { label: 'Descending', value: 'desc' }]
    }
];

// Компонент LeadsDashboard
const LeadsDashboard = () => {
    const { user, token } = useAuth()
    const { request } = useHttp()
    const [filterConfig, setFilterConfig] = useState(initFilterConfig);
    const { managers } = useManagers();
    const { leads, perPage, currentPage, total, filters } = useSelector(state => state.leads)
    // affiliate

    const dispatch = useDispatch();
    // Тестовые данные
    const [selectedRows, setSelectedRows] = useState(new Set());

    const [selectedFields, setSelectedFields] = useState(() => {

        const leadData = {}


        if (hasDataRights(user, 'personalInfoAccess')) {
            leadData.lead = { label: 'Лид', isChecked: true }
        }
        //saleStatusAccess
        if (hasDataRights(user, 'saleStatusAccess')) {
            leadData.sale_status = { label: 'Сейл Статус', isChecked: true }
        }
        //retentionStatusAccess
        if (hasDataRights(user, 'retentionStatusAccess')) {
            leadData.reten_status = { label: 'Ретен Статус', isChecked: true }
        }

        leadData.manager = { label: 'Менеджер', isChecked: true }

        if (hasDataRights(user, 'phoneAccess')) {
            leadData.phone = { label: 'Телефон', isChecked: true }
        }

        //emailAccess
        if (hasDataRights(user, 'emailAccess')) {
            leadData.email = { label: 'Email', isChecked: true }
        }

        leadData.reminder = { label: 'Напоминание', isChecked: true }
        //last comment
        leadData.lastComment = { label: 'Последний коммент', isChecked: true }
        leadData.created_at = { label: 'Зарегистрирован', isChecked: true }
        leadData.desk = { label: 'Деск', isChecked: true }

        leadData.ip = { label: 'IP', isChecked: true }
        //affiliateAccess
        if (hasDataRights(user, 'affiliateAccess')) {
            leadData.affiliate = { label: 'Аффилиат', isChecked: true }
        }

        //campaignAccess
        if (hasDataRights(user, 'campaignAccess')) {
            leadData.campaign = { label: 'Кампания', isChecked: true }
        }
        if (hasDataRights(user, 'financialIndicatorsAccess')) {
            leadData.deposit = { label: 'Заведено', isChecked: true }
            leadData.balance = { label: 'Баланс', isChecked: true }
        }

        return {
            id: { label: 'ID', isChecked: true },
            ...leadData,
            actives: { label: 'Действия', isChecked: true }
            // sale_status: { label: 'Сейл Статус', isChecked: true },
            // reten_status: { label: 'Ретен Статус', isChecked: true },
            // manager: { label: 'Менеджер', isChecked: true },
            // phone: { label: 'Телефон', isChecked: true },
            // email: { label: 'Email', isChecked: true },
            // reminder: { label: 'Напоминание', isChecked: true },
            // lastComment: { label: 'Последний коммент', isChecked: true },
            // created_at: { label: 'Зарегистрирован', isChecked: true },
            // desk: { label: 'Деск', isChecked: true },
            // affiliate: { label: 'Аффилиат', isChecked: true },
            // campaign: { label: 'Кампания', isChecked: true },
            // deposited: { label: 'Заведено', isChecked: true },
            // balance: { label: 'Баланс', isChecked: true },
        }
    });

    const [searchQuery, setSearchQuery] = useState("");



    const handleFieldChange = (fieldName) => {
        setSelectedFields(prevFields => ({
            ...prevFields,
            [fieldName]: {
                ...prevFields[fieldName],
                isChecked: !prevFields[fieldName].isChecked
            }
        }));
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleRowSelectionChange = (itemId) => {
        console.log('Выбран элемент с id:', itemId);
        console.log('Выбраны элементы:', Array.from(selectedRows));
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allRowIds = leads.map((item, index) => item.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    };

    const handleDelete = async () => {
        // Логика удаления для выбранных элементов
        console.log('Удаление:', selectedRows);
        const newData = leads.filter((item, index) => !selectedRows.has(item.id));
        try {
            const data = await request('/api/leads/delete/array/', 'DELETE', { ids: Array.from(selectedRows) })
            console.log('data', data)
        } catch (e) {
            alert('Ошибка удаления')
            console.log('error', e)
        } finally {
            setSelectedRows(new Set());
            dispatch(fetchLeads({ token: token }));
        }
    };

    const handleAssign = async (status) => {
        // Логика назначения для выбранных элементов
        console.log('Назначение для:', Array.from(selectedRows), status);
        if (_.isEmpty(status) || _.isEmpty(selectedRows)) {
            alert('No Selectd value');
            return;
        }
        try {
            const respons = await request(`/api/leads/bulk_update_leads/`, 'PUT', {
                ids: Array.from(selectedRows),
                ...status
            })
            if (respons.message) {
                alert(respons.message)
                setSelectedRows(new Set())
                dispatch(fetchLeads({ token, limit: perPage, offset: (currentPage - 1) * perPage }));
            }
        } catch (e) {
            alert('Error: ', e.message)
        }
    };


    const applyFilters = (localFilters, callback) => {
        console.log('applyFilters: ', localFilters);
        dispatch(setFilters(localFilters));
        callback();
    };

    // /api/leads/delete/{lead_id}
    const handleDeleteLead = async (id) => {
        try {
            const data = await request(`/api/leads/delete/${id}`, 'DELETE')
            console.log('data', data)
            dispatch(fetchLeads({ token: token }));
            alert('Удалено успешно')
        } catch (e) {
            alert('Ошибка удаления')
            console.log('error', e)
        }
    }
    useEffect(() => {
        dispatch(fetchLeads({ token, limit: perPage, offset: (currentPage - 1) * perPage, filters }));
    }, [dispatch, currentPage, perPage, filters]);

    const handlePageChange = (newPage) => {
        dispatch(setCurrentPage(newPage.selected + 1));
    };

    const pageNumbers = Math.ceil(total / perPage);

    const handlePerPageChange = (e) => {
        dispatch(setPerPage(e.target.value));
    };

    return (
        <div className="sm:container mx-auto">

            <div className="min-h-screen bg-gray-100">
                <div className="bg-white shadow rounded-lg p-1 md:p-4">
                    <div className="flex flex-wrap lg:flex-nowrap gap-4 flex-wrap">
                        <Search searchQuery={searchQuery} onSearchChange={handleSearchChange} />
                        <Filters applyChanges={applyFilters} defaultFilters={() => dispatch(setFilters({}))} />
                        {/* change per page count */}
                        <div className="flex items-center gap-2">
                            <label htmlFor="perPage" className="text-sm">Показать:</label>
                            <select
                                id="perPage"
                                name="perPage"
                                className="border border-gray-300 rounded-md p-1"
                                value={perPage}
                                onChange={handlePerPageChange}
                            >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                        <FieldSettings selectedFields={selectedFields} handleFieldChange={handleFieldChange} />
                        <ActionsDropdown filterConfig={filterConfig} selectedRows={selectedRows} onDelete={handleDelete} onAssign={handleAssign} />
                    </div>
                    <div>
                        <div className="mb-2">
                            {/* pagination */}
                            <ReactPaginate
                                initialPage={currentPage - 1}
                                previousLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10.707 5.293a1 1 0 010 1.414L7.414 10l3.293 3.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>}
                                nextLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M9.293 14.707a1 1 0 010-1.414L12.586 10 9.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>}
                                breakLabel={'...'}
                                breakClassName={'break-me'}
                                pageCount={pageNumbers}
                                marginPagesDisplayed={2}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageChange}
                                containerClassName={'pagination'}
                                activeClassName={'rounded-full border'}
                                className="flex justify-center mt-4 items-center gap-1"
                                pageClassName="flex items-center justify-center w-8 h-8"
                            />
                        </div>
                        <AdvancedTable
                            selectedRows={selectedRows}
                            handleRowSelectionChange={handleRowSelectionChange}
                            handleSelectAll={handleSelectAll}
                            selectedFields={selectedFields}
                            data={
                                leads.map(item => {
                                    return {
                                        ...item,
                                        deposit: item.deposit ? item.deposit.toFixed(2) : 0,
                                        balance: item.balance ? item.balance.toFixed(2) : 0,
                                        lastComment: item.comment ? item.comment.comment : '',
                                        actives: <button onDoubleClick={(e) =>
                                            handleDeleteLead(item.id)
                                        } className="bg-red-500 text-white px-4 py-1 rounded-md">Удалить</button>
                                    }
                                })
                            }
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LeadsDashboard;
