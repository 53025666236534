import React, { useState, useEffect, useContext } from "react";

const ModalContext = React.createContext({
    onClose: () => { },
});

const Modal = ({ isOpen: initialIsOpen, onClose, children }) => {
    const [isOpen, setIsOpen] = useState(initialIsOpen);

    useEffect(() => {
        setIsOpen(initialIsOpen);
    }, [initialIsOpen]);

    const handleClose = () => {
        setIsOpen(false);
        if (onClose) onClose();
    };

    // close modal on ESC key press
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                handleClose();
            }
        };
        window.addEventListener("keydown", handleEsc);

        return () => {
            window.removeEventListener("keydown", handleEsc);
        };
    }, []);

    if (!isOpen) return null;

    return <ModalContext.Provider value={{ handleClose }}>
        <div
            className="bg-black bg-opacity-20 fixed left-0 top-0 z-[1055] h-full w-full overflow-y-auto overflow-x-hidden outline-none"
        >
            <div className="mt-16 -translate-y-1/2 min-w-full md:min-w-[500px] w-fit pointer-events-none relative flex min-h-[calc(100%-1rem)] w-auto translate-y-[-50px] items-center transition-all duration-300 ease-in-out min-[576px]:mx-auto">
                <div className="pointer-events-auto relative flex w-full flex-col rounded-md border-none bg-white bg-clip-padding text-current shadow-lg outline-none ">
                    {children}
                </div>
            </div>
        </div>
    </ModalContext.Provider>;

}

const ModalHeader = ({ children }) => {
    const { handleClose } = useContext(ModalContext);

    return (
        <div
            className="flex flex-shrink-0 items-center justify-between rounded-t-md border-b-2 border-neutral-100 border-opacity-100 p-4 ">
            {/* Modal title */}
            {children}
            {/* Close button */}
            <button
                onClick={handleClose}
                type="button"
                className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none">
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="h-6 w-6">
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12" />
                </svg>
            </button>
        </div>
    );
};


const ModalBody = ({ children }) => {
    return (
        <div className="relative p-4 min-h-[50vh] max-h-[75vh] overflow-auto">
            {children}
        </div>
    );
};

const ModalFooter = ({ children }) => {
    return (
        <div className="flex flex-shrink-0 flex-wrap items-center justify-end rounded-b-md border-t-2 border-neutral-100 border-opacity-100 p-4">
            {children}
        </div>
    );
};

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;