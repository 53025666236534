import React, { useState } from 'react';

// Компонент продвинутой таблицы
const AdvancedTable = ({ type = '', data, selectedFields, handleSelectAll, handleRowSelectionChange, selectedRows, chooseUser }) => {

    const fieldsToShow = Object.entries(selectedFields)
        .filter(([key, value]) => value.isChecked)
        .map(([key]) => key);



    // Здесь должна быть логика для поиска и фильтрации данных таблицы
    return (
        <div className="min-w-full overflow-x-auto shadow rounded-lg bg-white p-1 md:p-4">
            <table className="min-w-full">
                <thead className="bg-gray-50">
                    <tr className="text-left text-gray-500 uppercase">
                        <th className="px-6 py-3">
                            <input
                                className="w-4 h-4"
                                type="checkbox"
                                id="checkAll"
                                onChange={handleSelectAll}
                                checked={data.length > 0 && selectedRows.size === data.length}
                            /></th>
                        {/* Отображаем только заголовки активных полей */}
                        {fieldsToShow.map(field => (
                            <th key={field} className="px-6 py-3">{selectedFields[field].label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <input
                                    className="w-4 h-4"
                                    type="checkbox"
                                    checked={selectedRows.has(item.id)}
                                    onChange={() => handleRowSelectionChange(item.id)}
                                />
                            </td>
                            {/* Отображаем только данные активных полей */}
                            {fieldsToShow.map(field => (
                                <td key={field} className="px-6 py-4 whitespace-nowrap">
                                    {field === 'id' && type !== 'desk'
                                        ? <div className='text-blue-500 cursor-pointer' onClick={() => chooseUser(item[field])}>{item[field]}</div>
                                        // Проверяем, является ли значение по ключу field объектом
                                        : typeof item[field] === 'object' && item[field] !== null
                                            // Если это объект, выводим интересующее подполе (например, item[field].name)
                                            ? item[field].name || item[field].role || 'Не указано' // Вы можете добавить другие подполя по аналогии
                                            // Если это не объект, выводим значение напрямую
                                            : item[field]
                                    }
                                </td>
                            ))}

                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdvancedTable;