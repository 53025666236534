// desk slice for redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// chunk of get sales
// /api/users/managers/
// GET

export const fetchSale = createAsyncThunk(
    'sale/fetchSale',
    async ({ token }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/sale_status/?limit=100`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить сейлі');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });
// add desk
// '/api/sales/add', 'POST', { name: desk }

export const saleSlice = createSlice({
    name: 'sale',
    initialState: {
        sales: [],
        status: 'idle',
        error: null
    },
    reducers: {
        setSale: (state, action) => {
            state.sales = [...state.sales, action.payload];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSale.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSale.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.sales = action.payload;
            })
            .addCase(fetchSale.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setSale } = saleSlice.actions;

export default saleSlice.reducer;