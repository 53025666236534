import Affiliates from "../components/affiliates/Affiliates"


const Leads = () => {

    return <div className="p-1 md:p-4">
        <h2 className="my-6 text-2xl font-semibold">
            Аффилиаты
        </h2>


        <Affiliates />
    </div>

}

export default Leads