// Добавьте функцию, которая определяет, доступна ли вкладка на основе прав пользователя
export const isTabAccessible = (tabKey, user) => {
    const userRights = user?.permissions;
    
    if (!userRights) return false;

    if (user.role === 'Admin') return true;

    if (tabKey === '/staffs' && userRights.commonRights.staffManagement === 'none') {
        return false;
    }
    //transactionAccess
    if (tabKey === '/transactions' && userRights.commonRights.transactionAccess === 'none') {
        return false;
    }
    //newsManagement
    if (tabKey === '/news' && userRights.commonRights.newsManagement === 'none') {
        return false;
    }
    //affiliateManagement
    if (tabKey === '/affiliates' && userRights.commonRights.affiliateManagement === false) {
        return false;
    }
    //assetManagement
    if (tabKey === '/assets' && !userRights.commonRights.assetManagement) {
        return false;
    }
    // Добавьте другие условия для разных вкладок
    // ...
    return true; // По умолчанию все вкладки доступны
}