import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../components/Modal';
import UserInfo from '../components/lead/UserInfo';
import UserVerification from '../components/lead/UserVerification';
import User from '../components/lead/User';
import TransactionsTable from '../components/transactions/TransactionsTable';
import UserOffers from '../components/lead/UserOffers';
import UserHistory from '../components/lead/UserHistory';
import UserActivity from '../components/lead/UserActivity';
import UserNews from '../components/lead/UserNews';
import { useParams } from 'react-router-dom';
import { useHttp } from '../hooks/http.hook';
import { AddEventModal } from '../pages/Calendar';
import useAuth from '../hooks/useAuth.hook';
import moment from 'moment';
import { isEmpty } from 'lodash';
import UserTransaction from '../components/lead/UserTransaction';
import { hasFinancialRight, hasCommonRights, haseNewsPermissions } from '../utils/permissionsHelper';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import ReactPaginate from 'react-paginate';

moment.locale('ru');

const ActionModal = ({ isModalOpen, closeModal, transactionType }) => {
  const { request, loading } = useHttp();
  let { id } = useParams();

  const [transaction, setTransaction] = useState({
    sum: '',
    card_number: '',
    currency: '',
    method: '',
    type: transactionType,
    ftd: false,
    test: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTransaction(prev => ({ ...prev, [name]: value }));
  }

  const addTrasaction = async (e) => {
    e.preventDefault();

    try {
      const response = await request(`/api/transactions/add`, 'POST', { ...transaction, lead_id: id });
      alert('Данные успешно обновлены!')

    } catch (err) {
      console.log('Edit User Error: ', err)
    } finally {
      closeModal();
    }
  }

  return <Modal isOpen={isModalOpen || false} onClose={closeModal}>
    <form onSubmit={addTrasaction}>
      <Modal.Header>
        Добавить транзакцию
      </Modal.Header>
      <Modal.Body>
        <div className="max-w-md mx-auto my-10">
          <div className="mb-4 flex">
            <input onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="sum" type="number" placeholder="Сумма" />

            {(transactionType === 'deposit' || transactionType === 'withdrawal') && <select onChange={handleChange} className="ml-3 shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
              <option>USD</option>
            </select>}
          </div>
          {(transactionType === 'deposit' || transactionType === 'withdrawal') && <>
            <div className="mb-4">
              <input onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="card_number" type="text" placeholder="Номер Карты" />
            </div>

            <div className="mb-4">
              <select name='method' onChange={handleChange} className="shadow border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline">
                <option>VISA/MASTERCARD</option>
                {/* Другие опции, если нужно */}
              </select>
            </div>

            <div className="mb-4 flex justify-between items-center">
              <label className="inline-flex items-center">
                <input name={'fdt'} onChange={handleChange} type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" /><span className="ml-2 text-gray-700">FTD:</span>
              </label>
              <label className="inline-flex items-center">
                <input name='test' onChange={handleChange} type="checkbox" className="form-checkbox h-5 w-5 text-blue-600" /><span className="ml-2 text-gray-700">Тест:</span>
              </label>
            </div>
          </>}

        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
          Подтвердить
        </button>
      </Modal.Footer>
    </form>
  </Modal>
}

const AddComentModal = ({ isModalOpen, closeModal, handleAddComment }) => {
  let { id } = useParams();

  const [comment, setComment] = useState({
    comment: '',
    user_id: 1,
    lead_id: id,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setComment(prev => ({ ...prev, [name]: value }));
  }

  return <Modal isOpen={isModalOpen || false} onClose={closeModal}>
    <Modal.Header>
      Добавить коментарий
    </Modal.Header>
    <Modal.Body>
      <div className="max-w-md mx-auto my-10">
        <div className="mb-4 flex">
          <textarea onChange={handleChange} className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" name="comment" placeholder="Текст коментария" />
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer>
      <button onClick={() => { handleAddComment(comment); closeModal() }} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
        Подтвердить
      </button>
    </Modal.Footer>
  </Modal>
}

const ClientPage = () => {
  // State for the active tab
  const { request, loading } = useHttp();
  const { user } = useAuth();
  let { id } = useParams();
  const [lead, setLead] = useState({}); // [user, setUser
  const [activeTab, setActiveTab] = useState('info');
  const [openMenu, setOpenMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [transactionType, setTransactionType] = useState(''); // начальный тип транзакции
  const [commentModal, setCommentModal] = useState(false); // [user, setUser
  const [remaindModal, setRemaindModal] = useState(false); // [user, setUser
  const [userHistory, setUserHistory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNumbers, setPageNumbers] = useState(1);
  // data state
  const [comments, setComments] = useState([]);

  const menuRef = React.useRef();
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  }
  const getUser = useCallback(async (id) => {
    // Запрос на получение данных пользователя
    try {
      const response = await request(`/api/leads/${id}`);
      setLead(response);
    } catch (err) {
      console.error("Get Lead ERROR: ", err);
    }
  }, [id]);

  useEffect(() => {
    // outside click close menu
    const handleOutsideClick = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setOpenMenu(false);
      }
    };

    // add listener
    document.addEventListener('mousedown', handleOutsideClick);

    // remove listener
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const openTransactionModal = (type) => {
    setTransactionType(type);
    setOpenModal(true);
  }

  const closeTransactionModal = () => {
    setTransactionType('');
    setOpenModal(false);
  }

  const handleSubmit = async (e, field_name, field_value, callback = () => null) => {
    e.preventDefault();

    try {
      const response = await request(`/api/leads/update/${lead.id}`, 'PUT', { field_name, field_value });
      setLead(prev => ({ ...prev, [field_name]: field_value }))
      alert('Данные успешно обновлены!')

    } catch (err) {
      console.log('Edit User Error: ', err)
    }
  };

  const getUserHistory = useCallback(async (id) => {
    // Запрос на получение данных пользователя
    try {
      const response = await request(`/api/action_info/lead/${id}`);
      if (response.total > 0)
        setUserHistory(response.actions);
    } catch (err) {
      console.error("Get Lead ERROR: ", err);
    }
  }, [id]);

  useEffect(() => {
    getUser(id);
    getUserHistory(id);
  }, [id]);

  // Быстрый Вход (quick login) - copy to clipboard link platform.com/token=token
  const quickLogin = () => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_PLATFORM_URL}/quick-login/${lead.token || 'test'}`);
    alert('Ссылка скопирована в буфер обмена!')
  }

  // Обработчик добавления нового события
  const handleAddEvent = async (newEvent) => {
    const event = {
      title: newEvent.title,
      description: newEvent.description,
      user_id: id,
      remind_date: moment(newEvent.date).format('YYYY-MM-DD HH:mm'),
      start: moment(newEvent.date).toDate(),
      end: moment(newEvent.date).toDate(),
      lead_id: newEvent.lead_id,
    };

    try {
      const res = await request('/api/reminders/create/', 'POST', { ...event })
    } catch (e) {
      console.log(e)
    } finally {
      window.location.reload();
    }
  };

  const handleAddComment = useCallback(async (newComment) => {
    try {
      const res = await request('/api/comments/create/', 'POST', { ...newComment })
      setComments(prev => [...prev, res])
    } catch (e) {
      console.log(e)
    }
  }, [id]);

  const handleGetComments = async () => {
    try {
      const res = await request(`/api/comments/lead/${id}?limit=25&offset=${(currentPage - 1) * 25}`);
      if (res.total > 0) {
        setComments(res.comments);
        setPageNumbers(Math.ceil(res.total / 25));
      }
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => {
    handleGetComments();
  }, [handleAddComment, currentPage]);

  if (loading || isEmpty(lead))
    return <div></div>


  return (
    <div className="sm:container mx-auto p-1 md:p-4">
      {transactionType && <ActionModal isModalOpen={openModal} transactionType={transactionType} closeModal={closeTransactionModal} />}

      {remaindModal && <AddEventModal
        onClose={() => setRemaindModal(false)}
        exEvent={{ title: '', date: "", description: '', lead_id: id }}
        handleAddEvent={handleAddEvent}

      />}
      <div className="flex flex-col gap-4 lg:flex-row">
        {/* Sidebar for navigation between different sections */}
        <div className="w-full lg:w-2/6 bg-gray-100">
          <User lead={lead} />
        </div>
        {/* Main content */}
        <div className="w-full lg:w-4/6 bg-gray-100">
          <div className="w-full p-4 bg-white">
            <ul className="flex flex-row flex-wrap gap-2">
              <li className={`cursor-pointer p-2 ${activeTab === 'info' ? 'bg-gray-200' : ''}`} onClick={() => setActiveTab('info')}>Инфо</li>
              <li className={`cursor-pointer p-2 ${activeTab === 'verification' ? 'bg-gray-200' : ''}`} onClick={() => setActiveTab('verification')}>Верификация</li>
              {hasCommonRights(user, 'transactionAccess') && <li className={`cursor-pointer p-2 ${activeTab === 'transaction' ? 'bg-gray-200' : ''}`} onClick={() => setActiveTab('transaction')}>Транзакции</li>}
              <li className={`cursor-pointer p-2 ${activeTab === 'offers' ? 'bg-gray-200' : ''}`} onClick={() => setActiveTab('offers')}>Открытые сделки</li>
              <li className={`cursor-pointer p-2 ${activeTab === 'history' ? 'bg-gray-200' : ''}`} onClick={() => setActiveTab('history')}>История торговли</li>
              <li className={`cursor-pointer p-2 ${activeTab === 'activity' ? 'bg-gray-200' : ''}`} onClick={() => setActiveTab('activity')}>История изменений</li>
              {(haseNewsPermissions(user) || haseNewsPermissions(user) === 'all' || haseNewsPermissions(user) === 'lead') && <li className={`cursor-pointer p-2 ${activeTab === 'news' ? 'bg-gray-200' : ''}`} onClick={() => setActiveTab('news')}>Новости</li>}
              {console.log(haseNewsPermissions(user))}
            </ul>
            <div className='relative inline' ref={menuRef}>
              <button onClick={() => setOpenMenu(true)} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Действия</button>

              <div className={`absolute left-0 right-0 z-50 mt-2 py-2 w-56 bg-white rounded-lg shadow-xl ${openMenu ? '' : 'hidden'}`}>
                <CopyToClipboard text={`${process.env.REACT_APP_PLATFORM_URL}/quick-login/${lead.token || 'test'}`}
                  onCopy={() => alert('Скопировано в буфер обмена')}>
                  <span className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2 cursor-pointer">Быстрый Вход</span>
                </CopyToClipboard>

                {/* <div onClick={quickLogin} className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Быстрый Вход</div> */}
                {/*  */}
                <div onClick={() => setRemaindModal(true)} className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Создать напоминание</div>

                {/* Стилизация для разделителей, если они есть на скриншоте */}
                <div className="border-t border-gray-100"></div>

                {/* Подзаголовок в меню, если он нужен */}
                <div className="px-4 py-2">
                  <span className="block text-xs text-gray-400">ФИНАНСОВЫЕ ОПЕРАЦИИ</span>
                </div>

                {/* Пункты подзаголовка */}
                {hasFinancialRight(user, 'depositAccess') && <div onClick={() => openTransactionModal('deposit')} className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Депозит</div>}
                {hasFinancialRight(user, 'conclusionAccess') && <div onClick={() => openTransactionModal('withdrawal')} className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Вывод</div>}
                {hasFinancialRight(user, 'bonusAccess') && <div onClick={() => openTransactionModal('bonus')} className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Бонус</div>}
                {/* {hasFinancialRight(user, 'creditAccess') && <div onClick={() => openTransactionModal('credit')} className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Кредит</div>} */}
                {/* {hasFinancialRight(user, 'correctionAccess') && <div onClick={() => openTransactionModal('correction')} className="text-sm hover:bg-gray-100 text-gray-700 block px-4 py-2">Коррекция</div>} */}
              </div>

            </div>
          </div>
          {activeTab === 'info' && <UserInfo lead={lead} handleSubmit={handleSubmit} />}
          {activeTab === 'verification' && <UserVerification lead={lead} handleSubmit={handleSubmit} />}
          {hasCommonRights(user, 'transactionAccess') && activeTab === 'transaction' && <UserTransaction id={id} />}
          {activeTab === 'offers' && <UserOffers id={id} />}
          {activeTab === 'history' && <UserHistory id={id} />}
          {activeTab === 'activity' && <UserActivity data={userHistory} />}
          {activeTab === 'news' && <UserNews />}
          {/* Render the appropriate component based on the active tab */}
          <AddComentModal isModalOpen={commentModal} closeModal={() => setCommentModal(false)} handleAddComment={handleAddComment} />
          <div className="w-full p-4 bg-white mt-4">
            <div className="flex flex-row justify-between items-center">
              <div className="text-sm text-gray-500">Коментарии</div>
              <div onClick={() => setCommentModal(true)} className="text-sm text-gray-500 cursor-pointer bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Добавить коментарий</div>
            </div>
            <div className="mt-4">
              {/* pagination */}
              <ReactPaginate
                initialPage={currentPage - 1}
                previousLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10.707 5.293a1 1 0 010 1.414L7.414 10l3.293 3.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>}
                nextLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M9.293 14.707a1 1 0 010-1.414L12.586 10 9.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageNumbers}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'rounded-full border bg-gray-200'}
                className="flex justify-center mt-4 items-center gap-1"
                pageClassName="flex items-center justify-center w-8 h-8 "
              />
              {comments.map((comment, index) => (
                <div key={index} className="mb-4 bg-gray-100 p-4 rounded">
                  <div className="flex flex-row justify-between items-center">
                    <div className="text-sm text-gray-800 font-bold">{comment.user}</div>
                    <div className="text-sm text-gray-800">{moment(comment.created_at).format('DD.MM.YYYY HH:mm')}</div>
                  </div>
                  <div className="mt-2">
                    <div className="text-sm text-gray-800">{comment.comment}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientPage;
