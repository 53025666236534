import React, { useEffect, useState } from 'react'
import AdvancedTable from '../../components/AffLeads/AdvancedTable'
import { useHttp } from '../../hooks/http.hook'
import ReactPaginate from 'react-paginate';
import useAuth from '@/hooks/useAuth.hook';
import { useSelector, useDispatch } from 'react-redux';
import { fetchAffLeads, setCurrentPage } from '@/redux/affLeadsSlice';

const AffLeads = () => {
    const { user } = useAuth()
    const { request, loading } = useHttp()
    const { leads, status, currentPage, perPage, total, filters } = useSelector(state => state.affLeads)
    const dispatch = useDispatch()
    // const [leads, setLeads] = useState([])
    // const getLeads = async () => {
    //     try {
    //         const fetched = await request('/api/aff_leads/get_leads_status?token=acd80c914060649e5d2e1928cab9fa3a', 'GET', null)

    //         if (fetched && fetched.leads)
    //             setLeads(fetched.leads)
    //     } catch (e) {
    //         console.log(e)
    //     }
    // }

    useEffect(() => {
        dispatch(fetchAffLeads({ token: user.token, limit: perPage, offset: (currentPage - 1) * perPage }))
    }, [dispatch, currentPage, user])


    const handlePageChange = (newPage) => {
        dispatch(setCurrentPage(newPage.selected + 1));
    };


    const pageNumbers = Math.ceil(total / perPage);

    return <div className="p-1 md:p-4">
        <h2 className="my-6 text-2xl font-semibold">
            Лиды
        </h2>
        <div className="mb-2">
            {/* pagination */}
            <ReactPaginate
                initialPage={currentPage - 1}
                previousLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10.707 5.293a1 1 0 010 1.414L7.414 10l3.293 3.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>}
                nextLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M9.293 14.707a1 1 0 010-1.414L12.586 10 9.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageNumbers}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName={'pagination'}
                activeClassName={'rounded-full border bg-gray-200'}
                className="flex justify-center mt-4 items-center gap-1"
                pageClassName="flex items-center justify-center w-8 h-8 "
            />
        </div>
        <AdvancedTable
            data={leads}
            selectedFields={{
                id: { label: 'ID', isChecked: true },
                lead: { label: 'ЛИД', isChecked: true },
                email: { label: 'EMAIL', isChecked: true },
                phone: { label: 'ТЕЛЕФОН', isChecked: true },
                campaign: { label: 'КАМПАНИЯ', isChecked: true },
                status: { label: 'СТАТУС', isChecked: true },
                registered: { label: 'ДАТА', isChecked: true },
                fdt: { label: 'FTD', isChecked: true },

            }}
            handleSelectAll={() => null}
            handleRowSelectionChange={() => null}
            selectedRows={null}
        />
    </div>
}

export default AffLeads