import React, { useState } from 'react';
import { dateFormater } from '../../utils/dateFormater';

// Компонент продвинутой таблицы
const AdvancedTable = ({ data, selectedFields }) => {

    const fieldsToShow = Object.entries(selectedFields)
        .filter(([key, value]) => value.isChecked)
        .map(([key]) => key);

    // Функция для форматирования данных лида
    const formatLeadData = (item, field) => {
        if (field === 'registered') {
            return dateFormater(item[field]);
        } else if (field === 'fdt') {
            return item[field] ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-green-500 w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
          </svg>
           : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="text-red-500 w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
            </svg>;
        } else {
            // Возвращаем остальные данные как есть
            return item[field];
        }
    };

    return (
        <div className="min-w-full overflow-x-auto shadow rounded-lg bg-white p-1 md:p-4">
            <table className="min-w-full">
                <thead className="bg-gray-50">
                    <tr className="text-left text-gray-500 uppercase">
                        {fieldsToShow.map((field, i) => (
                            <th key={field + i} className="px-6 py-3">{selectedFields[field].label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => <tr key={item + index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                        {/* Отображаем только данные активных полей */}
                        {fieldsToShow.map(field => <td key={field} className="px-6 py-4 whitespace-nowrap">{formatLeadData(item, field)}</td>
                        )}
                    </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default AdvancedTable;