import './App.css';
import React from 'react';
import Layout from './layout/Layout';
import { Routes, Route, Navigate } from 'react-router-dom';
import { tabs, affOnlyTabs } from './layout/pages';
import Login from './pages/Login';
import useAuth from './hooks/useAuth.hook';
import { isTabAccessible } from './utils/isTabAccessible';
import { ManagersProvider } from './providers/ManagersProvider';
import AdminLayout from '@/layout/AdminLayout';


function App() {
  const auth = useAuth();

  return (
    <Layout>
      {/* Содержимое, которое будет отображаться в основной части лейаута */}
      <ManagersProvider>
        <AdminLayout>
          <Routes>
            {auth.isLoaded && auth.token && auth.user !== null && auth?.user?.role !== 'affiliate' && <React.Fragment> {
              tabs
                .filter(tab => isTabAccessible(tab.path, auth.user))
                .map((tab, index) => (<React.Fragment key={index}>
                  <Route
                    path={tab.path}
                    element={tab.Component}
                  />
                  {tab?.links && tab.links
                    .filter(tab => isTabAccessible(tab.path, auth.user))
                    .map((link, index) => (
                      <Route
                        key={index + 99}
                        path={link.path}
                        element={link.Component}
                      />
                    ))}
                </React.Fragment>))}

              <Route
                path="*"
                element={<Navigate to={'/'} replace />}
              />
            </React.Fragment>}
          </Routes>
        </AdminLayout>
      </ManagersProvider>

      <Routes>
        <Route path='/login' exact element={<Login />} />

        {auth.isLoaded && auth.token && auth.user !== null && auth?.user?.role === 'affiliate' && <React.Fragment> {
          affOnlyTabs
            .map((tab, index) => (<React.Fragment key={index}>
              <Route
                path={tab.path}
                element={tab.Component}
              />
              {tab?.links && tab.links
                .map((link, index) => (
                  <Route
                    key={index + 99}
                    path={link.path}
                    element={link.Component}
                  />
                ))}
            </React.Fragment>))}

          <Route
            path="*"
            element={<Navigate to={'/'} replace />}
          />
        </React.Fragment>}

        {auth.isLoaded && !auth.token && <Route
          path="*"
          element={<Navigate to={'/login'} replace />}
        />}
      </Routes>
    </Layout>
  );
}

export default App;
