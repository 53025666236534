import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { dateFormater } from '../../utils/dateFormater';
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

// Компонент продвинутой таблицы
const AdvancedTable = ({ data, selectedFields, handleSelectAll, handleRowSelectionChange, selectedRows }) => {

    const fieldsToShow = Object.entries(selectedFields)
        .filter(([key, value]) => value.isChecked)
        .map(([key]) => key);

    // Функция для форматирования данных лида
    const formatLeadData = (item, field) => {
        if (field === 'created_at') {
            return dateFormater(item[field]);
        }

        if (field === 'reminder') {
            return item[field] === null ? '' : item[field].title;
        }
        if (field === 'lead') {
            // Агрегируем first_name и last_name
            return `${item.first_name} ${item.last_name}`;
        } else {
            // Возвращаем остальные данные как есть
            return item[field];
        }
    };

    return (
        <div className="min-w-full overflow-x-auto shadow rounded-lg bg-white p-1 md:p-4">
            <table className="min-w-full">
                <thead className="bg-gray-50">
                    <tr className="text-left text-gray-500 uppercase">
                        {selectedRows === null ? '' : <th className="px-6 py-3">
                            <input
                                className="w-4 h-4"
                                type="checkbox"
                                id="checkAll"
                                onChange={handleSelectAll}
                                checked={data.length > 0 && selectedRows.size === data.length}
                            /></th>}
                        {/* Отображаем только заголовки активных полей */}
                        {fieldsToShow.map((field, i) => (
                            <th key={field + i} className="px-6 py-3">{selectedFields[field].label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => <tr key={item + index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                        {selectedRows === null ? '' : <td className="px-6 py-4 whitespace-nowrap">
                            <input
                                className="w-4 h-4"
                                type="checkbox"
                                checked={selectedRows.has(item.id)}
                                onChange={() => handleRowSelectionChange(item.id)}
                            />
                        </td>}
                        {/* Отображаем только данные активных полей */}
                        {fieldsToShow.map((field, index) => field === 'lead'
                            ? <td key={field}><NavLink to={'/leads/' + item.id} className="block text-blue-500 px-6 py-4 whitespace-nowrap">{formatLeadData(item, field)}</NavLink></td>
                            : <>
                                <td key={field + '-' + index} data-tooltip-id={field === 'actions' ? '' :'tooltip-content'} className={`px-6 py-4 whitespace-nowrap max-w-[400px] ${field === 'actions' ? '' : 'truncate'} `} data-tooltip-content={field === 'actions' ? '' : formatLeadData(item, field)}>{formatLeadData(item, field)}</td>
                                {/* add tooltip */}

                            </>
                        )}
                    </tr>
                    )}
                </tbody>
            </table>
            <Tooltip id={'tooltip-content'} place="bottom" effect="solid" style={{
                backgroundColor: 'rgba(0, 0, 0, 0.8)',
                color: 'white',
                padding: '5px',
                borderRadius: '5px',
                maxWidth: '300px'
            }} />
        </div>
    );
};

export default AdvancedTable;