import React, { useEffect, useState, useCallback } from 'react'
import Card from '../components/Card'
import { ManagerActions, Reminder, ResultsTable } from '../components/dashboard';
import { useHttp } from '../hooks/http.hook';
import { set } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReminds } from '@/redux/reminderSlice';
import useAuth from '@/hooks/useAuth.hook';

const Dashboard = () => {
    const { request } = useHttp();
    const [cardsData, setCardData] = useState([
        { key: 'todayFDT', title: 'Сегодня', value: '0', description: 'FTD', background: 'bg-green-200' },
        { key: 'monthFDT', title: 'Месяц', value: '0', description: 'FTD', background: 'bg-yellow-200' },
        { key: 'allFDT', title: 'Всего', value: '0', description: 'FTD', background: 'bg-blue-200' },
        { key: 'summToday', title: 'Сегодня', value: '0', description: 'Заведено', background: 'bg-green-200' },
        { key: 'summMonth', title: 'Месяц', value: '0', description: 'Заведено', background: 'bg-yellow-200' },
        { key: 'summAll', title: 'Всего', value: '0', description: 'Заведено', background: 'bg-blue-200' },
    ]);
    // const [reminders, setRemainders] = useState([]);
    const [managerActions, setManagerActions] = useState([]);
    const [managers, setManagers] = useState([]);
    const dispatch = useDispatch();
    const reminders = useSelector(state => state.reminders.reminders);
    const { token, user } = useAuth();

    const getFDT = useCallback(async () => {
        try {
            // Запуск всех запросов параллельно
            const results = await Promise.all([
                request('/api/transactions/get_transactions/fdt/', 'GET'),
                request('/api/transactions/get_transactions/fdt/?month=true', 'GET'),
                request('/api/transactions/get_transactions/fdt/?day=true', 'GET'),
                request('/api/transactions/get_transactions/sum/', 'GET'),
                request('/api/transactions/get_transactions/sum/?month=true', 'GET'),
                request('/api/transactions/get_transactions/sum/?day=true', 'GET')
            ]);

            // Создание нового массива данных карточек с обновленными значениями
            const updatedCardsData = cardsData.map(card => {
                let newValue;
                switch (card.key) {
                    case 'todayFDT':
                        newValue = results[2]; // значение из todayFDT
                        break;
                    case 'monthFDT':
                        newValue = results[1]; // значение из monthFDT
                        break;
                    case 'allFDT':
                        newValue = results[0]; // значение из allFDT
                        break;
                    case 'summAll':
                        newValue = results[3] + '$'; // значение из summAll
                        break;
                    case 'summMonth':
                        newValue = results[4] + '$'; // значение из summMonth
                        break;
                    case 'summToday':
                        newValue = results[5] + '$'; // значение из summToday
                        break;
                    default:
                        newValue = card.value;
                }
                return { ...card, value: newValue };
            });

            console.log('Updated cards data: ', updatedCardsData);
            setCardData(updatedCardsData);

        } catch (e) {
            console.log('Get FDT ERROR: ', e);
            // Можно реализовать дополнительные действия для обработки ошибок
        }
    }, []); // Убедитесь, что зависимости правильно настроены в массиве зависимостей.


    const getLastActions = useCallback(async () => {
        try {
            const result = await request('/api/action_info/last_day/', 'GET');
            if (result.total > 0) {
                setManagerActions(result.actions);
            }
        } catch (e) {
            console.log('setManagerActions ERROR: ', e);
        }
    }, []);

    const getManagersResults = useCallback(async () => {
        try {
            const result = await request('/api/users/managers/', 'GET');
            console.log('getManagersResults: ', result);
            if (result && result.total > 0)
                setManagers(result.managers);
        } catch (e) {
            console.log('setManagerActions ERROR: ', e);
        }
    }, []);

    useEffect(() => {
        getFDT();
    }, [getFDT]); // Зависимость от getFDT гарантирует, что данные будут загружены при монтировании компонента.

    useEffect(() => {
        getLastActions();
        getManagersResults();
    }, []);

    const managersFilterByRole = () => {
        if (user.role.toLowerCase() === 'teamlead retention') {
            return managers.filter(manager => manager.role.toLowerCase().includes('retention'));
        }

        if (user.role.toLowerCase() === 'teamlead sales') {
            return managers.filter(manager => manager.role.toLowerCase().includes('sales'));
        }

        if (user.role.toLowerCase() === 'retention' || user.role.toLowerCase() === 'sales') {
            return managers.filter(manager => manager.id === user.id);
        }

        return managers;
    };

    return <div className="p-1 md:p-4">
        <h2 className="my-6 text-2xl font-semibold">
            Дашборд
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-4">
            {cardsData.map((card, index) => (
                <Card
                    key={index}
                    title={card.title}
                    value={card.value}
                    description={card.description}
                    background={card.background}
                />
            ))}
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mb-4">
            <Reminder data={reminders} />
            <ManagerActions data={managerActions} />
        </div>
        <div>
            <ResultsTable data={managersFilterByRole()} />
        </div>
    </div>

}

export default Dashboard