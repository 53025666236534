// active slice for redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// chunk of get actives
// /api/users/managers/
// GET

export const fetchActives = createAsyncThunk(
    'active/fetchActives',
    async ({ token, limit = 100, offset = 0 }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/actives/?limit=${limit}&offset=${offset}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить дески');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });
// add active
// '/api/actives/add', 'POST', { name: active }

export const activeSlice = createSlice({
    name: 'active',
    initialState: {
        actives: [],
        total: 0,
        status: 'idle',
        currentPage: 1,
        activePerPage: 100,
        error: null
    },
    reducers: {
        setActive: (state, action) => {
            state.actives = [...state.actives, action.payload];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchActives.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchActives.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.actives = action.payload.actives;
                state.total = action.payload.total;
            })
            .addCase(fetchActives.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setActive } = activeSlice.actions;

export default activeSlice.reducer;