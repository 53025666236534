// desk slice for redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { decodeToken } from "react-jwt";

// chunk of get desks
// /api/users/managers/
// GET

export const deleteReminder = createAsyncThunk(
    'desk/deleteReminder',
    async ({ token, id }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/reminders/delete/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message || 'Не удалось удалить напоминание');
            }
            return id;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const bulkUpdateReminders = createAsyncThunk(
    'desk/bulkUpdateReminders',
    async ({ token, reminders }, { rejectWithValue }) => {
        try {
            // use promiss all
            const promisses = reminders.map(reminder => {
                return fetch(`/api/reminders/update/${reminder.id}`, {
                    method: 'PUT',
                    headers: {
                        'Content-Type': 'application/json',
                        'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                    },
                    body: JSON.stringify(reminder)
                });
            });
            const responses = await Promise.all(promisses);
            const results = await Promise.all(responses.map(response => response.json()));
            if (responses.some(response => !response.ok)) {
                throw new Error(results[0].message || 'Не удалось обновить напоминание');
            }
            return results;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const updateReminder = createAsyncThunk(
    'desk/updateReminder',
    async ({ token, reminder }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/reminders/update/${reminder.id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
                body: JSON.stringify(reminder)
            });
            const result = await response.json();
            if (!response.ok) {
                throw new Error(result.message || 'Не удалось обновить напоминание');
            }
            return result;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const fetchReminds = createAsyncThunk(
    'desk/fetchReminds',
    async ({ token }, { rejectWithValue }) => {
        try {
            let url = `/api/reminders/for_user/?limit=100&offset=0`
            const user = decodeToken(token);


            if (user.sub.role.toLowerCase() === 'admin') url = `/api/reminders/?limit=100&offset=0`

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить дески');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });
// add desk
// '/api/desks/add', 'POST', { name: desk }

export const reminderSlice = createSlice({
    name: 'desk',
    initialState: {
        reminders: [],
        total: 0,
        status: 'idle',
        // lastCount:  get from localstorage
        lastCount: localStorage.getItem('lastCount') || 0,
        error: null
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReminds.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReminds.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reminders = action.payload.reminders;
                state.total = action.payload.total;
            })
            .addCase(fetchReminds.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(updateReminder.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(updateReminder.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reminders = state.reminders.map(reminder =>
                    reminder.id === action.payload.id ? action.payload : reminder
                );
            })
            .addCase(updateReminder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(bulkUpdateReminders.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(bulkUpdateReminders.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reminders = state.reminders.map(reminder =>
                    action.payload.find(item => item.id === reminder.id) || reminder
                );
            })
            .addCase(bulkUpdateReminders.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteReminder.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(deleteReminder.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.reminders = state.reminders.filter(reminder => reminder.id !== action.payload);
            })
            .addCase(deleteReminder.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });

    }
});


export default reminderSlice.reducer;