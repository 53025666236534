import useAuth from '@/hooks/useAuth.hook';
import { handleSearchQuery, handleSearchField, fetchLeads, setFilters } from '@/redux/leadsSlice';
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

const Search = ({ searchQuery }) => {
    const dispatch = useDispatch();
    const { search, filters } = useSelector(state => state.leads);
    const { token } = useAuth();

    const searchHandler = (e) => {
        e.preventDefault();
        dispatch(fetchLeads({ token, searchQuery: search.query, searchField: search.field, filters }));
    }

    const searchHandlerReset = (e) => {
        e.preventDefault();
        dispatch(setFilters([]));
        dispatch(fetchLeads({ token }));
    }

    return <div className="md:flex-1 flex-wrap lg:flex-nowrap flex gap-1">
        <input
            type="search"
            className="md:flex-1 bg-transparent text-blue-700 font-semibold outline-0 py-2 px-4 border border-blue-500 rounded"
            placeholder="Поиск..."
            value={search.query}
            onChange={(e) => dispatch(handleSearchQuery(e.target.value))}
        />
        <select value={search.field}
            onChange={(e) => dispatch(handleSearchField(e.target.value))}
            className="bg-transparent text-blue-700 font-semibold outline-0 py-2 px-4 border border-blue-500 rounded">
            <option value="first_name">Имя</option>
            <option value="last_name">Фамилия</option>
            <option value="email">Почта</option>
            <option value="phone">Телефон</option>
            <option value="ip">IP</option>
        </select>

        <button onClick={searchHandler} className="bg-blue-500 text-white font-semibold py-2 px-4 border border-blue-500 rounded">Поиск</button>
        <button onClick={searchHandlerReset} className="bg-red-500 text-white font-semibold py-2 px-4 border border-red-500 rounded"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 0 0-3.7-3.7 48.678 48.678 0 0 0-7.324 0 4.006 4.006 0 0 0-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 0 0 3.7 3.7 48.656 48.656 0 0 0 7.324 0 4.006 4.006 0 0 0 3.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3-3 3" />
        </svg>
        </button>
    </div>
};

export default Search;