// Компонент Sidebar.js
import React, { useState, useEffect, useRef, useLayoutEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { tabs, affOnlyTabs } from './pages';
import { isTabAccessible } from '../utils/isTabAccessible';
import useAuth from '../hooks/useAuth.hook';

function Sidebar({ isOpen, setIsOpen }) {
  const auth = useAuth();
  const sidebarRef = useRef();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [openTab, setOpenTab] = useState(null);

  // Обработчик изменения размера окна
  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useLayoutEffect(() => {
    // Перед рендером страницы закрываем сайдбар, если ширина окна меньше 768px
    if (window.innerWidth < 768) {
      setIsOpen(false);
    }
  }, []);

  const handleClickOutside = (event) => {
    // Закрываем сайдбар только если ширина окна меньше 768px
    if (windowWidth < 768 && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Добавляем обработчик клика при монтировании компонента
    document.addEventListener('mousedown', handleClickOutside);

    // Очищаем обработчик при размонтировании компонента
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [windowWidth]); // Добавляем windowWidth в зависимости эффекта

  const handleLinkClick = () => {
    if (windowWidth < 768) {
      window.scrollTo(0, 0);
      setIsOpen(false);
    }
  };

  return (
    <Transition
      show={isOpen}
      enter="transition ease-out duration-150"
      enterFrom="opacity-0 transform -translate-x-full"
      enterTo="opacity-100 transform translate-x-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 transform translate-x-0"
      leaveTo="opacity-0 transform -translate-x-full"
      className="md:transform-none z-50" // На десктопных размерах экрана не применять трансформацию
    >
      <aside
        ref={sidebarRef}
        className={`fixed z-30 w-64 h-full bg-orange-50 shadow-md overflow-y-auto md:relative md:z-0 md:block md:shadow-none ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}`}
      >
        <div className="flex flex-col p-5 h-full justify-between">
          {/* Первая часть: аватар с именем и должностью */}
          <div className="flex flex-col items-center justify-center p-5 border-b">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-16 h-16 rounded-full text-white bg-gray-400">
              <path strokeLinecap="round" strokeLinejoin="round" d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>

            <h2 className="mt-2 font-bold text-lg">{auth.user.first_name} {auth.user.last_name}</h2>
            <span className="text-sm text-gray-600">{auth.user.role}</span>
          </div>

          {/* Вторая часть: меню */}
          <nav className="mt-8 text-sm">
            {auth.user.role !== 'affiliate' && tabs
              .filter(tab => isTabAccessible(tab.path, auth.user))
              .map((tab, index) => (
                tab?.isVisible !== false && <div className='mb-2' key={index}>
                  {tab.links ? (
                    // Для пунктов с подменю
                    <div>
                      <button
                        className='flex gap-4 items-center w-full p-2 font-bold text-gray-800 hover:text-gray-600'
                        onClick={() => setOpenTab(openTab === index ? null : index)} // Переключаем видимость подменю
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-6 transform transition-transform ${openTab === index ? 'rotate-90' : 'rotate-0'}`}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                        <span>{tab.groupName}</span>
                      </button>
                      <Transition
                        show={openTab === index}
                        enter="transition-opacity duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        {tab.links
                          .filter(tab => isTabAccessible(tab.path, auth.user))
                          .map((link, linkIndex) => (
                            <NavLink
                              key={linkIndex}
                              to={link.path}
                              onClick={handleLinkClick}
                              className='flex gap-2 pl-10 pr-2 py-2 font-normal text-gray-600 hover:text-gray-400'
                            >
                              <span className='text-gray-600'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>
                              </span>
                              {link.name}
                            </NavLink>
                          ))}
                      </Transition>
                    </div>
                  ) : (
                    // Для пунктов без подменю
                    <NavLink
                      to={tab.path}
                      className='flex gap-4 items-center p-2 font-bold text-gray-800 hover:text-gray-600'
                      onClick={handleLinkClick}
                    >
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                      </span>
                      {tab.groupName}
                    </NavLink>
                  )}
                </div>
              ))}

            {auth.user.role === 'affiliate' && affOnlyTabs
              .map((tab, index) => (
                tab?.isVisible !== false && <div className='mb-2' key={index}>
                  {tab.links ? (
                    // Для пунктов с подменю
                    <div>
                      <button
                        className='flex gap-4 items-center w-full p-2 font-bold text-gray-800 hover:text-gray-600'
                        onClick={() => setOpenTab(openTab === index ? null : index)} // Переключаем видимость подменю
                      >
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className={`w-4 h-6 transform transition-transform ${openTab === index ? 'rotate-90' : 'rotate-0'}`}>
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                        <span>{tab.groupName}</span>
                      </button>
                      <Transition
                        show={openTab === index}
                        enter="transition-opacity duration-75"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity duration-150"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        {tab.links
                          .map((link, linkIndex) => (
                            <NavLink
                              key={linkIndex}
                              to={link.path}
                              onClick={handleLinkClick}
                              className='flex gap-2 pl-10 pr-2 py-2 font-normal text-gray-600 hover:text-gray-400'
                            >
                              <span className='text-gray-600'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                                  <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                                </svg>
                              </span>
                              {link.name}
                            </NavLink>
                          ))}
                      </Transition>
                    </div>
                  ) : (
                    // Для пунктов без подменю
                    <NavLink
                      to={tab.path}
                      className='flex gap-4 items-center p-2 font-bold text-gray-800 hover:text-gray-600'
                      onClick={handleLinkClick}
                    >
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3" />
                        </svg>
                      </span>
                      {tab.groupName}
                    </NavLink>
                  )}
                </div>
              ))}
          </nav>

          {/* Третья часть: кнопка логаут и сетнгс */}
          <div className="flex justify-between border-t">
            <button onClick={auth.logOut} className="flex items-center text-left p-2 font-bold text-red-400 hover:text-red-600 text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75" />
              </svg>
              Выйти
            </button>

            <button className="flex items-center text-left p-2 font-bold text-blue-800 hover:text-blue-600 text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4">
                <path strokeLinecap="round" strokeLinejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
            </button>

          </div>

        </div>
      </aside>
    </Transition>
  );
}

export default Sidebar;