import { fetchDesks } from "@/redux/deskSlice"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import useAuth from "@/hooks/useAuth.hook"
import { fetchManagers } from "@/redux/managersSlice"
import { fetchRoles } from "@/redux/roleSLice"
import { fetchSale } from "@/redux/saleSlice"
import { fetchReten } from "@/redux/retenSlice"
import { fetchActives } from "@/redux/activesSlice"


const AdminLayout = ({ children }) => {
    const { token, user } = useAuth()
    const dispatch = useDispatch()
    const { desks, status, error } = useSelector(state => state.desk)
    const { status: managerStatus, error: managerError } = useSelector(state => state.manager)
    const { status: rolesStatus, error: rolesError } = useSelector(state => state.role)
    //reten
    const { status: retenStatus, error: retenError } = useSelector(state => state.reten)
    //sale
    const { status: saleStatus, error: saleError } = useSelector(state => state.sale)
    // actives  
    const { status: activesStatus, error: activesError } = useSelector(state => state.active)

    useEffect(() => {

        if (status === 'idle' && (user && user.role != "affiliate") && token) {
            console.log('fetching desks')
            dispatch(fetchDesks({
                token: token
            }))
        }
        if (managerStatus === 'idle' && (user && user.role != "affiliate") && token)
            dispatch(fetchManagers({
                token: token
            }))

        if (rolesStatus === 'idle' && (user && user.role != "affiliate") && token)
            dispatch(fetchRoles({
                token: token
            }))

        if (retenStatus === 'idle' && (user && user.role != "affiliate") && token)
            dispatch(fetchReten({
                token: token
            }))

        if (saleStatus === 'idle' && (user && user.role != "affiliate") && token)
            dispatch(fetchSale({
                token: token
            }))

        if (activesStatus === 'idle' && (user && user.role != "affiliate") && token)
            dispatch(fetchActives({
                token: token
            }))
    }, [token, user])


    if (user)
        return children

    return <div></div>
}

export default AdminLayout