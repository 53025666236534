import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import useAuth from '../../hooks/useAuth.hook';
import { hasFinancialRight } from '../../utils/permissionsHelper';
// Компонент продвинутой таблицы
const AdvancedTable = ({ data, selectedFields, chooseTrans, handleSelectAll, handleRowSelectionChange, selectedRows }) => {
    const { user } = useAuth();
    const fieldsToShow = Object.entries(selectedFields)
        .filter(([key, value]) => value.isChecked)
        .map(([key]) => key);



    // Здесь должна быть логика для поиска и фильтрации данных таблицы
    return (
        <div className="min-w-full overflow-x-auto shadow rounded-lg bg-white p-1 md:p-4">
            <table className="min-w-full">
                <thead className="bg-gray-50">
                    <tr className="text-left text-gray-500 uppercase">
                        <th className="px-6 py-3">
                            <input
                                className="w-4 h-4"
                                type="checkbox"
                                id="checkAll"
                                onChange={handleSelectAll}
                                checked={data.length > 0 && selectedRows.size === data.length}
                            /></th>
                        {/* Отображаем только заголовки активных полей */}
                        {fieldsToShow.map(field => (
                            <th key={field} className="px-6 py-3">{selectedFields[field].label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}`}>
                            <td className="px-6 py-4 whitespace-nowrap">
                                <input
                                    className="w-4 h-4"
                                    type="checkbox"
                                    checked={selectedRows.has(item.id)}
                                    onChange={() => handleRowSelectionChange(item.id)}
                                />
                            </td>
                            {/* Отображаем только данные активных полей */}
                            {fieldsToShow.map(field => {
                                if (field === 'id') return (
                                    <td key={field}>
                                        {hasFinancialRight(user, 'modifyingTransactions') ?
                                            <div onClick={() => chooseTrans(item[field])} className="block text-blue-500 px-6 py-4 whitespace-nowrap cursor-pointer">#{item[field]}</div>
                                            : <div className="block text-blue-500 px-6 py-4 whitespace-nowrap">#{item[field]}</div>}
                                    </td>
                                )

                                if (field === 'lead' || field === 'lead_id') return (
                                    <td key={field}> <NavLink to={'/leads/' + item[field]} className="block text-blue-500 px-6 py-4 whitespace-nowrap">{item[field]}</NavLink></td>
                                )

                                if (field === 'created_at') return (
                                    <td key={field} className="px-6 py-4 whitespace-nowrap">{new Date(item[field]).toLocaleString()}</td>
                                )

                                if (field === 'fdt' || field === 'test') return (
                                    <td key={field} className='px-6 py-4 whitespace-nowrap'>
                                        {item[field] ? <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-green-500">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 text-red-500">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M18.364 18.364A9 9 0 0 0 5.636 5.636m12.728 12.728A9 9 0 0 1 5.636 5.636m12.728 12.728L5.636 5.636" />
                                        </svg>
                                        }
                                    </td>
                                )
                                return (
                                    <td key={field} className="px-6 py-4 whitespace-nowrap">{item[field]}</td>
                                )
                            }
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdvancedTable;