import React, { useCallback, useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/ru';
import Modal from '../components/Modal';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useHttp } from '../hooks/http.hook';
import { fetchReminds } from '@/redux/reminderSlice';
import useAuth from '@/hooks/useAuth.hook';
import { useDispatch, useSelector } from 'react-redux';

moment.locale('ru');

const formatDateForInput = (datetime) => {
    return moment(datetime).format('YYYY-MM-DDTHH:mm');
};

const messages = {
    allDay: 'Весь день',
    previous: '<',
    next: '>',
    today: 'Сегодня',
    month: 'Месяц',
    week: 'Неделя',
    day: 'День',
    agenda: 'Список событий',
    date: 'Дата',
    time: 'Время',
    event: 'Событие', // or any other text you want to show
    // ... другие ключи для локализации
};
// Настройка локализатора с помощью moment
const localizer = momentLocalizer(moment);
const initEvent = {
    title: '',
    date: '',
    description: '',
}

const EventDetailsModal = ({ isOpen, onClose, event }) => {

    const { request } = useHttp()

    const handleDelete = async () => {
        try {
            const res = await request(`/api/reminders/delete/${event.id}`, 'DELETE')
            if (res.message)
                alert(res.message)
        } catch (e) {
            alert("Ошибка удаления")
            console.log(e)
        } finally {
            window.location.reload()
            onClose()
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Modal.Header>Детали события</Modal.Header>
            <Modal.Body>
                {event ? (
                    <div>
                        <div className='mt-4'>
                            <label className="font-bold">Название события:</label>
                            <h3 className="text-lg">{event.title}</h3>
                        </div>
                        <div className='mt-4'>
                            <label className="font-bold">Дата и время:</label>
                            <p>{event.start.toLocaleString()}</p>
                        </div>
                        <div className='mt-4'>
                            <label className="font-bold">Описание:</label>
                            <p>{event.description}</p>
                        </div>
                        {/* Форматируйте и отображайте дату и время, если нужно */}
                    </div>
                ) : (
                    <p>Нет данных о событии.</p>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleDelete}
                >
                    Удалить
                </button>

            </Modal.Footer>
        </Modal>
    );
};

export const AddEventModal = ({ onClose, exEvent = null, handleAddEvent = () => null }) => {
    const [newEvent, setNewEvent] = useState(exEvent || initEvent);

    const handleEditNewEvent = (e) => {
        const key = e.target.name;
        const value = e.target.value;

        setNewEvent(prevState => ({
            ...prevState,
            [key]: value
        }))
    };

    return (
        <Modal isOpen={true} onClose={onClose}>
            <Modal.Header>
                ДОБАВИТЬ НАПОМИНАНИЕ
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    <input
                        type="text"
                        name='title'
                        placeholder="Event Title"
                        value={newEvent.title}
                        onChange={handleEditNewEvent}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
                <div className="mb-4">

                    <input
                        type="datetime-local"
                        name='date'
                        value={formatDateForInput(newEvent.date)}
                        onChange={handleEditNewEvent}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"

                    />
                </div>
                <div className="mb-4">

                    <textarea
                        type="text"
                        name='description'
                        placeholder="Event Description"
                        value={newEvent.description}
                        onChange={handleEditNewEvent}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={onClose}
                >
                    Отмена
                </button>

                <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => { handleAddEvent(newEvent); onClose() }}
                >
                    Добавить
                </button>

            </Modal.Footer>
        </Modal >
    );
};

const MyCalendar = () => {
    // Состояние для хранения событий
    const { request } = useHttp()
    const [events, setEvents] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEventDetailsModalOpen, setIsEventDetailsModalOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [eventDetails, setEventDetails] = useState(null);
    const { token, user } = useAuth()
    const dispatch = useDispatch()

    const handleEventClick = (event) => {
        setEventDetails(event);
        setIsEventDetailsModalOpen(true); // Используйте тот же флаг или отдельный для открытия модального окна деталей
    };

    // Открытие модального окна с сохранением выбранной даты
    const handleCellSelect = (slotInfo) => {
        setSelectedDate(slotInfo.start);
        setIsModalOpen(true);
    };

    // Обработчик добавления нового события
    const handleAddEvent = useCallback(async (newEvent) => {
        const event = {
            title: newEvent.title,
            description: newEvent.description,
            user_id: 1,
            remind_date: moment(newEvent.date).format('YYYY-MM-DD HH:mm'),
            start: moment(newEvent.date).toDate(),
            end: moment(newEvent.date).toDate(),
            lead_id: null,
        };

        try {
            const res = await request('/api/reminders/create/', 'POST', { ...event })
            console.log("res: ", res)
        } catch (e) {
            console.log(e)
        } finally {
            window.location.reload()
        }

        setEvents([...events, event]);
    }, [events]);

    const getEvents = async () => {
        try {
            let url = `/api/reminders/for_user/?limit=100&offset=0`
            
            if(user.role.toLowerCase() === 'admin') url = `/api/reminders/?limit=100&offset=0`

            const response = await request(url);

            if (response && response.total > 0) {
                const events = response.reminders.map((item, index) => {
                    return {
                        id: item.id,
                        title: item.title,
                        description: item.description,
                        start: moment(item.remind_date).toDate(),
                        end: moment(item.remind_date).toDate(),
                    }
                })
                setEvents(events);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getEvents();
    }, []);

    return (
        <div className="sm:container mx-auto p-1 md:p-4 z-10 relative">
            <h2 className="my-6 text-2xl font-semibold">
                Календарь
            </h2>
            <div className="p-1 md:p-4">
                <div className="" role="alert">
                    {isModalOpen && <AddEventModal
                        onClose={() => setIsModalOpen(false)}
                        handleAddEvent={handleAddEvent}
                        exEvent={{ title: '', date: selectedDate, description: '' }} // Инициализируйте событие с выбранной датой
                    />}

                    <EventDetailsModal
                        isOpen={isEventDetailsModalOpen} // или другой флаг для этого модального окна
                        onClose={() => { setIsEventDetailsModalOpen(false); setEventDetails(null); }}
                        event={eventDetails}
                    />


                    {/* Календарь */}
                    <div style={{ height: '500px' }} className="z-10">
                        <Calendar
                            localizer={localizer}
                            events={events}
                            startAccessor="start"
                            endAccessor="end"
                            messages={messages}
                            style={{ height: 500 }}
                            selectable
                            onSelectEvent={handleEventClick}
                            onSelectSlot={handleCellSelect}
                        />
                    </div>
                    {/* ... ваш предупреждающий баннер ... */}
                </div>
            </div>
        </div>
    );
}

export default MyCalendar