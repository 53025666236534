import { Link } from "react-router-dom";
import { dateFormater } from "../../utils/dateFormater";
const entityMap = {
    "lead": "лида",
    "transaction": "транзакцию",
    "comment": "комментарий",
}

const actionMap = {
    "create": "создал",
    "update": "изменил",
    "delete": "удалил"
}

const actionIconMap = {
    "create": <span className="w-8 h-8 inline-block flex items-center justify-center rounded-full m-4 text-white bg-opacity-50 bg-green-500">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
            <path fillRule="evenodd" d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z" clipRule="evenodd" />
        </svg></span>,
    "update": <span className="w-8 h-8 inline-block flex items-center justify-center rounded-full m-4 text-white bg-opacity-50 bg-yellow-500">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
            <path d="M21.731 2.269a2.625 2.625 0 0 0-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 0 0 0-3.712ZM19.513 8.199l-3.712-3.712-12.15 12.15a5.25 5.25 0 0 0-1.32 2.214l-.8 2.685a.75.75 0 0 0 .933.933l2.685-.8a5.25 5.25 0 0 0 2.214-1.32L19.513 8.2Z" />
        </svg></span>,
    "delete": <span className="w-8 h-8 inline-block flex items-center justify-center rounded-full m-4 text-white bg-opacity-50 bg-red-500">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
            <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 0 1 1.06 0L12 10.94l5.47-5.47a.75.75 0 1 1 1.06 1.06L13.06 12l5.47 5.47a.75.75 0 1 1-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 0 1-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
        </svg></span>
}
export const ActionCard = ({ activity }) => {

    const { action, description, entity, user, created_at, lead, lead_id } = activity;

    return (
        <div className="w-full rounded overflow-hidden shadow-lg bg-white p-4">
            <div className="flex items-center">
                {actionIconMap[action]}
                <div>
                    <div className="font-bold text-sm mb-2">{user} {actionMap[action]} {entityMap[entity]}:</div>
                    <p className="text-gray-700 text-xs">
                        {dateFormater(created_at)}
                    </p>
                </div>
                <p className="text-gray-700 text-base ml-auto font-semibold">
                    <Link className="text-blue-500 hover:text-blue-700"
                        to={`/leads/${lead_id}`}>{lead}</Link>
                </p>
            </div>
            <div className="px-6 py-4">
                <div className="border rounded p-2 text-sm mb-2">
                    {description && Object.keys(description).map((key, index) => {
                        return (
                            <div className="text-md" key={index}>
                                <p className="font-semibold">{key}:</p>
                                <div className="flex gap-4 items-center">{description[key]?.from ?? 'create'} <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-4 h-4">
                                    <path fillRule="evenodd" d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z" clipRule="evenodd" />
                                </svg>
                                </span> {description[key]?.to ?? 'delete'}</div>
                            </div>
                        )
                    })}
                </div>

            </div>
        </div>)
}