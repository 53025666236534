import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrowNight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import useAuth from '@/hooks/useAuth.hook';

const PostLeads = () => {
    const {user} = useAuth()

    const codeString1 = 'STATUS 401\n\n{\n' +
        '\t"success": false,\n' +
        '\t"message": "No token provided"\n' +
        '}`;'

    const serverResponse = [
        {
            title: '1. Если Вы не предоставили токен',
            status: 401,
            response: `{ "success": false, "message": "No token provided" }`
        },
        {
            title: '2. Если Вы предоставили неверный токен',
            status: 401,
            response: `{ "success": false, "message": "Invalid token" }`
        },
        {
            title: '3. Если Вы не предоставили обязательные поля',
            status: 400,
            response: `{ "success": false, "message": "Required fields are missing" }`
        },
        {
            title: '4. Лид не принят, является дубликатом',
            status: 200,
            response: `{ "success": false, "message": "Lead is duplicate" }`
        },
        {
            title: '5. Лид принят',
            status: 200,
            response: `{ "success": true, "message": "Lead added", "lead_id": 533, "link_quick_login": "https://domain.com/quick-login/1a2b3c4d5e6f7g8h9i0j"}`
        }
    ]
    return (
        <div className="sm:container mx-auto p-1 md:p-4 z-10 relative">
            <h2 className="my-6 text-2xl font-semibold">
                POST LEADS
            </h2>

            <div className="bg-white card p-4 mb-6 xl:mb-9">
                <div className="card-body pt-0 pb-5">
                    <h2 className="font-bold mb-5">URL для отправки лидов:</h2>
                    <code className="ms-0">https://domain.com/api/aff_leads/add_lead</code>

                    <div className="mt-6 mb-3">
                        <div className="mb-2">
                            Данные ожидаются в формате JSON.
                        </div>
                        <div className="mb-2">Обязательные поля: <code className="ms-0">token, first_name, last_name, email, phone, ip, campaign</code></div>
                        <div className="mb-2">Опциональные поля: <code className="ms-0">password, geo, description</code></div>
                    </div>

                    <div className="mt-6 mb-3">
                        <div className="mb-2">
                            Ваш личный токен: <code className="ms-0">{user.token}</code>
                        </div>
                    </div>

                    <div className="mt-6">
                        <div className="mb-2">
                            <h2 className="font-bold mb-5">Возможные ответы сервера:</h2>
                        </div>
                        {serverResponse.map((item, index) => (
                            <div key={index} className="mt-6 mb-3">
                                <div className="mb-2">
                                    <h2 className="font-bold mb-5">{item.title}</h2>
                                    <SyntaxHighlighter language="javascript" style={tomorrowNight}>
                                        {'STATUS ' + item.status + '\n\n' + item.response}
                                    </SyntaxHighlighter>


                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default PostLeads