import React, { useCallback, useEffect, useRef, useState } from "react";
import AdvancedTable from "../transactions/AdvancedTable";
import Filters from "../transactions/Filters";
import FieldSettings from "../leads/FieldSettings";
import TransactionModalWrap from '../transactions/TransactionModal';
import { useHttp } from "../../hooks/http.hook";
import { hasFinancialRight } from "../../utils/permissionsHelper";
import useAuth from "../../hooks/useAuth.hook";
import { useDispatch, useSelector } from "react-redux";
import { fetchLeadTransaction, setCurrentPage, setFilters } from "@/redux/leadSlice";
import ReactPaginate from 'react-paginate';

// doropdown btn with 2 actions: delete and edit
export const ActionsDropdown = ({ handleDelete, handleEdit }) => {
    const [isOpen, setIsOpen] = useState(false);
    const btnRef = useRef(null);

    const handleClickOutside = (event) => {
        if (btnRef.current && !btnRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    // any btn click will close dropdown
    const handleEditClick = () => {
        setIsOpen(false);
        handleEdit();
    }

    const handleDeleteClick = () => {
        setIsOpen(false);
        handleDelete();
    }

    return (
        <div className="relative inline-block text-left" ref={btnRef}>
            <button onClick={() => setIsOpen(!isOpen)} type="button" className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none" id="options-menu" aria-haspopup="true" aria-expanded="true">
                Действия
                <svg className="-mr-1 ml-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path fillRule="evenodd" clipRule="evenodd"
                        d="M10 12a2 2 0 100-4 2 2 0 000 4zM7 10a2 2 0 114 0 2 2 0 01-4 0zm9 0a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
            </button>
            <div className={`${isOpen ? "block" : "hidden"} origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-10`} role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div className="py-1" role="none">
                    <button onClick={handleEditClick} className="text-gray-700 block w-full text-left px-4 py-2 text-sm ease-in duration-100 hover:bg-gray-300/30" role="menuitem">Редактировать</button>
                    <button onClick={handleDeleteClick} className="text-red-500 block w-full text-left px-4 py-2 text-sm ease-in duration-100 hover:bg-gray-300/30" role="menuitem">Удалить</button>
                </div>
            </div>
        </div>
    );
};

// Компонент UserTransaction
const UserTransaction = ({ id }) => {
    // Тестовые данные
    const dispatch = useDispatch();
    const { user, token } = useAuth();
    const { request } = useHttp();
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [modalIsOpen, setIsOpen] = useState(false);
    const [selectedTrans, setSelectedTrans] = useState(0);
    const { items: transactions, total, status: leadTransactionStatus, perPage, currentPage, filters } = useSelector(state => state.lead.transactions);

    // {label: 'Имя', key: 'first_name', value: lead.first_name, editable: true },

    const [selectedFields, setSelectedFields] = useState({
        id: { label: 'ID', isChecked: true },
        lead_id: { label: 'Лид', isChecked: true },
        type: { label: 'Тип', isChecked: true },
        sum: { label: 'Сумма', isChecked: true },
        currency: { label: '', isChecked: true },
        method: { label: 'Метод', isChecked: true },
        card_number: { label: 'НОМЕР КАРТЫ', isChecked: true },
        created_at: { label: 'СОЗДАНА', isChecked: true },
        status: { label: 'СТАТУС', isChecked: true },
        comment: { label: 'КОММЕНТАРИЙ', isChecked: true },
        manager: { label: 'МЕНЕДЖЕР', isChecked: true },
        desk: { label: 'Деск', isChecked: true },
        fdt: { label: 'FTD', isChecked: true },
        test: { label: 'ТЕСТ', isChecked: true },
        actions: { label: 'ДЕЙСТВИЯ', isChecked: true },
    });
    const [searchQuery, setSearchQuery] = useState("");

    // Функции для обработки событий фильтрации и настройки полей
    const handleFilterChange = (filters) => {
        // ... Обновление состояния с фильтрами
    };

    const applyFilters = (localFilters, callback) => {
        dispatch(setFilters(localFilters));
        callback();
    };

    const handleFieldChange = (fieldName) => {
        setSelectedFields(prevFields => ({
            ...prevFields,
            [fieldName]: {
                ...prevFields[fieldName],
                isChecked: !prevFields[fieldName].isChecked
            }
        }));
    };


    const handleRowSelectionChange = (itemId) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allRowIds = transactions.map((item, index) => item.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    };

    const handleDelete = async () => {
        // Логика удаления для выбранных элементов
        // /api/transactions/delete/array/

        try {
            const response = await request('/api/transactions/delete/array/', 'DELETE', { ids: Array.from(selectedRows) });
            if (response.message) alert(response.message);

            // const newData = transactions.filter((item, index) => !selectedRows.has(item.id));
            // add ActionsDropdown component
            // setTransactions(newData);
            setSelectedRows(new Set()); // Очистка выбранных элементов
        } catch (error) {
            console.error(error);
        } finally {
            getTransactions();
        }
    };

    const handleSingleDelete = async (id) => {
        // Логика удаления для выбранных элементов
        try {
            const res = await request(`/api/transactions/delete/${id}`, 'DELETE');
            if (res.message) {
                alert(res.message);
            }
            setSelectedTrans(0); // Очистка выбранных элементов
        } catch (error) {
            alert("ERROR: ", error.message);
            console.error(error);
        } finally {
            getTransactions();
        }
    };

    const chooseTrans = (id) => {
        setSelectedTrans(id);
        setIsOpen(true)
    }

    const getTrans = (id) => {
        return transactions.find((item, index) => item.id === id)
    }

    const handleEditTrans = async (trs) => {
        // Логика назначения для выбранных элементов
        try {
            const response = await request(`/api/transactions/update/${trs.id}`, 'PUT', { ...trs });

            if (response && response.message) {
                console.log(response.message);
                setIsOpen(false);
                getTransactions();
            }
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }

    const getTransactions = () => {
        dispatch(fetchLeadTransaction({ token, lead_id: id, limit: perPage, offset: (currentPage - 1) * perPage, filters }));
    }

    useEffect(() => {
        getTransactions();
    }, [id, currentPage, perPage, filters]);

    const handlePageChange = (newPage) => {
        dispatch(setCurrentPage(newPage.selected + 1));
    };
    const pageNumbers = Math.ceil(total / perPage);
    return (
        <div className="sm:container mx-auto">

            {selectedTrans && selectedTrans !== 0 ? <TransactionModalWrap
                transObj={getTrans(selectedTrans)}
                isModalOpen={modalIsOpen}
                closeModal={() => setIsOpen(false)}
                openModal={() => setIsOpen(true)}
                applyChanges={handleEditTrans}
            /> : ''}
            <div className="min-h-screen bg-gray-100">
                <div className="bg-white shadow rounded-lg p-1 md:p-4">
                    <div className="flex gap-2 md:gap-4 flex-wrap lg:flex-nowrap">
                        {hasFinancialRight(user, 'modifyingTransactions') && <button className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded" onClick={handleDelete}>Удалить</button>}
                        <Filters filters={filters} onFiltersChange={handleFilterChange} filterConfig={[]} applyChanges={applyFilters} defaultFilters={() => dispatch(setFilters({}))} />
                        <FieldSettings selectedFields={selectedFields} handleFieldChange={handleFieldChange} />
                    </div>
                    <div className="mb-2">
                        {/* pagination */}
                        <ReactPaginate
                            initialPage={currentPage - 1}
                            previousLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M10.707 5.293a1 1 0 010 1.414L7.414 10l3.293 3.293a1 1 0 11-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" /></svg>}
                            nextLabel={<svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true"><path fillRule="evenodd" d="M9.293 14.707a1 1 0 010-1.414L12.586 10 9.293 6.707a1 1 0 111.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg>}
                            breakLabel={'...'}
                            breakClassName={'break-me'}
                            pageCount={pageNumbers}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageChange}
                            containerClassName={'pagination'}
                            activeClassName={'rounded-full border bg-gray-200'}
                            className="flex justify-center mt-4 items-center gap-1"
                            pageClassName="flex items-center justify-center w-8 h-8 "
                        />
                    </div>
                    <AdvancedTable
                        chooseTrans={chooseTrans}
                        selectedRows={selectedRows}
                        handleRowSelectionChange={handleRowSelectionChange}
                        handleSelectAll={handleSelectAll}
                        selectedFields={selectedFields}
                        data={transactions.map(trx => ({
                            ...trx,
                            actions: <ActionsDropdown
                                handleDelete={() => handleSingleDelete(trx.id)}
                                handleEdit={() => chooseTrans(trx.id)}
                            />,
                        }))
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default UserTransaction;
