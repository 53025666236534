import React, { useState, useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import AdvancedTable from "./AdvancedTable";
import AddAffWrap from "./AddAffiliate";
import EditAffWrap from "./EditAffiliate";
import { useHttp } from "../../hooks/http.hook";
import { useSelector, useDispatch } from "react-redux";
import { fetchAffiliates } from "@/redux/affiliatesSlice";
import useAuth from "@/hooks/useAuth.hook";

// Компонент Affiliates
const Affiliates = () => {
    // Тестовые данные
    const { token } = useAuth();
    const { request } = useHttp();
    const dispatch = useDispatch();
    const { affiliates, status } = useSelector((state) => state.affiliates);
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [selectedAff, setSelectedAff] = useState(null);
    // const [affiliates, setAffiliates] = useState([]);
    // modals
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);

    const [selectedFields, setSelectedFields] = useState({
        id: { label: 'ID', isChecked: true },
        first_name: { label: 'АФФИЛИАТ', isChecked: true },
        token: { label: 'ТОКЕН', isChecked: true },
        count_leads: { label: 'ЛИДЫ', isChecked: true },
        // delete: { label: 'ДЕЙСТВИЕ', isChecked: true },
    });

    // const getAffs = async () => {
    //     try {
    //         const fetched = await request('/api/affiliates/')
    //         if (fetched && fetched.affiliates)
    //             setAffiliates(fetched.affiliates)
    //     } catch (e) {
    //         console.log("user added: ", e)
    //     }
    // }

    const handleRowSelectionChange = (itemId) => {
        console.log('Выбран элемент с id:', itemId);
        console.log('Выбраны элементы:', Array.from(selectedRows));
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allRowIds = affiliates.map((item, index) => item.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    };

    const openModal = () => {
        setShowEditModal(true)
    }

    const chooseAff = (id) => {
        setSelectedAff(id);
        openModal()
    }

    const handleEditAff = async (aff) => {
        try {
            const fetched = await request('/api/affiliates/' + aff.id, 'PUT', { ...aff })

            if (fetched && fetched.message) {
                alert(fetched.message)
                dispatch(fetchAffiliates({ token }))
                setSelectedAff(null)
            }
        } catch (e) {
            console.log("aff edited error: ", e)
            alert("Error: all fields are required")
        }
    }

    const handleAddAff = async (aff) => {
        try {
            const fetched = await request('/api/affiliates/add', 'POST', { ...aff })
            console.log("user added: ", fetched)

            if (fetched && fetched.message) {
                alert(fetched.message)
                dispatch(fetchAffiliates({ token }))
                setSelectedAff(0)
            }
        } catch (e) {
            console.log("user added: ", e)
            alert("Error: all fields are required")
        }
    }

    const getAff = (id) => {
        return affiliates?.find((aff) => aff.id === id);
    }
    const handleAffDelete = async (id) => {
        try {
            const fetched = await request('/api/affiliates/' + id, 'DELETE')
            console.log("user added: ", fetched)

            if (fetched && fetched.message) {
                alert(fetched.message)
                dispatch(fetchAffiliates({ token }))
            }
        } catch (e) {
            console.log("user added: ", e)
            alert("Error: all fields are required")
        }
    }

    useEffect(() => {
        if (status === 'idle')
            dispatch(fetchAffiliates({ token }))
    }, [])

    return (
        <div className="sm:container mx-auto">
            {showEditModal && affiliates.length > 0 &&
                <AddAffWrap
                    type='edit'
                    affObj={getAff(selectedAff)}
                    apllyChanges={handleEditAff}
                    showModal={showEditModal}
                    setShowModal={() => setShowEditModal(!showEditModal)}
                />}

            <div className="min-h-screen bg-gray-100">
                <div className="bg-white shadow rounded-lg p-1 md:p-4">
                    <div className="flex gap-2 md:gap-4 flex-wrap lg:flex-nowrap">
                        <AddAffWrap
                            apllyChanges={handleAddAff}
                            showModal={showAddModal}
                            setShowModal={setShowAddModal}
                        />
                    </div>
                    <AdvancedTable
                        type="affiliates"
                        chooseAff={chooseAff}
                        selectedRows={selectedRows}
                        handleRowSelectionChange={handleRowSelectionChange}
                        handleSelectAll={handleSelectAll}
                        selectedFields={selectedFields}
                        data={
                            affiliates
                            // .map((aff) => {
                            //     return {
                            //         ...aff,
                            //         delete: (
                            //             <button
                            //                 onClick={() => {
                            //                     handleAffDelete(aff.id);
                            //                 }}
                            //                 className="text-red-500"
                            //             >
                            //                 Удалить
                            //             </button>
                            //         ),
                            //     };
                            // })
                        }
                    />
                </div>
            </div>
        </div>
    );
};

export default Affiliates;
