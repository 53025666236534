import React, { useEffect, useState } from 'react';
import AdvancedTable from '../components/leads/AdvancedTable'
import Modal from '../components/Modal'
import { useDispatch, useSelector } from 'react-redux';
import { fetchActives } from '@/redux/activesSlice';
import useAuth from '@/hooks/useAuth.hook';

const selectedFields = {
    id: { label: 'ID', isChecked: true },
    name: { label: 'Актив', isChecked: true },
    type: { label: 'Тип Актива', isChecked: true },
    sell: { label: 'sell', isChecked: true },
    buy: { label: 'buy', isChecked: true },
    // contract: { label: 'РАЗМЕР КОНТРАКТА', isChecked: true },
    // value: { label: 'ЗНАЧЕНИЕ ПУНКТА', isChecked: true },
    // cost: { label: 'ЦЕНА ПУНКТА', isChecked: true },
    // spred: { label: 'МАКС. СПРЕД', isChecked: true },
    // edit: { label: '', isChecked: true },

}

const AssetsModal = ({ isOpen, onClose, editingAsset, handleAddEvent = () => null }) => {
    const [asset, setAsset] = useState(editingAsset);

    // const handleEditNewEvent = (e) => {
    //     const key = e.target.name;
    //     const value = e.target.value;

    //     setNewEvent(prevState => ({
    //         ...prevState,
    //         [key]: value
    //     }))
    // };
    console.log("asset: ", asset)


    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <Modal.Header>
                ДОБАВИТЬ НАПОМИНАНИЕ
            </Modal.Header>
            <Modal.Body>
                <div className="mb-4">
                    {/* Актив */}
                    <input
                        type="text"
                        name="asset"
                        value={asset.asset}
                        onChange={(e) => setAsset({ ...asset, asset: e.target.value })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Актив"
                    />
                </div>
                <div className="mb-4">
                    {/* Цена Пункта */}
                    <input
                        type="text"
                        name="cost"
                        value={asset.cost}
                        onChange={(e) => setAsset({ ...asset, cost: e.target.value })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Цена Пункта"
                    />
                </div>
                <div className="mb-4">
                    {/* Размер контракта */}
                    <input
                        type="text"
                        name="contract"
                        value={asset.contract}
                        onChange={(e) => setAsset({ ...asset, contract: e.target.value })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Размер контракта"
                    />
                </div>
                <div className="mb-4">
                    {/* Максимальный Спред */}
                    <input
                        type="text"
                        name="spred"
                        value={asset.spred}
                        onChange={(e) => setAsset({ ...asset, spred: e.target.value })}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        placeholder="Максимальный Спред"
                    />
                </div>

            </Modal.Body>
            <Modal.Footer>
                <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={() => handleAddEvent(asset)}
                >
                    Сохранить
                </button>
                <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={onClose}
                >
                    Закрыть
                </button>
            </Modal.Footer>
        </Modal>
    );
}

const Assets = () => {
    // {
    //     "id": 5,
    //     "name": "FOREX",
    //     "type": "JPY / USD",
    //     "prefix": "FX_IDC",
    //     "sell": 0.00669,
    //     "buy": 0.0067,
    //     "contract_size": 10000,
    //     "item_value": 1,
    //     "item_price": 1,
    //     "max_spread": 1,
    //     "updated_at": "2024-02-09T07:05:51.056413"
    // }
    const dispatch = useDispatch();
    const { actives: assets, status, error } = useSelector((state) => state.active);
    const [selectedRows, setSelectedRows] = useState(new Set());
    const [isModalOpen, setIsModalOpen] = React.useState(false)
    const [editingAsset, setEditingAsset] = React.useState(null)
    const { token } = useAuth()

    const handleRowSelectionChange = (itemId) => {
        setSelectedRows((prevSelectedRows) => {
            const newSelectedRows = new Set(prevSelectedRows);
            if (newSelectedRows.has(itemId)) {
                newSelectedRows.delete(itemId);
            } else {
                newSelectedRows.add(itemId);
            }
            return newSelectedRows;
        });
    };

    const handleSelectAll = (e) => {
        if (e.target.checked) {
            const allRowIds = assets.map((item, index) => item.id);
            setSelectedRows(new Set(allRowIds));
        } else {
            setSelectedRows(new Set());
        }
    };

    const handleEditAsset = (asset) => {
        setIsModalOpen(true);
        setEditingAsset(asset)
    }

    useEffect(() => {
        if (status === 'idle') {
            dispatch(fetchActives({ token }))
        }
    }, [dispatch, token])

    return <div className="sm:container mx-auto p-1 md:p-4">
        <h2 className="my-6 text-2xl font-semibold">
            Активы
        </h2>

        {editingAsset && <AssetsModal isOpen={isModalOpen} onClose={() => { setIsModalOpen(false); setEditingAsset(null) }} editingAsset={editingAsset} />}

        <AdvancedTable data={assets}
            selectedRows={selectedRows}
            handleRowSelectionChange={handleRowSelectionChange}
            handleSelectAll={handleSelectAll}
            selectedFields={selectedFields}
        />
    </div>

}

export default Assets