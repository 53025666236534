// desk slice for redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// chunk of get managers
// /api/users/managers/
// GET

export const fetchManagers = createAsyncThunk(
    'manager/fetchManagers', 
    async ({ token, limit = 10, offset = 0 }, { rejectWithValue }) => {
    try {
        const response = await fetch(`/api/users/managers/?limit=${limit}&offset=${offset}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
            },
        });
        const data = await response.json();
        if (!response.ok) {
            throw new Error(data.message || 'Не удалось получить менеджеров');
        }
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const managersSlice = createSlice({
    name: 'manager',
    initialState: {
        managers: [],
        total: 0,
        status: 'idle',
        currentPage: 1,
        deskPerPage: 10,
        error: null
    },
    reducers: {
        setManager: (state, action) => {
            state.managers = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchManagers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchManagers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.managers = action.payload.managers;
                state.total = action.payload.total;
            })
            .addCase(fetchManagers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setManager } = managersSlice.actions;

export default managersSlice.reducer;