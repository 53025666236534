import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


export const fetchTransactions = createAsyncThunk(
    'transactions/fetchTransactions',
    async ({ token, limit = 10, offset = 0, filters }, { rejectWithValue }) => {

        try {
            let url = `/api/transactions/?limit=${limit}&offset=${offset}`

            if (filters) {
                Object.keys(filters).forEach(key => {
                    if (filters[key].includes(',')) {
                        const values = filters[key].split(',');
                        values.forEach(value => {
                            url += `&${key}=${value}`
                        })
                        return
                    }
                    
                    if (filters[key] !== 'all' && filters[key] !== '')
                        return url += `&${key}=${filters[key]}`
                })
            }

            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить transactions');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });

export const transactionsSlice = createSlice({
    name: 'transactions',
    initialState: {
        transactions: [],
        filters: [],
        total: 0,
        status: 'idle',
        currentPage: 1,
        perPage: 10,
        error: null
    },
    reducers: {
        setTransactions: (state, action) => {
            state.transactions = action.payload.transactions;
        },
        setFilters: (state, action) => {
            state.filters = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        setPerPage: (state, action) => {
            state.perPage = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchTransactions.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchTransactions.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.transactions = action.payload.transactions;
                state.total = action.payload.total;
            })
            .addCase(fetchTransactions.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setTransactions, setFilters, setCurrentPage, setPerPage } = transactionsSlice.actions;

export default transactionsSlice.reducer;