// desk slice for redux toolkit
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// chunk of get retens
// /api/users/managers/
// GET

export const fetchReten = createAsyncThunk(
    'reten/fetchReten',
    async ({ token }, { rejectWithValue }) => {
        try {
            const response = await fetch(`/api/reten_status/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'authorization': `Bearer ${token}`, // Вставьте ваш токен аутентификации здесь
                },
            });
            const data = await response.json();
            if (!response.ok) {
                throw new Error(data.message || 'Не удалось получить ретеншен');
            }
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    });
// add desk
// '/api/retens/add', 'POST', { name: desk }

export const retenSlice = createSlice({
    name: 'reten',
    initialState: {
        retens: [],
        status: 'idle',
        error: null
    },
    reducers: {
        setReten: (state, action) => {
            state.retens = [...state.retens, action.payload];
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchReten.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchReten.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.retens = action.payload;
            })
            .addCase(fetchReten.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            });
    }
});

export const { setReten } = retenSlice.actions;

export default retenSlice.reducer;